import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { faBookOpen, faEarListen, faPencil, faKeynote, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import './ViewCaseDetailsPopup.css'
import CustomExamSectionCard from '../CustomExamSectionCard/CustomExamSectionCard'
import { AnimatePresence, motion } from 'framer-motion'
import formatDateString from '../../../Functions/FormatDateString'
import MDSpinner from 'react-md-spinner'
import formatTimeString from '../../../Functions/FormatTimeString'

const INCOMPLETE_CASES = [
  1, 2, 3, 4
]

export default function ViewCaseDetailsPopup(props) {
  const [selectedSections, setSelectedSections] = useState([])
  const [isViewingSelectedSections, setIsViewingSelectedSections] = useState(true)
  const [incompleteCaseExpanded, setIncompleteCaseExpanded] = useState(-1)
  const [selectedIncompleteCase, setSelectedIncompleteCase] = useState(-1)
  const [sectionMoreOptionsPopupDetails, setSectionMoreOptionsPopupDetails] = useState({open: false, sectionIndex: null, x: 0, y: 0})
  const [caseMoreOptionsPopupDetails, setCaseMoreOptionsPopupDetails] = useState({open: false, examData: null, examType: null, x: 0, y: 0})
  const [displayUpdateExamName, setDisplayUpdateExamName] = useState(false)
  const [newExamName, setNewExamName] = useState('')
  const [examNamePopupLoading, setExamNamePopupLoading] = useState(false)

  useEffect(() => {
    let sections = []
    props.caseData.sections.forEach((section) => {
      sections.push(section.sectionId)
    })
    setSelectedSections(sections)
  }, [])

  const handleSectionClick = (section) => {
    if (selectedSections.includes(section.sectionId)) {
      setSelectedSections(selectedSections.filter((selectedSection) => selectedSection !== section.sectionId))
    } else {
      setSelectedSections([...selectedSections, section.sectionId])
    }
  }

  const toggleSectionMoreOptions = (event, sectionData) => {
    event.stopPropagation()
    let sectionIndex = props.caseData.sections.findIndex((section) => section.sectionId === sectionData.sectionId)
    let y = event.clientY
    let x = event.clientX
    if (sectionMoreOptionsPopupDetails.open && sectionMoreOptionsPopupDetails.sectionIndex === sectionIndex) {
      setSectionMoreOptionsPopupDetails({open: false, sectionIndex: null, x: 0, y: 0})
    } else {
      setSectionMoreOptionsPopupDetails({open: true, sectionIndex, x, y})
    }
  }

  const toggleCaseMoreOptions = (event) => {
    event.stopPropagation()
    let y = event.clientY
    let x = event.clientX
    if (caseMoreOptionsPopupDetails.open) {
      setCaseMoreOptionsPopupDetails({open: false, x: 0, y: 0})
    } else {
      setCaseMoreOptionsPopupDetails({open: true, x, y})
    }
  }

  const fetchRenameExam = () => {

    let currentStateId

    if (props.caseData.completedAttempts.length > 0) {
      currentStateId = props.caseData.completedAttempts[0].CurrentStateId
    } else {
      currentStateId = props.caseData.IncompleteAttempts[0].CurrentStateId
    }

    setExamNamePopupLoading(true)
    fetch(`${props.route}/renamecustomexam.webapi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': props.userData.Token
      },
      body: JSON.stringify({
        customerId: props.userData.CustomerId,
        currentStateId,
        examName: newExamName
      })
    })
    .then((response) => {
      return response.text()
    })
    .then((data) => {
      props.updateCaseName(newExamName)
      setExamNamePopupLoading(false)
      setDisplayUpdateExamName(false)
    })
    .catch(() => {
      window.alert('failed to rename exam')
      setExamNamePopupLoading(false)
    })
  }

  const handleExamNameChangeDisplay = () => {
    setCaseMoreOptionsPopupDetails({open: false, x: 0, y: 0})
    setNewExamName(props.caseData.ExamName)
    setDisplayUpdateExamName(true)
  }

  return (
    <div className='viewCaseDetailsPageWrapper'>
      <motion.div
        className='viewCaseDetailsBackdrop'
        key={'viewCaseDetailsBackdrop'}
        onClick={props.closePopup}
        initial={props.animationsDisabled ? false : {opacity: 0}}
        animate={props.animationsDisabled ? false : {opacity: 1}}
        exit={props.animationsDisabled ? false : {opacity: 0}}
        transition={{duration: 0.2}}
      />
      <motion.div className='viewCaseDetailsPopup'
        key={'viewCaseDetailsPopup'}
        initial={props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
        animate={props.animationsDisabled ? false : {y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
        exit={props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
        transition={{duration: 0.2}}
      >
        <div className='viewCaseDetailsPopupHeader'>
          <h2 className='viewCaseDetailsPopupIdNumber'>{props.caseData.ExamId}</h2>
          <h3 className='viewCaseDetailsPopupTitle'>{props.caseData.ExamName}</h3>
          {props.userData.TurnOffHighYield && !props.caseData.Completed ?
            null
          :props.userData.TurnOffHighYield && props.caseData.Completed ?
            <p className='viewCaseDetailsPopupMeta'>Completed: {formatDateString(props.caseData.MostRecentCompletionDate, props.userData.TimeZone)} ({Math.floor(props.caseData.MostRecentCompletionScore * 100) / 100}%)</p>
          :props.caseData.Completed ?
            <p className='viewCaseDetailsPopupMeta'>{props.caseData.HighYield} High Yield Total | Completed: {formatDateString(props.caseData.MostRecentCompletionDate, props.userData.TimeZone)} ({Math.floor(props.caseData.MostRecentCompletionScore * 100) / 100}%)</p>
          :
            <p className='viewCaseDetailsPopupMeta'>{props.caseData.HighYield} High Yield Total</p>
          }
          <button className='viewCaseDetailsPopupMoreOptionsButton' onClick={toggleCaseMoreOptions}>
            <FontAwesomeIcon icon={faEllipsisVertical} className='viewCaseDetailsPopupMoreOptionsIcon' />
          </button>
          <button className='viewCaseDetailsPopupCloseButton' onClick={props.closePopup}/>
        </div>
        {props.caseData.IncompleteAttempts.length > 0 &&
          <div className='viewCaseDetailsPopupTabsWrapper'>
            <button className={`viewCaseDetailsPopupTab ${isViewingSelectedSections ? 'viewCaseDetailsPopupTabSelected' : ''}`} onClick={() => setIsViewingSelectedSections(true)}>Select Sections</button>
            <button className={`viewCaseDetailsPopupTab ${!isViewingSelectedSections ? 'viewCaseDetailsPopupTabSelected' : ''}`} onClick={() => setIsViewingSelectedSections(false)}>Incomplete Attempts ({props.caseData.IncompleteAttempts.length})</button>
          </div>
        }
        {isViewingSelectedSections ?
          <div className='viewCaseDetailsPopupBody'>
            <div className='viewCaseDetailsPopupSectionListWrapper'>
              {props.caseData.sections.map((section) => (
                <CustomExamSectionCard
                  animationsDisabled={false}
                  sectionData={section}
                  forceEllipses={true}
                  handleSectionClick={() => handleSectionClick(section)}
                  key={`Exam Section - ${section.sectionTitle} - ${section.sectionId}`}
                  completed={true}
                  isSectionSelected={selectedSections.includes(section.sectionId)}
                  toggleCaseListSectionMoreOptions={toggleSectionMoreOptions}
                  handleSectionReviewLater={props.handleSectionReviewLater}
                  primaryColor={props.primaryColor}
                  userData={props.userData}
                  viewCaseGradesClick={props.viewCaseGradesClick}
                />
              ))}
            </div>
            {props.fetchingStartExam ?
              <div className='viewCaseDetailsStartSpinnerWrapper'>
                <MDSpinner size={30} singleColor={props.primaryColor} />
              </div>
            :
              <button className='viewCaseDetailsStartCaseButton' onClick={() => props.beginCaseHandler(selectedSections)}>
                Start Exam
              </button>
            }
          </div>
        :
          <div className='viewCaseDetailsPopupBody'>
            <div className='viewCaseDetailsIncompleteCasesWrapper'>
              {[...props.caseData.IncompleteAttempts].reverse().map((incompleteCase) => (
                <div
                  className={`viewCaseDetailsIncompleteCase ${incompleteCase.CurrentStateId === selectedIncompleteCase ? 'viewCaseDetailsIncompleteCaseSelected' : ''}`}
                  onClick={() => setSelectedIncompleteCase(incompleteCase.CurrentStateId)}
                  key={`${incompleteCase.TimeAttempted} - ${incompleteCase.CurrentStateId}`}
                >
                  <div className={`viewCaseDetailsIncompleteCaseHeaderWrapper ${incompleteCaseExpanded === incompleteCase ? 'viewCaseDetailsIncompleteCaseHeaderExpanded' : ''}`}>
                    <h3 className={`viewCaseDetailsIncompleteCaseTitle ${incompleteCaseExpanded === incompleteCase ? 'viewCaseDetailsIncompleteCaseTitleExpanded' : ''}`}>{formatDateString(incompleteCase.TimeAttempted, props.userData.TimeZone)} {formatTimeString(incompleteCase.TimeAttempted, props.userData.TimeZone)}</h3>
                    {/* {incompleteCaseExpanded !== incompleteCase && <h4 className='viewCaseDetailsIncompleteCaseMeta'>3/4 Sections</h4>} */}
                    <button className='viewCaseDetailsIncompleteCaseExpandButton' onClick={(e) => {
                      e.stopPropagation()
                      setIncompleteCaseExpanded(incompleteCaseExpanded === incompleteCase ? -1 : incompleteCase)
                    }}>
                      <FontAwesomeIcon
                        icon={incompleteCaseExpanded === incompleteCase ? faChevronUp : faChevronDown}
                        className='viewCaseDetailsIncompleteCaseExpandIcon'
                      />
                    </button>
                  </div>
                  { incompleteCaseExpanded === incompleteCase &&
                    <div className='viewCaseDetailsIncompleteCaseBodyWrapper'>
                      {incompleteCase.IncludedSectionIds.map((section) => (
                        <div className='caseListCaseSectionWrapper' key={`${incompleteCase.TimeAttempted} - ${incompleteCase.CurrentStateId} - ${section}`}>
                          <FontAwesomeIcon icon={props.sectionMap[section].CategoryIcon} className='caseListCaseSectionIcon'/>
                          <p className='caseListCaseSectionText'>Section {section} - {props.sectionMap[section].sectionTitle}</p>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              ))}
            </div>
            {props.fetchingStartExam ?
              <div className='viewCaseDetailsStartSpinnerWrapper'>
                <MDSpinner size={30} singleColor={props.primaryColor} />
              </div>
            :
              <button className={`viewCaseDetailsStartCaseButton ${selectedIncompleteCase === -1 ? 'buttonDisabled' : ''}`} onClick={() => props.incompleteStartHandler(selectedIncompleteCase, props.caseData)}>
                Start Exam
              </button>
            }
          </div>
        }
      </motion.div>
      <AnimatePresence mode="wait">
        {sectionMoreOptionsPopupDetails.open &&
          <motion.div
            className='moreOptionsPopupContainer'
            style={{
              top: sectionMoreOptionsPopupDetails.y,
              left: sectionMoreOptionsPopupDetails.x - 220,
            }}
            initial={props.animationsDisabled ? false : {opacity: 0.15, transform: 'scale(0.95) translate(5%, -5%)'}}
            animate={props.animationsDisabled ? false : {opacity: 1, transform: 'scale(1) translate(0%, 0%)'}}
            exit={props.animationsDisabled ? false : {opacity: 0.15, transform: 'scale(0.95) translate(5%, -5%)'}}
            transition={{duration: 0.15, ease: 'easeOut'}}
            key='sectionMoreOptionsPopup'
          >
            {props.caseData.sections[sectionMoreOptionsPopupDetails.sectionIndex].Completed &&
              <button className='moreOptionsPopupButton' onClick={(e) => props.viewCaseGradesClick(e, props.caseData.sections[sectionMoreOptionsPopupDetails.sectionIndex])}>
                View Grades
              </button>
            }
            <button className='moreOptionsPopupReviewLaterButton' onClick={(e) => props.handleSectionReviewLater(e, props.caseData.sections[sectionMoreOptionsPopupDetails.sectionIndex])}>
              <div className='caseListSectionReviewLaterCheckboxWrapper'>
                {props.caseData.sections[sectionMoreOptionsPopupDetails.sectionIndex].ReviewLaterOutstanding ?
                  <MDSpinner
                    size={15}
                    singleColor={props.primaryColor}
                  />
                :
                  <div className='caseListSectionReviewLaterCheckbox'>
                    {props.caseData.sections[sectionMoreOptionsPopupDetails.sectionIndex].ReviewLaterFlag && <FontAwesomeIcon icon={faCheck} className='caseListSectionReviewLaterCheckboxIcon'/>}
                  </div>
                }
              </div>
              Review Later
            </button>
          </motion.div>
        }
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {caseMoreOptionsPopupDetails.open &&
          <motion.div
            className='moreOptionsPopupContainer'
            style={{
              top: caseMoreOptionsPopupDetails.y,
              left: caseMoreOptionsPopupDetails.x - 220,
            }}
            initial={props.animationsDisabled ? false : {opacity: 0.15, transform: 'scale(0.95) translate(5%, -5%)'}}
            animate={props.animationsDisabled ? false : {opacity: 1, transform: 'scale(1) translate(0%, 0%)'}}
            exit={props.animationsDisabled ? false : {opacity: 0.15, transform: 'scale(0.95) translate(5%, -5%)'}}
            transition={{duration: 0.15, ease: 'easeOut'}}
            key='examMoreOptionsPopup'
          >
            {props.caseData.Completed &&
              <button className='moreOptionsPopupButton'>
                View Grades
              </button>
            }
            {!props.isExamStylePremade && props.isViewingPreviousExams &&
              <button className='moreOptionsPopupButton' onClick={() => handleExamNameChangeDisplay()}>
                Edit Exam Name
              </button>
            }
            <button className='moreOptionsPopupReviewLaterButton' onClick={(e) => props.handleExamReviewLater(e, props.caseData, props.isExamStylePremade ? 'Premade' : 'Custom')}>
              <div className='caseListSectionReviewLaterCheckboxWrapper'>
                {props.caseData.ReviewLaterOutstanding ?
                  <MDSpinner
                    size={15}
                    singleColor={props.primaryColor}
                  />
                :
                  <div className='caseListSectionReviewLaterCheckbox'>
                    {props.caseData.ReviewLater && <FontAwesomeIcon icon={faCheck} className='caseListSectionReviewLaterCheckboxIcon'/>}
                  </div>
                }
              </div>
              Review Later
            </button>
          </motion.div>
        }
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {displayUpdateExamName &&
          <div className='confirmationPopupPageContainer'>
            <motion.div
              className='confirmationPopupBackdrop'
              onClick={() => setDisplayUpdateExamName(false)}
              initial={props.animationsDisabled ? false : {opacity: 0}}
              animate={props.animationsDisabled ? false : {opacity: 1}}
              exit={props.animationsDisabled ? false : {opacity: 0}}
              transition={{duration: 0.2}}
              key="confirmationPopupBackdrop"
            />
            <motion.div
              className='confirmationPopupContainer'
              initial={props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
              animate={props.animationsDisabled ? false : {y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
              exit={props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
              transition={{duration: 0.2}}
              key="confirmationPopupContainer"
            >
              <button className='popupCloseButton' onClick={() => setDisplayUpdateExamName(false)} />
              <h2 className='confirmationPopupTitle'>Rename Exam</h2>
              <p className='confirmationPopupDescription'>Editing: {props.caseData.ExamName}</p>
              <div className='printReceiptInputContainer'>
                <label htmlFor="updateExamNameInput" className='printReceiptInputLabel standardLabel'>New Exam Name</label>
                <input className='standardInput' value={newExamName} id="updateExamNameInput" type='text' onChange={(e) => setNewExamName(e.target.value)}/>
              </div>
              <div className='confirmationPopupButtonWrapper'>
                <button className='confirmationPopupButton secondaryButton' onClick={() => setDisplayUpdateExamName(false)}>Cancel</button>
                {examNamePopupLoading ?
                  <div className='confirmationPopupSpinnerContainer'>
                    <MDSpinner
                      className='confirmationPopupSpinner'
                      size={30}
                      singleColor={props.primaryColor}
                    />
                  </div>
                : 
                  <button className='confirmationPopupButton tertiaryButton' onClick={fetchRenameExam}>Confirm</button>
                }
              </div>
            </motion.div>
          </div>
        }
      </AnimatePresence>
    </div>
  )
}
