import React from 'react'
import './ConfirmationPopup.css'
import { motion } from 'framer-motion'
import MDSpinner from 'react-md-spinner'

export default function ConfirmationPopup(props) {
    return (
        <div className='confirmationPopupPageContainer'>
            <motion.div
                className='confirmationPopupBackdrop'
                onClick={props.closePopup}
                initial={props.animationsDisabled ? false : {opacity: 0}}
                animate={props.animationsDisabled ? false : {opacity: 1}}
                exit={props.animationsDisabled ? false : {opacity: 0}}
                transition={{duration: 0.2}}
                key="confirmationPopupBackdrop"
            />
            <motion.div
                className='confirmationPopupContainer'
                initial={props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
                animate={props.animationsDisabled ? false : {y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
                exit={props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
                transition={{duration: 0.2}}
                key="confirmationPopupContainer"
            >
                <button className='popupCloseButton' onClick={props.closePopup} />
                <h2 className='confirmationPopupTitle'>{props.options.title}</h2>
                <p className='confirmationPopupDescription'>{props.options.description}</p>
                {props.options.children}
                {props.options.continueOnly ?
                    <div className='confirmationPopupButtonWrapperOneButton'>
                        {props.popupLoading ?
                            <div className='confirmationPopupSpinnerContainer'>
                                <MDSpinner
                                    className='confirmationPopupSpinner'
                                    size={30}
                                    singleColor={props.primaryColor}
                                />
                            </div>
                        : 
                            <button className='confirmationPopupButton tertiaryButton' onClick={props.options.confirmFunction}>{props.options.confirmationText ? props.options.confirmationText : "Continue"}</button>
                        }
                    </div>
                :
                    <div className='confirmationPopupButtonWrapper'>
                        <button className='confirmationPopupButton secondaryButton' onClick={props.closePopup}>Cancel</button>
                        {props.popupLoading ?
                            <div className='confirmationPopupSpinnerContainer'>
                                <MDSpinner
                                    className='confirmationPopupSpinner'
                                    size={30}
                                    singleColor={props.primaryColor}
                                />
                            </div>
                        : 
                            <button className='confirmationPopupButton tertiaryButton' onClick={props.options.confirmFunction}>{props.options.confirmationText ? props.options.confirmationText : "Confirm"}</button>
                        }
                    </div>
                }
            </motion.div>
        </div>
    )
}
