import React from 'react'
import './InfoComponent.css'

export default function InfoComponent(props) {

  const filterText = input => {
    // Split the string into an array of substrings, using the "<strong>", "</strong>", "<center>", and "</center>" tags as the separators
    const substrings = input.split(/(<strong>|<\/strong>|<center>|<\/center>)/).filter((substring) => substring !== "");

    let result = []
    let centered = false
    let bold = false

    // Loop through the array of substrings and add the appropriate formatting to each substring
    // add it to the result array if it is not a tag
    for (let i = 0; i < substrings.length; i++) {
      switch(substrings[i]) {
        case "<strong>":
          bold = true
          break
        case "</strong>":
          bold = false
          break
        case "<center>":
          centered = true
          break
        case "</center>":
          centered = false
          break
        default:
          result.push({ text: substrings[i], bold, centered })
          break
      }
    }

    return result;
  }

  return (
    <div className='infoContainer'>
      <p
        className={`infoText`}
      >
        {props.content.text && filterText(props.content.text).map((text, index) => (
          text.bold && text.centered ?
            <span className='centeredText' key={`infoComponentText - ${props.componentIndex} - ${index}`}><strong>{text.text}</strong></span>
          : text.bold ?
            <strong key={`infoComponentText - ${props.componentIndex} - ${index}`}>{text.text}</strong>
          : text.centered ?
            <span className='centeredText' key={`infoComponentText - ${props.componentIndex} - ${index}`}>{text.text}</span>
          :
            text.text
        ))}
      </p>
    </div>
  )
}
