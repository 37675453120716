import React from 'react'
import './ExitExamPopup.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

export default function ExitExamPopup(props) {
  return (
    <div className='exitExamPopupPageContainer'>
      <div className='exitExamPopupBackdrop' />
      <div className='exitExamPopupContainer'>
        <header className="finishSectionPopupModalHeader">
          <p>Confirm Exit Exam</p>
        </header>
        <div className="finishSectionPopupModalBody">
          <p className='exitExamPopupText'>Are you sure you would like to exit this exam? Please note that using "Exit Exam" will prematurely close the exam, and the exam will not be graded. <br /><br /> Your progress will be saved so that the exam can be completed at a later time. If you would like to complete the exam, you will need to complete all the questions.</p>
        </div>
        <footer className="finishSectionPopupModalFooter finishSectionPopupConfirmButtonWrapper">
          <button onClick={props.onClose}>
            <FontAwesomeIcon
              icon={faXmark}
              className='buttonIcon'
            />
            Cancel
          </button>
          <button onClick={props.onConfirm}>
            <FontAwesomeIcon
              icon={faCheck}
              className='buttonIcon'
            />
            Exit Exam
          </button>
        </footer>
      </div>
    </div>
  )
}
