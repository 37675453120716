
const createGradeOverview = (WritingGradeOutput, section) => {
  let gradeOverview = []

  // Header Grade
  let headerScore = 0
  if (WritingGradeOutput.HeaderGrade.CorrectAddress) headerScore += 1;
  if (WritingGradeOutput.HeaderGrade.CorrectCity) headerScore += 1;
  if (WritingGradeOutput.HeaderGrade.CorrectFirstName) headerScore += 1;
  if (WritingGradeOutput.HeaderGrade.CorrectLastName) headerScore += 1;
  if (WritingGradeOutput.HeaderGrade.CorrectLocation) headerScore += 1;
  if (WritingGradeOutput.HeaderGrade.CorrectTitle) headerScore += 1;
  if (WritingGradeOutput.HeaderGrade.EachOnOwnLine) headerScore += 1;
  if (WritingGradeOutput.HeaderGrade.EndingLineBreak) headerScore += 1;

  gradeOverview.push({
    title: 'Header',
    yourScore: `${headerScore}/8 (${Math.floor((headerScore / 8) * 100)}%)`,
    totalGradePercent: `${WritingGradeOutput.HeaderGrade.TotalGradePortion}%`,
    yourTotalGrade: `${(((WritingGradeOutput.HeaderGrade.Grade / 100) * WritingGradeOutput.HeaderGrade.percentage) * 100).toFixed(2).replace(/\.00$/, '')}%`
  })

  // Salutation Grade
  let salutationScore = 0
  if (WritingGradeOutput.SalutationGrade.CorrectLastName) salutationScore += 1;
  if (WritingGradeOutput.SalutationGrade.CorrectSalutation) salutationScore += 1;
  if (WritingGradeOutput.SalutationGrade.CorrectTitle) salutationScore += 1;
  if (!WritingGradeOutput.SalutationGrade.IncludesFirstName) salutationScore += 1;
  if (WritingGradeOutput.SalutationGrade.SalutationOnOwnLine) salutationScore += 1;

  gradeOverview.push({
    title: 'Salutation',
    yourScore: `${salutationScore}/5 (${Math.floor((salutationScore / 5) * 100)}%)`,
    totalGradePercent: `${Math.floor(WritingGradeOutput.SalutationGrade.percentage * 100)}%`,
    yourTotalGrade: `${(((WritingGradeOutput.SalutationGrade.Grade / 100) * WritingGradeOutput.SalutationGrade.percentage) * 100).toFixed(2).replace(/\.00$/, '')}%`
  })

  // Signature Grade
  let signatureScore = 0
  if (WritingGradeOutput.Signature.CorrectClosingSalutation) signatureScore += 1;
  if (WritingGradeOutput.Signature.CorrectSignature) signatureScore += 1;

  gradeOverview.push({
    title: 'Signature',
    yourScore: `${signatureScore}/2 (${Math.floor((signatureScore / 2) * 100)}%)`,
    totalGradePercent: `${Math.floor(WritingGradeOutput.Signature.percentage * 100)}%`,
    yourTotalGrade: `${(((WritingGradeOutput.Signature.Grade / 100) * WritingGradeOutput.Signature.percentage) * 100).toFixed(2).replace(/\.00$/, '')}%`
  })

  // Date Grade
  let dateScore = 0
  if (WritingGradeOutput.DateGrade.CorrectDate) dateScore += 1;
  if (WritingGradeOutput.DateGrade.DateOnOwnLine) dateScore += 1;

  gradeOverview.push({
    title: 'Date',
    yourScore: `${dateScore}/2 (${Math.floor((dateScore / 2) * 100)}%)`,
    totalGradePercent: `${Math.floor(WritingGradeOutput.DateGrade.percentage * 100)}%`,
    yourTotalGrade: `${(((WritingGradeOutput.DateGrade.Grade / 100) * WritingGradeOutput.DateGrade.percentage) * 100).toFixed(2).replace(/\.00$/, '')}%`
  })

  // Patient Info Grade
  let patientInfoScore = 0
  if (WritingGradeOutput.PatientGrade.CorrectDateOfBirth) patientInfoScore += 1;
  if (WritingGradeOutput.PatientGrade.CorrectPatientName) patientInfoScore += 1;
  if (WritingGradeOutput.PatientGrade.CorrectRegards) patientInfoScore += 1;
  if (WritingGradeOutput.PatientGrade.PatientDataOnOwnLine) patientInfoScore += 1;

  gradeOverview.push({
    title: 'Patient Info',
    yourScore: `${patientInfoScore}/4 (${Math.floor((patientInfoScore / 4) * 100)}%)`,
    totalGradePercent: `${Math.floor(WritingGradeOutput.PatientGrade.percentage * 100)}%`,
    yourTotalGrade: `${(((WritingGradeOutput.PatientGrade.Grade / 100) * WritingGradeOutput.PatientGrade.percentage) * 100).toFixed(2).replace(/\.00$/, '')}%`
  })

  // Content Grade
  let totalContentScore = 0

  WritingGradeOutput.ContentGrade.forEach((content) => {
    if (content.matched) {
      totalContentScore += 1;
    }
  })

  gradeOverview.push({
    title: 'Content',
    yourScore: `${totalContentScore}/${WritingGradeOutput.ContentGrade.length} (${Math.floor((totalContentScore / WritingGradeOutput.ContentGrade.length) * 100)}%)`,
    totalGradePercent: `${Math.floor(WritingGradeOutput.ContentGradePercentage * 100)}%`,
    yourTotalGrade: `${(((totalContentScore / WritingGradeOutput.ContentGrade.length) * WritingGradeOutput.ContentGradePercentage) * 100).toFixed(2).replace(/\.00$/, '')}%`
  })

  // Total Word Count Grade
  gradeOverview.push({
    title: 'Total Word Count',
    yourScore: `${WritingGradeOutput.WordCountGradeNumber / 100}/1 (${Math.floor(WritingGradeOutput.WordCountGradeNumber * 100)}%)`,
    totalGradePercent: `${Math.floor(WritingGradeOutput.WordCountGradePercentage * 100)}%`,
    yourTotalGrade: `${(((WritingGradeOutput.WordCountGradeNumber) * WritingGradeOutput.WordCountGradePercentage) * 100).toFixed(2).replace(/\.00$/, '')}%`
  })
  // Paragraph Word Count Grade
  gradeOverview.push({
    title: 'Paragraph Word Count',
    yourScore: `${WritingGradeOutput.ParagraphWordCountGradeNumber / 100}/1 (${Math.floor(WritingGradeOutput.ParagraphWordCountGradeNumber)}%)`,
    totalGradePercent: `${Math.floor(WritingGradeOutput.ParagraphWordCountGradePercentage * 100)}%`,
    yourTotalGrade: `${(((WritingGradeOutput.ParagraphWordCountGradeNumber) * WritingGradeOutput.ParagraphWordCountGradePercentage)).toFixed(2).replace(/\.00$/, '')}%`
  })
  // Grammar/Spelling Grade
  gradeOverview.push({
    title: 'Grammar/Spelling',
    yourScore: `${WritingGradeOutput.GrammarGradeNumber / 100}/1 (${Math.floor(WritingGradeOutput.GrammarGradeNumber)}%)`,
    totalGradePercent: `${Math.floor(WritingGradeOutput.GrammarGradePercentage * 100)}%`,
    yourTotalGrade: `${(((WritingGradeOutput.GrammarGradeNumber) * WritingGradeOutput.GrammarGradePercentage)).toFixed(2).replace(/\.00$/, '')}%`
  })

  // Total Grade
  gradeOverview.push({
    title: 'Your Total Grade',
    yourScore: '',
    totalGradePercent: '100%',
    yourTotalGrade: `${WritingGradeOutput.TotalOverallGrade.toFixed(2).replace(/\.00$/, '')}%`
  })

  section.gradeOverview = gradeOverview
  return section
}

export default createGradeOverview;