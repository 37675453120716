import React from 'react'
import './ProcessingAudioPopup.css'
import Spinner from 'react-md-spinner'

export default function ProcessingAudioPopup() {
  return (
    <div className='processingAudioPopupPageWrapper'>
      <div className='processingAudioPopupBackdrop' />
      <div className='processingAudioPopupContainer'>
        <div className='processingAudioPopupHeader'>
          <p className='processingAudioPopupHeaderText'>Processing Audio</p>
        </div>
        <div className='processingAudioPopupBody'>
          <Spinner
            size={40}
            singleColor={'#1a517b'}
          />
          <p className='processingAudioPopupBodyText'>Please wait while your audio is processed. This can take a minute</p>
        </div>
      </div>
    </div>
  )
}
