import React from 'react'
import './TopTenCasesModule.css'

export default function TopTenCases(props) {

    return (
        <div className='reportCardModuleContainer topTenCasesModuleContainer'>
            <div className='reportCardModuleHeaderContainer'>
                <h2 className='reportCardModuleHeaderText'>{props.title}</h2>
            </div>
            <div className='topTenCasesTitleContainer'>
                {/* <h3 className='topTenCasesTitleText' style={{justifySelf: 'start', paddingLeft: 10}}>Case Number</h3> */}
                <h3 className='topTenCasesTitleText'>Exam Number</h3>
                <h3 className='topTenCasesTitleText'>Exam Name</h3>
                <h3 className='topTenCasesTitleText'>Your Best Score</h3>
            </div>
            {props.userData.map((caseData, caseIndex) => (
                <div className='topTenCasesBodyContainer' key={`topTenCases-${props.title}-${caseIndex}`}>
                    {/* <p className='topTenCasesBodyText' style={{justifySelf: 'start', paddingLeft: 10, textAlign: 'start'}}>{caseData.CaseId}</p> */}
                    <p className='topTenCasesBodyText'>{caseData.CaseId}</p>
                    <p className='topTenCasesBodyText'>{caseData.CategoryId}</p>
                    <p className='topTenCasesBodyText'>{Math.floor(caseData.BestGrade * 100) / 100}%</p>
                </div>
            ))}
        </div>
    )
}
