import React, { Component } from 'react'
import './AccountSettings.css'
import Logo from '../../Assets/logoCropped.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faListDots } from '@fortawesome/free-solid-svg-icons'
import { faFileChartPie, faEye } from '@fortawesome/pro-regular-svg-icons'
import MDSpinner from 'react-md-spinner'
import FaqQuestionsArray from '../../FAQ_Json.json'
import formatDateString from '../../Functions/FormatDateString'
import formatTimeString from '../../Functions/FormatTimeString'

class Switch extends Component {
    render() {
        return (
            <button onClick={this.props.onClick} className={`switchContainer${this.props.value ? ' switchContainerToggled' : ''}${this.props.customClass ? ` ${this.props.customClass}` : ''}`}>
                <div style={{left: this.props.value ? '30px' : '6px'}} className='switchButton' />
            </button>
        )
    }
}

export default class AccountSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoadingPopup: true,
            hideHighYield: false,
            newCaseSelector: true,
            hideCompletedCases: false,
            selectedTimeZone: "-7",
            pageDisplayed: "Settings",
            showLoadingPopupLoading: false,
            hideHighYieldLoading: false,
            hideCaseDiagnosisLoading: false,
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            settingsMessage: "",
            hideCasePopupToggleLoading: false,
            showNewCaseSelectorLoading: false,
            successMessage: null,
            changePasswordError: null,
            submitFeedbackError: null,
            faqQuestions: [],
            lastFourDigits: "",
            disableFeedbackLoading: false,
            professions: [
                'Dentistry',
                'Dietetics',
                'Medicine',
                'Nursing',
                'Occupational Therapy',
                'Optometry',
                'Pharmacy',
                'Physiotherapy',
                'Podiatry',
                'Radiography',
                'Speech Pathology',
                'Veterinary Science'
            ],
            selectProfessionOutstanding: false,
            isChangeSignatureModalDisplayed: false,
            userSignature: ''
        }
    }

    componentDidMount() {
        var metaThemeColor = document.querySelector("meta[name=theme-color]")
        metaThemeColor.setAttribute("content", this.props.themeStyle.tertiaryBackgroundColor)
        this.updateFaqQuestionsHandler()
    }

    handlePageChange = (page) => {
        this.props.changePage(page)
    }

    showLoadingPopupHandler = async () => {
        this.setState({showLoadingPopupLoading: true}, async () => {
            await this.props.showLoadingHandler()
            this.setState({showLoadingPopupLoading: false})
        })
    }

    hideHighYieldHandler = async () => {
        this.setState({hideHighYieldLoading: true}, async () => {
            await this.props.hideHighYieldHandler()
            this.setState({hideHighYieldLoading: false})
        })
    }

    changePasswordHandler = async (e) => {
        e.preventDefault()
        if (this.state.newPassword !== this.state.confirmNewPassword) {
			this.setState({changePasswordError: 'New Password and Confirm Password must match'});
		}
		else {
            this.setState({changePasswordLoading: true})
			fetch(`${this.props.route}/changepassword.webapi`, {
				method: 'POST',
				headers: {
					'Token': this.props.userData.Token,
					'Content-Type': 'text/plain',
				},
				body: JSON.stringify({
					customerId: this.props.userData.CustomerId,
					oldPassword: this.state.currentPassword,
					newPassword: this.state.newPassword,
				})
			})
			.then(response => {
                this.setState({changePasswordLoading: false})
				if (response.status === 401) {
                    // show authentication popup
                    this.props.displayAuthenticationError()
				} else {
					return response.text()
				}
			})
			.then(response => {
				if (response.includes('Password updated')) {
                    this.setState({
                        successMessage: 'Password Updated Successfully',
                        pageDisplayed: "Settings",
                        currentPassword: "",
                        newPassword: "",
                        confirmNewPassword: "",
                        changePasswordError: null
                    })
				} else {
                    // Remove curly braces from message
                    let result = response.replace('{', '')
                    result = result.replace('}', '')
                    this.setState({changePasswordError: result})
				}
			})
            .catch(error => {
                console.log(error)
                this.setState({settingsMessage: error, changePasswordLoading: false, changePasswordError: error})
            })
		}
        // this.setState({changePasswordLoading: true}, async () => {
        //     await this.props.fetchChangePassword(this.state.currentPassword, this.state.newPassword, this.state.confirmNewPassword)
        //     this.setState({changePasswordLoading: false, pageDisplayed: 'Settings'})
        // })
    }

    submitFeedbackHandler = async () => {
        this.setState({submitFeedbackLoading: true})
        await fetch(`${this.props.route}/feedback.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.props.userData.CustomerId,
				feedback: this.state.feedback
			})
		})
		.then((response) => {
			if (response.status === 401) {
				this.props.displayAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then((response) => {
            this.setState({
                submitFeedbackLoading: false,
                successMessage: 'Thank you for your feedback.',
                pageDisplayed: 'Settings',
                feedback: '',
                submitFeedbackError: null
            })
		})
		.catch((error) => {
            console.log(error)
            this.setState({
                submitFeedbackLoading: false,
                submitFeedbackError: `${error}`
            })
		})
    }

    updateTimeZone = async (e) => {
        let value = e.target.value
        this.setState({updateTimeZoneLoading: true}, async () => {
            await this.props.updateSelectedTimeZone(value)
            this.setState({updateTimeZoneLoading: false})
        })
    }

    toggleCaseSelector = async () => {
        this.setState({showNewCaseSelectorLoading: true})
        await this.props.toggleNewCaseSelector()
        this.setState({showNewCaseSelectorLoading: false})
    }

    updateFaqQuestionsHandler = () => {
		let questions = []
		let answers = []
		FaqQuestionsArray.forEach((value, index) => {
			let beginning = value.slice(0, 10)
			if (beginning.includes('question:')) {
				let question = value.slice(-value.length + 10)
				questions.push(question)
			} else if (beginning.includes('answer:')) {
				let answer = value.slice(-value.length + 8)
				answers.push(answer)
			}
		})
		let FAQ = []
		questions.forEach((value, index) => {
			FAQ.push({
				question: value,
				answer: answers[index]
			})
		})
		this.setState({faqQuestions: FAQ})
	}

    disableAnimationsHandler = async () => {
        this.setState({disableAnimationsLoading: true})
        await this.props.disableAnimationsHandler()
        this.setState({disableAnimationsLoading: false})
    }

    getSubscriptionDaysRemaining = () => {
        let expirationDate = new Date(this.props.userData.LicenseExpiration)
        let today = new Date()
        let daysRemaining = Math.floor((expirationDate - today) / (1000 * 60 * 60 * 24))
        return daysRemaining
    }

    changeSettingsPage = (newPage) => {
        this.setState({pageDisplayed: newPage}, () => {
            let pageWrapper = document.getElementById('accountSettingsPageContainer')
            if (pageWrapper) {
                pageWrapper.scrollIntoView({block: 'start', inline: 'nearest'})
            }
        })
    }

    handlePrintReceipt = () => {
        let confirmationPopupOptions = {
            title: `Print Your Receipt`,
            description: `If you need the last four digits of your card added to your receipt, please enter them here.`,
            confirmFunction: async () => {
                this.props.setPopupLoading(true)
                await this.fetchPrintReceipt()
                this.props.setPopupLoading(false)
            },
            children: (
                <div className='printReceiptInputContainer'>
                    <label htmlFor="printReceiptInput" className='printReceiptInputLabel standardLabel'>Last Four Digits of Card</label>
                    <input className='standardInput' id="printReceiptInput" type='text' placeholder='Last 4' maxLength={4} onChange={(e) => {this.setState({lastFourDigits: e.target.value})}}/>
                </div>
            )
        }
        this.props.displayConfirmationPopup(confirmationPopupOptions)
    }

    handleSetUserSignature = async () => {
        // Handle user signature submission to server
        await fetch(`${this.props.route}/sendusersignature.webapi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': this.props.userData.Token
            },
            body: JSON.stringify({
                customerId: this.props.userData.CustomerId,
                CustomerSignature: this.state.userSignature
            })
        })
        .then((response) => {
            if (response.status === 200) {
                return response.text()
            } else {
                throw new Error()
            }
        })
        .then(() => {
            this.props.updateUserSignature(this.state.userSignature)
            this.setState({userSignature: ''})
            this.props.closeConfirmationPopup()
        })
        .catch((error) => {
            this.setState({selectProfessionOutstanding: false})
            window.alert('Unable to submit your signature. Please try again.')
        })
    }

    handleChangeSignature = () => {
        let confirmationPopupOptions = {
            title: `Change Your Signature`,
            description: `During the exam, you are required to write a letter and sign it with your name with or without a title. Please enter how you will be signing your letter (i.e. Dr. George Smith or Dr. George Smith, MD).`,
            confirmFunction: async () => {
                this.props.setPopupLoading(true)
                await this.handleSetUserSignature()
                this.props.setPopupLoading(false)
            },
            children: (
                <div className='printReceiptInputContainer'>
                    {/* <label htmlFor="changeSignatureInput" className='printReceiptInputLabel standardLabel'>Signature</label> */}
                    <input className='standardInput' id="changeSignatureInput" type='text' placeholder='Test User' onChange={(e) => {this.setState({userSignature: e.target.value})}}/>
                </div>
            )
        }
        this.props.displayConfirmationPopup(confirmationPopupOptions)
    }

    fetchPrintReceipt = async () => {
        if (isNaN(Number(this.state.lastFourDigits))) {
            return window.alert('Invalid Last 4 Entry')
        } else if (this.state.lastFourDigits.length !== 0 && this.state.lastFourDigits.length !== 4) {
            return window.alert('Invalid Last 4 Entry')
        }

        try {
            let printReceiptFetch = await fetch(`${this.props.route}/invoicetopdf.webapi`, {
                method: 'POST',
                headers: {
                    'Token': this.props.userData.Token,
                    'Content-Type': 'text/plain'
                },
                body: JSON.stringify({
                    CustomerId: this.props.userData.CustomerId,
                    Last4: this.state.lastFourDigits
                })
            })
    
            if (!printReceiptFetch.ok) {
                return window.alert('Error: Unable to print receipt')
            }

            if (printReceiptFetch.status === 401) {
                return this.props.displayAuthenticationError()
            }
    
            let printReceiptHTML = await printReceiptFetch.text()

            this.props.closeConfirmationPopup()
            this.props.setPrintReceiptHTML(printReceiptHTML)
        } catch(error) {
            console.log(error)
        }
    }

    updateProfession = (e) => {
        let professionId = this.state.professions.indexOf(e.target.value) + 1
        fetch(`${this.props.route}/updateprofession.webapi`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Token': this.props.userData.Token
        },
        body: JSON.stringify({
            customerId: this.props.userData.CustomerId,
            professionId
        })
        })
        .then((response) => {
            if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.props.displayAuthenticationError()
			} else {
				throw new Error('Error')
			}
        })
        .then((responseText) => {
            this.props.updateProfession(e.target.value)
        })
        .catch((error) => {
            window.alert('There was an error updating your profession. Please try again later.')
            e.target.value = this.props.userData.Profession
        })
    }

    toggleDisableFeedback = async () => {
        this.setState({disableFeedbackLoading: true})
        await this.props.toggleDisableFeedback()
        this.setState({disableFeedbackLoading: false})
    }

    render() {
        return (
            <div className='accountSettingsPageContainer' id="accountSettingsPageContainer">
                <nav className='accountSettingsNavbar'>
                    {/* <img className='accountSettingsLogo' alt="CCS Cases Logo" src={Logo}/> */}
                    <h1 className='caseListLogoText'>OCC<span className='caseListLogoTextNormal'>English</span></h1>
                    {/* <button className='accountSettingsNavButton secondaryButton' onClick={() => this.handlePageChange('reportCard')}>
                        <FontAwesomeIcon icon={faFileChartPie} className='accountSettingsNavButtonIcon'/>
                        Report Card
                    </button> */}
                    {/* <button className='accountSettingsNavButton secondaryButton' onClick={() => this.handlePageChange('caseList')}>
                        <FontAwesomeIcon icon={faListDots} className='accountSettingsNavButtonIcon' />
                        Case Selection
                    </button> */}
                    {/* <button className='accountSettingsMobileNavButton secondaryButton' onClick={() => this.handlePageChange('reportCard')}>
                        <FontAwesomeIcon icon={faFileChartPie} className='accountSettingsNavButtonIcon' />
                    </button>
                    <button className='accountSettingsMobileNavButton secondaryButton' onClick={() => this.handlePageChange('caseList')}>
                        <FontAwesomeIcon icon={faListDots} className='accountSettingsNavButtonIcon' />
                    </button> */}
                </nav>
                { this.state.pageDisplayed === 'Settings' ?
                    <div className='accountSettingsBodyWrapper' id='SettingsPage' key='SettingsPage'>
                        <button className='accountSettingsBackButton tertiaryButton' onClick={() => this.handlePageChange('CaseList')}>
                            <FontAwesomeIcon icon={faArrowLeft} className='accountSettingsBackArrowIcon'/>
                            Back to Case List
                        </button>
                        {this.state.successMessage &&
                            <div className='accountSettingsSuccessMessageContainer'>
                                <p className='accountSettingsSuccessMessageText'>{this.state.successMessage}</p>
                                <button className='accountSettingsSuccessCloseButton' onClick={() => this.setState({successMessage: null})} />
                            </div>
                        }
                        <div className='accountSettingsTitleContainer' key='Title Container'>
                            <h1 className='accountSettingsTitle'>Account Settings</h1>
                            <button className='accountSettingsTitleButton' onClick={this.props.logout}>Logout</button>
                        </div>
                        <div className='accountSettingsProfileContainer' key="Profile Details Container">
                            <h3 className='accountSettingsCardTitle accountSettinsProfileDetailsHeader'>Profile Details</h3>
                            <p className='accountSettingsProfileProperty'>Name</p>
                            <p className='accountSettingsProfileValue'>{this.props.userData.CustomerFirstName} {this.props.userData.CustomerLastName}</p>
                            <p className='accountSettingsProfileProperty'>Username</p>
                            <p className='accountSettingsProfileValue'>{this.props.userData.CustomerUserName}</p>
                            <p className='accountSettingsProfileProperty'>Account Creation Date</p>
                            <p className='accountSettingsProfileValue'>{formatDateString(this.props.userData.DateCustCreated, this.props.userData.TimeZone)} {formatTimeString(this.props.userData.DateCustCreated, this.props.userData.TimeZone)}</p>
                            <p className='accountSettingsProfileProperty'>Demo</p>
                            <p className='accountSettingsProfileValue'>{this.props.userData.Demo ? 'Yes' : 'No'}</p>
                            <p className='accountSettingsProfileProperty'>Case Completion Limit</p>
                            <p className='accountSettingsProfileValue'>{this.props.userData.caseCompletionLimit ? 'Yes' : 'No'}</p>
                            <p className='accountSettingsProfileProperty'>Subscription Expiration</p>
                            <p className='accountSettingsProfileValue'>{formatDateString(this.props.userData.LicenseExpiration, this.props.userData.TimeZone)} {formatTimeString(this.props.userData.LicenseExpiration, this.props.userData.TimeZone)}</p>
                        </div>
                        {this.props.userData.demo &&
                            <div className='accountSettingsCard accountSettingsBigCard' key='Demo Upgrade Container'>
                                <h3 className='accountSettingsCardTitle'>Upgrade To Full Account</h3>
                                <p className='accountSettingsToggleDescription'>Upgrading to a full account unlocks unlimited access to our collection of over 150 cases!</p>
                                <ol className='accountSettingsOrderedList'>
                                    <li className='accountSettingsOrderedListNumber'>1</li>
                                    <li className='accountSettingsOrderedListItem'><p>Click “Upgrade Now” or navigate to https://www.ccscases.com/purchase</p></li>
                                    <li className='accountSettingsOrderedListNumber'>2</li>
                                    <li className='accountSettingsOrderedListItem'><p>Select the amount of time that you need to study for your exam.</p></li>
                                    <li className='accountSettingsOrderedListNumber'>3</li>
                                    <li className='accountSettingsOrderedListItem'><p>At checkout, use the same e-mail address as this account and your account will be automatically upgraded from a demo to a full account.</p></li>
                                </ol>
                                <a href="https://www.ccscases.com/purchase" rel="noopener noreferrer" target="_blank">
                                    <button className='accountSettingsButton accountSettingsUpgradeButton'>Upgrade Now</button>
                                </a>
                            </div>
                        }
                        <div className='accountSettingsCard accountSettingsBigCard' key="Theme Card">
                            <h3 className='accountSettingsCardTitle'>Theme</h3>
                            <p className='accountSettingsToggleDescription'>Personalize your studying by selected a theme that feels more like you.</p>
                            <div className='accountSettingsThemeBreakLine' />
                            {this.props.themes.map((theme, index) => (
                                <div className='accountSettingsThemeWrapper' key={`theme #${index + 1}`}>
                                    <h4 className='accountSettingsThemeTitle'>{theme.themeTitle}</h4>
                                    <p className='accountSettingsThemeDescription'>{theme.themeDescription}</p>
                                    <div className='accountSettingsThemeColorSquaresContainer'>
                                        <div className='accountSettingsThemeColorSquare' style={{backgroundColor: theme.primaryColor}}/>
                                        <div className='accountSettingsThemeColorSquare' style={{backgroundColor: theme.secondaryColor}}/>
                                        <div className='accountSettingsThemeColorSquare' style={{backgroundColor: theme.primaryBackgroundColor}}/>
                                        <div className='accountSettingsThemeColorSquare' style={{backgroundColor: theme.secondaryBackgroundColor}}/>
                                        <div className='accountSettingsThemeColorSquare' style={{backgroundColor: theme.tertiaryBackgroundColor}}/>
                                    </div>
                                    {this.props.themeChangeIndex === index ?
                                        <div className='accountSettingsThemeSpinnerContainer'>
                                            <MDSpinner
                                                size={30}
                                                singleColor={this.props.primaryColor}
                                            />
                                        </div>
                                    :
                                        <button
                                            className={`accountSettingsThemeButton ${this.props.selectedTheme === index ? 'accountSettingsThemeButtonActive' : ''} ${(this.props.themeChangeIndex !== -1 && this.props.themeChangeIndex !== index) ? 'accountSettingsThemeButtonDisabled' : ''}`}
                                            onClick={this.props.selectedTheme === index ? () => {} : () => this.props.changeTheme(index)}
                                        >
                                            {this.props.selectedTheme === index ? 'Active' : 'Activate'}
                                        </button>
                                    }
                                </div>
                            ))}
                        </div>
                        <div className='accountSettingsCardColumn1'>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Hide High Yield</h3>
                                <p className='accountSettingsToggleDescription'>Users will report which cases they feel is most likely to be seen on the real exam. If you'd like, you can hide these results.</p>
                                <Switch
                                    value={this.props.userData.TurnOffHighYield}
                                    onClick={this.hideHighYieldHandler}
                                    customClass='accountSettingsToggle'
                                />
                                {this.state.hideHighYieldLoading &&
                                    <div className='accountSettingsCardLoaderOverlay'>
                                        <MDSpinner
                                            size={35}
                                            singleColor={'#fff'}
                                        />
                                    </div>
                                }
                            </div>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Change Password</h3>
                                <p className='accountSettingsToggleDescription'>Change the password you use to log in.</p>
                                <button className='accountSettingsButton' onClick={() => this.changeSettingsPage('ChangePassword')}>Change Password</button>
                            </div>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Report Bug/Feedback</h3>
                                <p className='accountSettingsToggleDescription'>Noticed a bug or have something to share with us? Let us know here.</p>
                                <button className='accountSettingsButton' onClick={() => this.changeSettingsPage('ReportBug/Feedback')}>Report Bug/Feedback</button>
                            </div>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Disable Animations</h3>
                                <p className='accountSettingsToggleDescription'>If you'd like to disable animations throughout the application, you can toggle that here.</p>
                                <Switch
                                    value={this.props.userData.AnimationsDisabled}
                                    onClick={this.disableAnimationsHandler}
                                    customClass='accountSettingsToggle'
                                />
                                {this.state.disableAnimationsLoading &&
                                    <div className='accountSettingsCardLoaderOverlay'>
                                        <MDSpinner
                                            size={35}
                                            singleColor={'#fff'}
                                        />
                                    </div>
                                }
                            </div>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Profession</h3>
                                <p className='accountSettingsToggleDescription'>If you selected the wrong profession when logging in for the first time, you can change that here.</p>
                                <select className='accountSettingsDropdown' defaultValue={this.props.userData.Profession} onChange={this.updateProfession}>
                                    {this.state.professions.map((profession, index) => (
                                        <option key={index} value={profession}>{profession}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>View FAQ</h3>
                                <p className='accountSettingsToggleDescription'>Have a question about our software? We answer those here.</p>
                                <button className='accountSettingsButton' onClick={() => this.changeSettingsPage('FAQ')}>View FAQ</button>
                            </div>
                        </div>
                        <div className='accountSettingsCardColumn2'>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Resubscribe</h3>
                                <p className='accountSettingsToggleDescription'>You have <strong>{this.getSubscriptionDaysRemaining()} Days</strong> remaining in your subscription ending <strong>{formatDateString(this.props.userData.LicenseExpiration, this.props.userData.TimeZone)}</strong>. If you need more time to study, you can resubscribe by following these instructions:</p>
                                <ol className='accountSettingsOrderedList'>
                                    <li className='accountSettingsOrderedListNumber'>1</li>
                                    <li className='accountSettingsOrderedListItem'><p>Click “Resubscribe” or navigate to https://www.ccscases.com/purchase</p></li>
                                    <li className='accountSettingsOrderedListNumber'>2</li>
                                    <li className='accountSettingsOrderedListItem'><p>Select the amount of time that you would like to extend your subscription</p></li>
                                    <li className='accountSettingsOrderedListNumber'>3</li>
                                    <li className='accountSettingsOrderedListItem'><p>At checkout, use the same e-mail address that you used to subscribe originally. The system will automatically locate your account and extend your subscription.</p></li>
                                </ol>
                                <a href="https://www.ccscases.com/purchase" rel="noopener noreferrer" target="_blank">
                                    <button className='accountSettingsButton'>Resubscribe</button>
                                </a>
                            </div>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Time Zone</h3>
                                <p className='accountSettingsToggleDescription'>Setting your time zone will make device times, like case completion dates, display in your local time instead of UTC.</p>
                                <select className='accountSettingsDropdown' value={this.props.userData.TimeZone} onChange={this.updateTimeZone}>
                                    <option value="-12">GMT -12 US Minor Outlying Islands</option>
                                    <option value="-11">GMT -11 US Minor Outlying Islands</option>
                                    <option value="-10">GMT -10 Honolulu</option>
                                    <option value="-9">GMT -9 Alaska Time</option>
                                    <option value="-8">GMT -8 Pacific Time</option>
                                    <option value="-7">GMT -7 Mountain Time</option>
                                    <option value="-6">GMT -6 Central Time</option>
                                    <option value="-5">GMT -5 Eastern Time</option>
                                    <option value="-4">GMT -4 Atlantic Time</option>
                                    <option value="-3">GMT -3 Greenland</option>
                                    <option value="-2">GMT -2 Brazil</option>
                                    <option value="-1">GMT -1 Portugal</option>
                                    <option value="+0">GMT +0 United Kingdom</option>
                                    <option value="+1">GMT +1 Paris</option>
                                    <option value="+2">GMT +2 Johannesburg</option>
                                    <option value="+3">GMT +3 Moscow</option>
                                    <option value="+4">GMT +4 Dubai</option>
                                    <option value="+5">GMT +5 Karachi</option>
                                    <option value="+6">GMT +6 Dhaka</option>
                                    <option value="+7">GMT +7 Jakarta</option>
                                    <option value="+8">GMT +8 Shanghai</option>
                                    <option value="+9">GMT +9 Tokyo</option>
                                    <option value="+10">GMT +10 Sydney</option>
                                    <option value="+11">GMT +11 Nouméa</option>
                                    <option value="+12">GMT +12 Auckland</option>
                                    <option value="+13">GMT +13 Tonga</option>
                                    <option value="+14">GMT +14 Kiribati</option>
                                </select>
                            </div>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Disable Grading Feedback</h3>
                                <p className='accountSettingsToggleDescription'>If you'd prefer grading feedback not tell you what you ordered right or wrong, you can toggle that here.</p>
                                <Switch
                                    value={this.props.userData.DisableFeedback}
                                    onClick={() => this.toggleDisableFeedback()}
                                    customClass='accountSettingsToggle'
                                />
                                {this.state.disableFeedbackLoading &&
                                    <div className='accountSettingsCardLoaderOverlay'>
                                        <MDSpinner
                                            size={35}
                                            singleColor={'#fff'}
                                        />
                                    </div>
                                }
                            </div>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Your Signature</h3>
                                <p className='accountSettingsToggleDescription'>During the exam, you are required to write a letter and sign it with your name with or without a title. Your signature is currently <strong>{this.props.userData.UserSignature}</strong></p>
                                <button className='accountSettingsButton' onClick={this.handleChangeSignature}>Change Signature</button>
                            </div>
                            <div className='accountSettingsCard'>
                                <h3 className='accountSettingsCardTitle'>Print Receipt</h3>
                                <p className='accountSettingsToggleDescription'>Need a copy of your account receipt? You can download a copy or print it here.</p>
                                <button className='accountSettingsButton' onClick={this.handlePrintReceipt}>Print Receipt</button>
                            </div>
                        </div>
                    </div>
                : this.state.pageDisplayed === 'ChangePassword' ?
                    <div className='accountSettingsBodyWrapper' id="ChangePassword" key="ChangePassword">
                        <form className='accountSettingsCard accountSettingsBigCard' onSubmit={this.changePasswordHandler}>
                            <h3 className='accountSettingsCardTitle'>Change Password</h3>
                            <p className='accountSettingsToggleDescription'>Update the password you use to log in</p>
                            <label for="currentPassword" className='standardLabel'>Current Password</label>
                            <input type="password" value={this.state.currentPassword} onChange={(e) => this.setState({currentPassword: e.target.value})} id="currentPassword" autofill="current-password" className='standardInput' />
                            <label for="newPassword" className='standardLabel'>New Password</label>
                            <input type="password" value={this.state.newPassword} onChange={(e) => this.setState({newPassword: e.target.value})} autofill="new-password" id="newPassword" className='standardInput' />
                            <label for="confirmNewPassword" className='standardLabel'>Confirm New Password</label>
                            <input type="password" value={this.state.confirmNewPassword} onChange={(e) => this.setState({confirmNewPassword: e.target.value})} autofill="new-password" id="confirmNewPassword" className='standardInput' />
                            {this.state.changePasswordError && <p className='changePasswordErrorText'>{this.state.changePasswordError}</p>}
                            <div className='accountSettingsButtonWrapper'>
                                <button className='accountSettingsButton secondaryButton' type='button' onClick={() => this.setState({changePasswordError: null}, () => this.changeSettingsPage('Settings'))}>Cancel</button>
                                {this.state.changePasswordLoading ?
                                    <div className='accountSettingsCardButtonLoaderContainer'>
                                        <MDSpinner
                                            size={35}
                                            singleColor={this.props.primaryColor}
                                        />
                                    </div>
                                :
                                    <button className='accountSettingsButton tertiaryButton' type='submit'>Confirm</button>
                                }
                            </div>
                        </form>
                    </div>
                : this.state.pageDisplayed === 'ReportBug/Feedback' ?
                    <div className='accountSettingsBodyWrapper' id="ReportBug/Feedback" key="ReportBug/Feedback">
                        <div className='accountSettingsCard accountSettingsBigCard' key='Report Bug'>
                            <h3 className='accountSettingsCardTitle'>Report Bug/Feedback</h3>
                            <p className='accountSettingsToggleDescription'>Noticed a bug or have something to share with us? Let us know here.</p>
                            <label for="feedback" className='standardLabel'>Enter Feedback</label>
                            <textarea type="text" value={this.state.feedback} onChange={(e) => this.setState({feedback: e.target.value})} id="feedback" autofill="none" className='standardTextArea' />
                            {this.state.submitFeedbackError && <p className='changePasswordErrorText'>{this.state.submitFeedbackError}</p>}
                            {this.state.submitFeedbackLoading ?
                                <div className='accountSettingsCardButtonLoaderContainer'>
                                    <MDSpinner
                                        size={35}
                                        singleColor={this.props.primaryColor}
                                    />
                                </div>
                            :
                                <div className='accountSettingsButtonWrapper'>
                                    <button className='accountSettingsCancelButton secondaryButton' onClick={() => this.setState({submitFeedbackError: null}, () => this.changeSettingsPage('Settings'))}>Cancel</button>
                                    <button className='accountSettingsButton tertiaryButton' onClick={this.submitFeedbackHandler}>Confirm</button>
                                </div>
                            }
                        </div>
                    </div>
                : this.state.pageDisplayed === 'FAQ' &&
                    <div className='accountSettingsBodyWrapper' id="FAQ" key="FAQ">
                        <button className='accountSettingsBackButton tertiaryButton' onClick={() => this.changeSettingsPage('Settings')}>
                            <FontAwesomeIcon icon={faArrowLeft} className='accountSettingsBackArrowIcon'/>
                            Back to Settings
                        </button>
                        <div className='accountSettingsCard accountSettingsBigCard'>
                            <h3 className='accountSettingsFaqQuestion'>FAQ</h3>
                            <p className='accountSettingsToggleDescription' style={{marginBottom: 0}}>Have a question about our software? We answer those here.</p>
                        </div>
                        {this.state.faqQuestions.map((question, index) => (
                            <div className='accountSettingsCard accountSettingsBigCard' key={`FAQ Question - ${index + 1}`}>
                                <h3 className='accountSettingsFaqQuestion'>{question.question}</h3>
                                <p className='accountSettingsFaqAnswer'>{question.answer}</p>
                            </div>
                        ))}
                    </div>
                }
            </div>
        )
    }
}
