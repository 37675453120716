import React from 'react'
import './CustomExamSectionCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faPencil, faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { motion } from 'framer-motion'
import MDSpinner from 'react-md-spinner'
import formatDateString from '../../../Functions/FormatDateString'

export default function CustomExamSectionCard(props) {
  return (
    // <motion.div
    //   initial={props.animationsDisabled ? false : {opacity: 1}}
    //   animate={props.animationsDisabled ? false : {opacity: 1}}
    //   exit={props.animationsDisabled ? false : {opacity: 1}}
    //   id={`section-${props.sectionData.sectionId}`}
    //   className={`caseListSectionContainer${props.isSectionSelected ? ' caseListCaseSelected' : ''}`}
    //   onClick={() => props.handleSectionClick(props.sectionData.sectionId)} key={'section - ' + props.sectionData.sectionTitle + ' - ' + props.sectionData.sectionId}
    // >
    <div
      id={`section-${props.sectionData.sectionId}`}
      className={`caseListSectionContainer${props.isSectionSelected ? ' caseListCaseSelected' : ''}`}
      onClick={() => props.handleSectionClick(props.sectionData)} key={'section - ' + props.sectionData.sectionTitle + ' - ' + props.sectionData.sectionId}
    >
      <div className={`caseListSectionCheckboxWrapper ${props.isSectionSelected ? 'caseListSectionCheckboxSelected' : ''}`}>
        <button className='caseListSectionCheckbox'>
          {props.isSectionSelected && <FontAwesomeIcon icon={faCheck} className='caseListSectionCheckboxIcon'/>}
        </button>
      </div>
      <div className='caseListSectionContentWrapper' style={props.forceEllipses ? {gridTemplateColumns: '1fr'} : {}}>
        <div className='caseListSectionDetailsWrapper' style={{rowGap: props.sectionData.Completed ? 2 : 5}}>
          <FontAwesomeIcon icon={props.sectionData.CategoryIcon} className='caseListSectionDetailsIcon'/>
          <h3 className='caseListSectionDetailsTitle'>{props.sectionData.sectionTitle}</h3>
          {!props.userData.TurnOffHighYield ?
            <p className='caseListSectionDetailsSubtitle'>Section {props.sectionData.sectionId} | {props.sectionData.HIGHYIELDCOUNT} High Yield</p>
          :
            <p className='caseListSectionDetailsSubtitle'>Section {props.sectionData.sectionId}</p>
          }
        </div>
        {props.sectionData.Completed && <p className='caseListSectionCompletionText'>Completed: {formatDateString(props.sectionData.LastCompletionDate, props.userData.TimeZone)} ({Math.floor(props.sectionData.secCompletion[props.sectionData.secCompletion.length - 1].GradeForExam * 100) / 100}%)</p>}
        {props.sectionData.Completed && !props.forceEllipses && <button className='caseListSectionViewGradesButton' onClick={(e) => props.viewCaseGradesClick(e, props.sectionData)}>View Grades</button>}
        {!props.forceEllipses &&
          <button className='caseListSectionReviewLaterButton' onClick={(e) => props.handleSectionReviewLater(e, props.sectionData)}>
            <div className='caseListSectionReviewLaterCheckboxWrapper'>
              {props.sectionData.ReviewLaterOutstanding ?
                <MDSpinner
                  size={15}
                  singleColor={props.primaryColor}
                />
              :
                <div className='caseListSectionReviewLaterCheckbox'>
                  {props.sectionData.ReviewLaterFlag && <FontAwesomeIcon icon={faCheck} className='caseListSectionReviewLaterCheckboxIcon'/>}
                </div>
              }
            </div>
            Review Later
          </button>
        }
        <button className='caseListSectionMobileMoreOptionsButton' style={props.forceEllipses ? {display: 'initial'} : {}} onClick={(e) => props.toggleCaseListSectionMoreOptions(e, props.sectionData)}>
          <FontAwesomeIcon icon={faEllipsisVertical} className='caseListSectionMobileMoreOptionsIcon' />
        </button>
      </div>
    </div>
    // </motion.div>
  )
}
