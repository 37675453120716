import React from 'react'
import './TextArea.css'

export default function TextArea(props) {
  return (
    <div className='textAreaWrapper'>
      <textarea
        className='textAreaInput'
        placeholder=''
        value={props.answer}
        onChange={(e) => props.setAnswer(e.target.value)}
      />
      <div className='textAreaFooter'>
        <p className='textAreaWordCountText'>Word Count: {props.answer.split(" ").filter((value) => value !== "").length}</p>
      </div>
    </div>
  )
}
