import React, { Component } from 'react'
import './App.css'
import LoginPage from './Login/LoginPage'
import Simulation from './Components/Simulation/Simulation'
import AccountSettings from './Components/AccountSettings/AccountSettings'
import ConfirmationPopup from './Util/ConfirmationPopup/ConfirmationPopup'
import CaseList from './Components/CaseList/CaseList'
// import caseListData from './static_cases_info.json'
import { faBookOpen, faEarListen, faPencil, faKeynote } from '@fortawesome/pro-regular-svg-icons'
import { AnimatePresence } from 'framer-motion'
import Grading from './Components/Grading/Grading'
import UnauthenticatedPopup from './Util/UnauthenticatedPopup/UnauthenticatedPopup'
import ReportCard from './Components/ReportCard/ReportCard'

const THEMES = [
	{
		themeTitle: 'CCS Cases Classic',
		themeDescription: 'Our personal favorite - A modest design with high contrast accents',
		primaryColor: '#0B335D',
		secondaryColor: '#F79D23',
		primaryBackgroundColor: '#e7e7e7',
		secondaryBackgroundColor: '#ffffff',
		tertiaryBackgroundColor: '#3E3E3E',
		offBackgroundColor: '#cecece',
		textColor: '#1d1d1d',
		caseTextColor: '#1d1d1d',
		secondaryTextColor: '#ffffff',
		offTextColor: '#686868',
		secondaryOffTextColor: '#cecece',
		tertiaryOffTextColor: '#1d1d1d',
		caseSelectedColor: '#e5ebf3',
	},
	{
		themeTitle: 'CCS After Dark',
		themeDescription: 'A dark mode variant of our classic theme for the night owls out there.',
		primaryColor: '#3670ae',
		secondaryColor: '#F79D23',
		primaryBackgroundColor: '#252525',
		secondaryBackgroundColor: '#3c3c3c',
		tertiaryBackgroundColor: '#4a4a4a',
		offBackgroundColor: '#cecece',
		textColor: '#ffffff',
		caseTextColor: '#ffffff',
		secondaryTextColor: '#ffffff',
		offTextColor: '#a4a4a4',
		secondaryOffTextColor: '#cecece',
		tertiaryOffTextColor: '#ffffff',
		caseSelectedColor: '#454b53',
	},
	{
		themeTitle: 'CCS Staycation',
		themeDescription: 'Even doctors need a break. We thought we\'d bring the beach to you - You\'re welcome.',
		primaryColor: '#266595',
		secondaryColor: '#0e4976',
		primaryBackgroundColor: '#8b7555',
		secondaryBackgroundColor: '#ead6b6',
		tertiaryBackgroundColor: '#caac81',
		offBackgroundColor: '#ccb188',
		textColor: '#3e2708',
		caseTextColor: '#3e2708',
		secondaryTextColor: '#fff8ef',
		offTextColor: '#8a6f46',
		secondaryOffTextColor: '#5b3705',
		tertiaryOffTextColor: '#f5ddbc',
		caseSelectedColor: '#e5ebf3',
	},
	{
		themeTitle: 'CCS Jungle',
		themeDescription: 'We don\'t have a very good explanation for this one.',
		primaryColor: '#4c6c4c',
		secondaryColor: '#a4ad27',
		primaryBackgroundColor: '#543e2f',
		secondaryBackgroundColor: '#a4886b',
		tertiaryBackgroundColor: '#293928',
		offBackgroundColor: '#cecece',
		textColor: '#dfc3a2',
		caseTextColor: '#f5ddc0',
		secondaryTextColor: '#f9f0e7',
		offTextColor: '#ddcab3',
		secondaryOffTextColor: '#ddb68d',
		tertiaryOffTextColor: '#dfc3a2',
		caseSelectedColor: '#a88055',
	}
]

class App extends Component {
  constructor() {
    super()
    this.state = {
      userData: null,
      primaryColor: '#0B335D',
      route: 'https://app.occenglish.com',
      selectedExamSections: [],
      firstExamSection: null,
      fetchingStartExam: false,
      pageDisplayed: 'Login',
      selectedTheme: 0,
      themeChangeIndex: -1,
			categories: [
				{
					checked: true,
					name: 'Listening',
					icon: faEarListen
				},
				{
					checked: true,
					name: 'Writing',
					icon: faPencil
				},
				{
					checked: true,
					name: 'Reading',
					icon: faBookOpen
				},
				{
					checked: true,
					name: 'Speaking',
					icon: faKeynote
				}
			],
			sortOrder: 'CaseNumber',
			sortDirection: 'Ascending',
			isHideIncompleteChecked: false,
			isHideCompletedChecked: false,
			isExamStylePremade: true,
			isExamStyleToggleOutstanding: true,
			selectedCase: null,
			selectedSections: [],
			displayConfirmationPopup: false,
			confirmationPopupOptions: null,
			popupLoading: false,
			printReceiptHTML: null,
			caseListData: null,
			loadingCaseList: true,
			examGuid: null,
			selectedExamGuid: null,
			isAuthenticationErrorDisplayed: false,
			windowsSystem: this.isUserOnWindows(),
			shouldGradingRefreshCaseList: false,
    }
  }

  handleLogin = (loginData) => {
    this.setState({
      userData: loginData,
      pageDisplayed: 'CaseList'
    }, () => {
			this.fetchCaseList()
    })
  }

	fetchCaseList = async () => {
		let caseListData = await this.getCaseListSections()
		caseListData = this.addExamSectionCategoryIcons(caseListData)
		this.setState({caseListData})
	}

	isUserOnWindows = () => {
		return navigator.userAgent.indexOf('Windows') > -1;
	}

	addExamSectionCategoryIcons = (caseListData) => {
		for (let i = 0; i < caseListData.ExamSections.length; i++) {
			let section = caseListData.ExamSections[i]
			section.CategoryIcon = this.getCategoryIcon(section.sectionCategory)
		}
		return caseListData
	}

  beginExam = (isViewingPreviousExams, selectedSections) => {
		let examId
		let examName

		selectedSections = selectedSections.sort((a, b) => a.sectionId - b.sectionId)

		// The user has selected a premade exam
		if (this.state.isExamStylePremade) {
			let selectedExam = this.state.caseListData.PremadeExams.find(exam => exam.ExamId === this.state.selectedCase)
			examId = this.state.selectedCase
			examName = selectedExam.ExamName
		// The user has created a new custom exam
		} else if (!isViewingPreviousExams) {
			examId = null
			examName = null
		// The user is viewing a previous custom exam
		} else {
			let selectedExam = this.state.caseListData.PreviousCustomExams.find(exam => exam.ExamId === this.state.selectedCase)
			examId = this.state.selectedCase
			examName = selectedExam.ExamName
		}
		this.setState({fetchingStartExam: true})
		fetch(`${this.state.route}/startexam.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				professionId: this.state.caseListData.ProfessionId,
				CustomerId: this.state.userData.CustomerId,
				isCustomExam: !this.state.isExamStylePremade,
				examId,
				sections: selectedSections,
				customExamName: examName
			})
		})
		.then(async (response) => {
			if (response.ok) {
				return response.json()
			} else if (response.status === 401) {
				return this.displayAuthenticationError()
			} else {
				throw new Error(await response.text())
			}
		})
		.then(async (result) => {
			this.setState({
				firstExamSection: result.firstSection,
				selectedExamSections: selectedSections,
				fetchingStartExam: false,
				selectedSections: [],
				pageDisplayed: 'Simulation',
				examGuid: result.examGuid,
			})
		})
		.catch(() => {
			this.setState({fetchingStartExam: false})
		})
  }

	viewCaseGrades = async (examGuid, shouldGradingRefreshCaseList = false) => {
		this.setState({pageDisplayed: 'Grading', selectedExamGuid: examGuid, shouldGradingRefreshCaseList})
	}

	incompleteStartHandler = (currentStateId, caseData) => {
		this.setState({fetchingStartExam: true})
		fetch(`${this.state.route}/incompletestart.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customerId: this.state.userData.CustomerId,
				CurrentStateId: currentStateId
			})
		})
		.then(async (response) => {
			if (response.ok) {
				return response.json()
			} else if (response.status === 401) {
				return this.displayAuthenticationError()
			} else {
				throw new Error(await response.text())
			}
		})
		.then((result) => {
			this.setState({
				firstExamSection: result.section,
				selectedExamSections: caseData.sectionIds,
				fetchingStartExam: false,
				selectedSections: [],
				pageDisplayed: 'Simulation',
				examGuid: result.examGuid,
			})
		})
		.catch((error) => {
			console.log(error)
			this.setState({
				fetchingStartExam: false,
			})
		})
	}

  hideHighYieldHandler = async () => {
		let userData = {...this.state.userData}
		let newValue = !userData.TurnOffHighYield
		userData.TurnOffHighYield = newValue
		this.setState({userData})
		await fetch(`${this.state.route}/turnoffhighyield.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				TurnOffHighYield: newValue
			})
		})
		.then(async (response) => {
			if (response.ok) {
				return response.json()
			} else if (response.status === 401) {
				return this.displayAuthenticationError()
			} else {
				throw new Error(await response.text())
			}
		})
		.catch((error) => {
			userData.TurnOffHighYield = !newValue
			this.setState({userData})
			// logFetchError(error, null, this.state.userData, 'hideHighYieldHandler')
		})
	}

	updateCaseName = (newName) => {
		let selectedCaseIndex

		this.state.caseListData.PreviousCustomExams.forEach((exam, index) => {
			if (exam.ExamId === this.state.selectedCase) {
				selectedCaseIndex = index
			}
		})

		let caseListData = {...this.state.caseListData}

		caseListData.PreviousCustomExams[selectedCaseIndex].ExamName = newName

		this.setState({caseListData})
	}

	getCaseListSections = async () => {
		this.setState({loadingCaseList: true})
		let caseListData = await fetch(`${this.state.route}/listsections.webapi`, {
			method: 'GET',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			}
		})
		.then(async response => {
			if (response.ok) {
				return response.json()
			} else if (response.status === 401) {
				return this.displayAuthenticationError()
			} else {
				throw new Error(await response.text())
			}
		})
		.then(result => {
			return result
		})
		.catch(error => {
			// logFetchError(error, null, this.state.userData, 'getCaseListSections')
		});
		this.setState({loadingCaseList: false})
		return caseListData
	}

	updateSelectedTimeZone = (value) => {
		let initialTimeZone = this.state.userData.TimeZone
		let userData = this.state.userData
		userData.TimeZone = value
		this.setState({userData})
		fetch(`${this.state.route}/updatetimezone.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				timeZone: value
			})
		})
		.then(async (response) => {
			if (response.ok) {
				return response.json()
			} else if (response.status === 401) {
				return this.displayAuthenticationError()
			} else {
				throw new Error(await response.text())
			}
		})
		.catch((error) => {
			let userData = this.state.userData
			userData.TimeZone = initialTimeZone
			this.setState({userData})
			// logFetchError(error, null, this.state.userData, 'updateSelectedTimeZone')
		})
	}

	toggleDisableFeedback = async () => {
		let userData = {...this.state.userData}
		userData.DisableFeedback = !userData.DisableFeedback

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			DisableFeedback: userData.DisableFeedback
		})

		await fetch(`${this.state.route}/toggledisablefeedback.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			this.setState({userData})
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.displayAuthenticationError()
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
		})
		.catch((error) => {
			console.log(error)
			// logFetchError(error, null, this.state.userData, 'toggleDisableFeedback')
		})
	}

  changeTheme = async (theme, saveChange = true) => {
		this.setState({selectedTheme: theme, primaryColor: THEMES[theme].primaryColor})

		document.documentElement.style.setProperty('--new-primary-color', THEMES[theme].primaryColor)
		document.documentElement.style.setProperty('--new-secondary-color', THEMES[theme].secondaryColor)
		document.documentElement.style.setProperty('--new-primary-background-color', THEMES[theme].primaryBackgroundColor)
		document.documentElement.style.setProperty('--new-secondary-background-color', THEMES[theme].secondaryBackgroundColor)
		document.documentElement.style.setProperty('--new-tertiary-background-color', THEMES[theme].tertiaryBackgroundColor)
		document.documentElement.style.setProperty('--new-off-background-color', THEMES[theme].offBackgroundColor)
		document.documentElement.style.setProperty('--new-text-color', THEMES[theme].textColor)
		document.documentElement.style.setProperty('--new-case-text-color', THEMES[theme].caseTextColor)
		document.documentElement.style.setProperty('--new-secondary-text-color', THEMES[theme].secondaryTextColor)
		document.documentElement.style.setProperty('--new-off-text-color', THEMES[theme].offTextColor)
		document.documentElement.style.setProperty('--new-secondary-off-text-color', THEMES[theme].secondaryOffTextColor)
		document.documentElement.style.setProperty('--new-tertiary-off-text-color', THEMES[theme].tertiaryOffTextColor)
		document.documentElement.style.setProperty('--case-selected-color', THEMES[theme].caseSelectedColor)
		document.querySelector("meta[name=theme-color]").setAttribute("content", THEMES[theme].tertiaryBackgroundColor)

		if (saveChange) {
			this.setState({themeChangeIndex: theme})
			const response = await fetch(`${this.state.route}/changetheme.webapi`, {
				method: 'POST',
				headers: {
					'Token': this.state.userData.Token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					Theme: theme,
					CustomerId: this.state.userData.CustomerId
				})
			})
			this.setState({themeChangeIndex: -1})

			if (response.status === 401) {
				return this.displayAuthenticationError()
			}

			if (response.ok) {
				const apiMessage = await response.text()
			} else {
				console.log('error', response)
			}
		}
	}

  disableAnimationsHandler = async () => {
		let userData = {...this.state.userData}
		userData.AnimationsDisabled = !userData.AnimationsDisabled

		let body = JSON.stringify({
			CustomerId: this.state.userData.CustomerId,
			AnimationsDisabled: userData.AnimationsDisabled
		})

		await fetch(`${this.state.route}/toggleanimationsdisable.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: body
		})
		.then((response) => {
			this.setState({userData})
			if (response.ok) {
				return response.text()
			} else if (response.status === 401) {
				return this.displayAuthenticationError()
			} else {
				throw new Error('Error')
			}
		})
		.then((response) => {
		})
		.catch((error) => {
			console.log(error)
			// logFetchError(error, null, this.state.userData, 'disableAnimationsHandler')
		})
	}

  displayConfirmationPopup = (confirmationPopupOptions) => {
		this.setState({displayConfirmationPopup: true, confirmationPopupOptions})
	}

	logout() {
		window.location.reload();
	}

	toggleSortDirection = () => {
		if (this.state.sortDirection === 'Ascending') {
			this.setState({sortDirection: 'Descending'})
		} else {
			this.setState({sortDirection: 'Ascending'})
		}
	}

	// Handle toggling hide completed here to persist state between page changes
	toggleHideCompleted = () => {
		if (this.state.isHideIncompleteChecked && !this.state.isHideCompletedChecked) {
			this.setState({isHideIncompleteChecked: false, isHideCompletedChecked: true})
		} else {
			this.setState({isHideCompletedChecked: !this.state.isHideCompletedChecked})
		}
	}

	// Handle toggling hide incomplete here to persist state between page changes
	toggleHideIncomplete = () => {
		if (this.state.isHideCompletedChecked && !this.state.isHideIncompleteChecked) {
			this.setState({isHideCompletedChecked: false, isHideIncompleteChecked: true})
		} else {
			this.setState({isHideIncompleteChecked: !this.state.isHideIncompleteChecked})
		}
	}

	setIsExamStylePremade = (isExamStylePremade) => {
		this.setState({isExamStylePremade})
	}

	setSelectedCase = (caseId, callback = null) => {
		this.setState({selectedCase: caseId}, () => {
			if (callback !== null) {
				callback()
			}
		})
	}

	handleSectionClick = (sectionData) => {
		let selectedSections = this.state.selectedSections
		// If the section is already selected, remove the section
		if (selectedSections.some((selectedSection) => selectedSection.sectionId === sectionData.sectionId)) {
			selectedSections = selectedSections.filter((section) => section.sectionId !== sectionData.sectionId)
		}
		// Otherwise, add the section
		else {
			selectedSections.push({
				sectionId: sectionData.sectionId,
				categoryIcon: sectionData.CategoryIcon
			})
		}
		this.setState({selectedSections})
	}

	selectRandomSections = () => {
		let selectedSections = []
		let sectionIds = this.state.caseListData.ExamSections.map((section) => section.sectionId)

		// Pick 4 random numbers between 0 and the number of sections
		let randomSectionIds = []
		for (let i = 0; i < 4; i++) {
			let randomIndex = Math.floor(Math.random() * sectionIds.length)
			randomSectionIds.push(sectionIds[randomIndex])

			// Remove the section from the array so it can't be selected again
			sectionIds.splice(randomIndex, 1)
		}

		randomSectionIds.forEach((sectionId) => {
			let section = this.state.caseListData.ExamSections.find((section) => section.sectionId === sectionId)
			selectedSections.push({
				sectionId: section.sectionId,
				categoryIcon: section.CategoryIcon
			})
		})
		this.setState({selectedSections})
	}

	getCategoryIcon = (category) => {
		switch(category) {
			case 'Listening':
				return faEarListen
			case 'Reading':
				return faBookOpen
			case 'Writing':
				return faPencil
			case 'Speaking':
				return faKeynote
			default:
				return null
		}
	}

	displayAuthenticationError = () => {
		this.setState({isAuthenticationErrorDisplayed: true})
	}

	updateCategories = (categories) => {
		this.setState({categories})
	}

	handleSectionReviewLater = (event, section) => {
		event.stopPropagation()
		let caseIndex = this.state.caseListData.ExamSections.findIndex((examSection) => examSection.sectionId === section.sectionId)
		let caseListData = {...this.state.caseListData}
		if (caseListData.ExamSections[caseIndex].ReviewLaterOutstanding)
			return

		caseListData.ExamSections[caseIndex].ReviewLaterOutstanding = true

		this.setState({caseListData}, () => {
			setTimeout(() => {
				caseListData.ExamSections[caseIndex].ReviewLaterOutstanding = false
				caseListData.ExamSections[caseIndex].ReviewLaterFlag = !caseListData.ExamSections[caseIndex].ReviewLaterFlag
				this.setState({caseListData})
			}, 500)
		})
	}

	handleExamReviewLater = (event, exam, examType) => {
		event.stopPropagation()
		let caseIndex
		let caseListData = {...this.state.caseListData}
		if (examType === 'Premade') {
			caseIndex = this.state.caseListData.PremadeExams.findIndex((premadeExam) => premadeExam.ExamId === exam.ExamId)

			// Verify that a call to update review later isn't already outstanding
			if (caseListData.PremadeExams[caseIndex].ReviewLaterOutstanding)
				return

			caseListData.PremadeExams[caseIndex].ReviewLaterOutstanding = true
		} else {
			caseIndex = this.state.caseListData.PreviousCustomExams.findIndex((customExam) => customExam.ExamId === exam.ExamId)

			// Verify that a call to update review later isn't already outstanding
			if (caseListData.PreviousCustomExams[caseIndex].ReviewLaterOutstanding)
				return

			caseListData.PreviousCustomExams[caseIndex].ReviewLaterOutstanding = true
		}

		this.setState({caseListData}, () => {
			setTimeout(() => {
				if (examType === 'Premade') {
					caseListData.PremadeExams[caseIndex].ReviewLaterOutstanding = false
					caseListData.PremadeExams[caseIndex].ReviewLater = !caseListData.PremadeExams[caseIndex].ReviewLater
				} else {
					caseListData.PreviousCustomExams[caseIndex].ReviewLaterOutstanding = false
					caseListData.PreviousCustomExams[caseIndex].ReviewLater = !caseListData.PreviousCustomExams[caseIndex].ReviewLater
				}
				this.setState({caseListData})
			}, 500)
		})
	}

	closeGrading = () => {
		this.setState({pageDisplayed: 'CaseList'})
	}

	updateReviewLater = (examId) => {
		// Check Premade Exams for examId
		let examIndex = this.state.caseListData.PremadeExams.findIndex((premadeExam) => premadeExam.ExamId === examId)
		let isPremadeExam = true
		if (examIndex === -1) {
			// Check Previous Custom Exams for examId
			examIndex = this.state.caseListData.PreviousCustomExams.findIndex((customExam) => customExam.ExamId === examId)
			isPremadeExam = false
		}

		let caseListData = {...this.state.caseListData}
		if (isPremadeExam) {
			caseListData.PremadeExams[examIndex].ReviewLater = !caseListData.PremadeExams[examIndex].ReviewLater
		} else {
			caseListData.PreviousCustomExams[examIndex].ReviewLater = !caseListData.PreviousCustomExams[examIndex].ReviewLater
		}

		this.setState({caseListData})
	}

	updateProfession = (profession) => {
		let userData = {...this.state.userData}
		userData.Profession = profession
		this.setState({userData})
	}

	exitExam = async () => {
		this.setState({pageDisplayed: 'CaseList'})
		this.fetchCaseList()
	}

	updateUserSignature = (signature) => {
		let userData = {...this.state.userData}
		userData.UserSignature = signature
		this.setState({userData})
	}

  render() {

		if (this.state.printReceiptHTML !== null) {
			return (
				<div className='printReceiptContainer'>
					<div dangerouslySetInnerHTML={{__html: this.state.printReceiptHTML}} />
					<div className='printReceiptButtonContainer'>
						<button className='printReceiptButton secondaryButton' onClick={() => this.setState({printReceiptHTML: null})}>Cancel</button>
						<button className='printReceiptButton primaryButton' onClick={this.printReceipt}>Print</button>
					</div>
				</div>
			)
		}

    return (
      <div className="App">
        {this.state.pageDisplayed === 'Login' ?
          <LoginPage
            primaryColor={this.state.primaryColor}
            route={this.state.route}
            handleLogin={this.handleLogin}
          />
        : this.state.pageDisplayed === 'Simulation' ?
          <Simulation
            route={this.state.route}
            userData={this.state.userData}
            firstExamSection={this.state.firstExamSection}
            selectedExamSections={this.state.selectedExamSections}
						changePage={(newPage) => this.setState({pageDisplayed: newPage})}
						initializeAudioData={this.initializeAudioData}
						examGuid={this.state.examGuid}
						displayConfirmationPopup={this.displayConfirmationPopup}
						primaryColor={this.state.primaryColor}
						animationsDisabled={this.state.userData.animationsDisabled}
						viewCaseGrades={this.viewCaseGrades}
						exitExam={this.exitExam}
						displayAuthenticationError={this.displayAuthenticationError}
          />
        : this.state.pageDisplayed === 'CaseList' ?
          <CaseList
						categories={this.state.categories}
						updateCategories={this.updateCategories}
						selectedTheme={THEMES[this.state.selectedTheme]}
						caseListData={this.state.caseListData}
						loadingCaseList={this.state.loadingCaseList}
						changePage={(newPage) => this.setState({pageDisplayed: newPage})}
						userData={this.state.userData}
						sortOrder={this.state.sortOrder}
						updateSortOrder={(newSortOrder, callback) => this.setState({sortOrder: newSortOrder}, callback)}
						sortDirection={this.state.sortDirection}
						toggleSortDirection={this.toggleSortDirection}
						toggleHideCompleted={this.toggleHideCompleted}
						toggleHideIncomplete={this.toggleHideIncomplete}
						isHideIncompleteChecked={this.state.isHideIncompleteChecked}
						isHideCompletedChecked={this.state.isHideCompletedChecked}
						animationsDisabled={this.state.userData.AnimationsDisabled}
						primaryColor={this.state.primaryColor}
						reviewLaterToggle={this.reviewLaterToggle}
						isExamStylePremade={this.state.isExamStylePremade}
						setIsExamStylePremade={this.setIsExamStylePremade}
						isExamStyleToggleOutstanding={this.state.isExamStyleToggleOutstanding}
						setSelectedCase={this.setSelectedCase}
						selectedCase={this.state.selectedCase}
						beginCase={this.beginExam}
						fetchingStartExam={this.state.fetchingStartExam}
						handleSectionClick={this.handleSectionClick}
						selectedSections={this.state.selectedSections}
						route={this.state.route}
						handleSectionReviewLater={this.handleSectionReviewLater}
						handleExamReviewLater={this.handleExamReviewLater}
						displayConfirmationPopup={this.displayConfirmationPopup}
						setPopupLoading={(newValue) => this.setState({popupLoading: newValue})}
						incompleteStartHandler={this.incompleteStartHandler}
						updateCaseName={this.updateCaseName}
						viewCaseGrades={this.viewCaseGrades}
						windowsSystem={this.state.windowsSystem}
						selectRandomSections={this.selectRandomSections}
						displayAuthenticationError={this.displayAuthenticationError}
						handleSetUserSignature={this.handleSetUserSignature}
					/>
        : this.state.pageDisplayed === 'Grading' ?
					<Grading
						grading={this.state.grading}
						displayAuthenticationError={this.displayAuthenticationError}
						userData={this.state.userData}
						caseData={this.state.caseData}
						selectedCase={this.state.selectedCase}
						closeGrading={this.closeGrading}
						reviewLater={this.state.reviewLater}
						route={this.state.route}
						setFetchOutstanding={(newValue) => this.setState({fetchOutstanding: newValue})}
						primaryColor={this.state.primaryColor}
						gradingMode={this.state.gradingMode}
						unfilteredCaseList={this.state.unfilteredCaseList}
						selectedTheme={THEMES[this.state.selectedTheme]}
						displayConfirmationPopup={this.displayConfirmationPopup}
						closeConfirmationPopup={() => this.setState({displayConfirmationPopup: false})}
						updateReviewLater={this.updateReviewLater}
						selectedExamGuid={this.state.selectedExamGuid}
						fetchCaseList={this.fetchCaseList}
						shouldGradingRefreshCaseList={this.state.shouldGradingRefreshCaseList}
					/>
				: this.state.pageDisplayed === 'ReportCard' ?
					<ReportCard
						userData={this.state.userData}
						route={this.state.route}
						returnToCaseList={() => this.setState({pageDisplayed: 'CaseList'})}
						setAuthenticationError={this.displayAuthenticationError}
					/>
				:
          <AccountSettings
            themeStyle={THEMES[this.state.selectedTheme]}
            userData={this.state.userData}
            themes={THEMES}
            route={this.state.route}
            themeChangeIndex={this.state.themeChangeIndex}
            selectedTheme={this.state.selectedTheme}
            changeTheme={this.changeTheme}
            primaryColor={this.state.primaryColor}
            changePage={(newPage) => this.setState({pageDisplayed: newPage})}
            hideHighYieldHandler={this.hideHighYieldHandler}
            disableAnimationsHandler={this.disableAnimationsHandler}
            displayConfirmationPopup={this.displayConfirmationPopup}
						updateSelectedTimeZone={this.updateSelectedTimeZone}
						toggleDisableFeedback={this.toggleDisableFeedback}
						logout={this.logout}
						setPopupLoading={(newValue) => this.setState({popupLoading: newValue})}
						closeConfirmationPopup={() => this.setState({displayConfirmationPopup: false})}
						setPrintReceiptHTML={(newValue) => this.setState({printReceiptHTML: newValue})}
						updateProfession={this.updateProfession}
						displayAuthenticationError={this.displayAuthenticationError}
						updateUserSignature={this.updateUserSignature}
          />
        }
				<AnimatePresence mode="wait">
					{this.state.displayConfirmationPopup &&
						<ConfirmationPopup
							options={this.state.confirmationPopupOptions}
							key={"ConfirmationPopup"}
							closePopup={() => this.setState({displayConfirmationPopup: false, confirmationPopupOptions: null})}
							popupLoading={this.state.popupLoading}
							primaryColor={this.state.primaryColor}
							animationsDisabled={this.state.userData.AnimationsDisabled}
						>
							{this.state.confirmationPopupOptions.children}
						</ConfirmationPopup>
					}
				</AnimatePresence>
				<AnimatePresence mode="wait">
					{this.state.isAuthenticationErrorDisplayed &&
						<UnauthenticatedPopup
							key={"UnauthenticatedPopup"}
							animationsDisabled={this.state.userData.AnimationsDisabled}
						/>
					}
				</AnimatePresence>
			</div>
    );
  }
}

export default App;
