import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import './TestPrepCalculator.css'

export default class TestPrepCalculator extends Component {
    constructor() {
        super()
        this.state = {
            newExamDate: '',
            displayDateError: false,
            dateErrorMessage: ''
        }
    }

    componentDidMount() {
        this.getCurrentExamDate()
    }

    getCurrentExamDate = () => {
        //Get current date and set it to the number of days equal to DaysUntilExam in the future
        let currentDate = new Date()
        currentDate.setDate(currentDate.getDate() + Number(this.props.DaysUntilExam))

        //Format the current date into the appropriate string for a date input
        let year = currentDate.getFullYear()
        let month = currentDate.getMonth() + 1
        let day = currentDate.getDate()

        //Verify that month and day are both two digit values to conform to date input requirements
        if (month < 10) {
            month = `0${month}`
        }
        if (day < 10) {
            day = `0${day}`
        }

        //Format the final date string and save it to the state (YYYY-MM-DD)
        let formattedDateString = `${year}-${month}-${day}`
        this.setState({newExamDate: formattedDateString})
    }

    updateExamDate = () => {
        //Get the current date and selected date
        let selectedDate = new Date(this.state.newExamDate)
        let today = new Date()

        //Verify that the selected date occurs after the current date
        if (selectedDate < today) {
            return this.setState({displayDateError: true, dateErrorMessage: 'Selected date must occur after today'})
        }
        
        //Verify that the selected date is valid
        if (this.state.newExamDate.length <= 0) {
            return this.setState({displayDateError: true, dateErrorMessage: 'Please select a valid date'})
        }

        //newExamDate comes through as (YYYY-MM-DD), we need to reformat to conform to API standards
        let dateArray
        if (this.state.newExamDate.includes('-')) {
            dateArray = this.state.newExamDate.split('-')
        } else {
            dateArray = this.state.newExamDate.split('/')
        }
        let year = dateArray[0]
        let month = dateArray[1]
        let day = dateArray[2]

        //Format selected date to conform to API standards (MM-DD-YYYY)
        let formattedDateString = `${month}-${day}-${year}`

        //Fetch the update and store the result as updatedSuccessfully boolean
        let updatedSuccessfully = this.props.fetchChangeExamDate(formattedDateString)

        if (updatedSuccessfully) {
            this.setState({displayDateError: false})
        }
    }

    setNewExamDate = (e) => {
        this.setState({newExamDate: e.target.value})
    }

    render() {
        return (
            <div className='reportCardModuleContainer testPrepCalculatorContainer'>
                <div className='reportCardModuleHeaderContainer'>
                    <h2 className='reportCardModuleHeaderText'>Test Preparation Calculator</h2>
                </div>
                <div className='reportCardTestPrepModuleInputContainer'>
                    <label className='reportCardTestPrepModuleInputLabel'>Your Exam's Date: </label>
                    <input className='reportCardTestPrepModuleInput' value={this.state.newExamDate} onChange={this.setNewExamDate} type='date'/>
                    {this.props.loadingChangeExamDate ?
                        <div className='reportCardTestPrepModuleLoadingContainer'>
                            <MDSpinner
                                size={20}
                                singleColor='#0B335D'
                                className='reportCardTestPrepModuleSpinner'
                            />
                        </div>
                    :
                        <button className='reportCardTestPrepModuleButton' onClick={this.updateExamDate}>Update</button>
                    }
                    <p className='reportCardTestPrepModuleErrorText' style={{display: this.state.displayDateError ? 'initial' : 'none'}}>{this.state.dateErrorMessage}</p>
                </div>
                <div className='reportCardTitleValueContainer testPrepCalculatorTitleValue'>
                    <p className='reportCardTitleValueText'>Days Until Exam</p>
                    <p className='reportCardTitleValueText'>{this.props.DaysUntilExam} Days</p>
                </div>
                <div className='reportCardTitleValueContainer testPrepCalculatorTitleValue'>
                    <p className='reportCardTitleValueText'>Recommended Minimum Daily Sections</p>
                    <p className='reportCardTitleValueText'>{this.props.RecMinDailyCases} Sections</p>
                </div>
                <div className='reportCardTitleValueContainer testPrepCalculatorTitleValue'>
                    <p className='reportCardTitleValueText'>Recommended Minimum Daily Sections Including Repeats</p>
                    <p className='reportCardTitleValueText'>{this.props.RecMinDailyCasesRepeats} Sections</p>
                </div>
            </div>
        )
    }
}
