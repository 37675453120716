import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/pro-light-svg-icons"

export const PdfComponent = ({openPDF, content}) => {
  return (
    <div className='openPDFButtonContainer'>
      <button
        className='openPDFButton'
        style={{position: 'relative', top: '-5px'}}
        onClick={() => openPDF(content.pdfBytes)}
      >
        <div className='openPDFIconWrapper'>
          <FontAwesomeIcon
            icon={faFilePdf}
            className='openPDFButtonIcon'
          />
        </div>
        <span>{content.pdfName}</span>
      </button>
    </div>
  )
}