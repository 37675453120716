import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from 'react'
import './AudioPlayer.css'
import MDSpinner from 'react-md-spinner'

export default class AudioPlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      audioPlayerActive: false,
      audioVolume: 1,
      previousAudioVolume: 1,
      isAudioEnded: false,
      currentTime: 0,
      currentSliderPosition: 0,
      audioDuration: 0,
      durationFetchOutstanding: true,
    }
    this.audioFile = new Audio(`${props.route}/getmp3.webapi?FileName=${props.fileName}&Token=${this.props.userData.Token}`)
  }

  audioTimer = null;

  componentDidMount() {
    this.audioFile.addEventListener('ended', () => {
      this.setState({ audioPlayerActive: false, isAudioEnded: true })
    })
    this.audioFile.addEventListener('play', () => {
      this.setState({ audioPlayerActive: true })
    })
    this.audioFile.addEventListener('pause', (e) => {
      this.setState({ audioPlayerActive: false })
    })
    this.fetchAudioDuration()
    this.audioFile.currentTime = 0
  }

  componentWillUnmount() {
    this.pauseAudio()
    this.audioFile.removeEventListener('ended', () => {
      this.setState({ audioPlayerActive: false, isAudioEnded: true })
    })
    this.audioFile.removeEventListener('play', () => {
      this.setState({ audioPlayerActive: true })
    })
    this.audioFile.removeEventListener('pause', (e) => {
      this.setState({ audioPlayerActive: false })
    })
  }

  playAudio = async () => {
    try {
      await this.audioFile.play()
    } catch(error) {
      console.log('Error playing audio: ', error)
    }
    this.setState({ currentTime: this.audioFile.currentTime, currentSliderPosition: this.audioFile.currentTime / this.state.audioDuration * 100 })
    this.audioTimer = setInterval(() => {
      this.setState({ currentTime: Math.round(this.audioFile.currentTime), currentSliderPosition: this.audioFile.currentTime / this.state.audioDuration * 100 })
    }, 1000)
    this.setState({ audioPlayerActive: true })
  }

  pauseAudio = () => {
    this.audioFile.pause()
    clearInterval(this.audioTimer)
    this.setState({ audioPlayerActive: false, currentTime: this.audioFile.currentTime })
  }

  toggleAudio = () => {
    if (this.state.isAudioEnded) {
      this.audioFile.currentTime = 0
      return this.setState({ isAudioEnded: false }, () => {
        this.playAudio()
      })
    }

    if (this.state.audioPlayerActive) {
      this.pauseAudio()
    } else {
      this.playAudio()
    }
  }

  muteAudioHandler = () => {
    if (this.state.audioVolume > 0) {
      this.setState({
        previousAudioVolume: this.state.audioVolume,
        audioVolume: 0
      })
    } else {
      this.setState({ audioVolume: this.state.previousAudioVolume })
    }
  }

  adjustAudioVolume = (e) => {
    this.setState({ audioVolume: e.target.value })
    this.audioFile.volume = e.target.value
  }

  formatTime = (time) => {
    return `${Math.floor(time / 60)}:${Math.floor(time % 60).toString().padStart(2, '0')}`
  }

  handleSliderChange = (e) => {
    let newTimeInSeconds = this.state.audioDuration * (e.target.value / 100)
    this.audioFile.currentTime = newTimeInSeconds

    clearInterval(this.audioTimer)
    this.audioTimer = setInterval(() => {
      this.setState({ currentTime: Math.round(this.audioFile.currentTime), currentSliderPosition: this.audioFile.currentTime / this.state.audioDuration * 100 })
    })

    this.setState({ currentTime: newTimeInSeconds, currentSliderPosition: e.target.value })
  }

  fetchAudioDuration = () => {
    fetch(`${this.props.route}/getaudioduration.webapi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Token': this.props.userData.Token,
      },
      body: JSON.stringify({
        mp3Name: this.props.fileName,
      })
    })
    .then(res => {
      if (res.status === 200) {
        return res.json()
      } else {
        throw new Error('Error getting audio duration')
      }
    })
    .then(data => {
      this.setState({ audioDuration: data.mp3Duration, durationFetchOutstanding: false })
    })
    .catch(err => {
      console.log(err)
      this.setState({ durationFetchOutstanding: false })
    })
  }

  render() {
    return (
      <>
        <div className='gradingFeedbackAudioContainer'>
          <h3 className='gradingFeedbackAudioHeader'>{this.props.fileName}</h3>
          {!this.state.durationFetchOutstanding && this.audioFile ?
            <div className='gradingFeedbackAudioModuleWrapper'>
              <button className='gradingFeedbackAudioModuleButton'>
                <FontAwesomeIcon icon={this.state.isAudioEnded ? faRotateRight : this.state.audioPlayerActive ? faPause : faPlay} className='gradingFeedbackAudioModuleIcon' onClick={() => this.toggleAudio()}/>
              </button>
              <p className='gradingFeedbackAudioModuleTime'>{this.formatTime(this.state.currentTime)}</p>
              <input type="range" className='gradingFeedbackAudioSlider' value={this.state.currentSliderPosition} onChange={this.handleSliderChange} />
              <p className='gradingFeedbackAudioModuleTime'>{this.formatTime(this.state.audioDuration)}</p>
            </div>
          :
            <div className='gradingFeedbackAudioModuleLoadingWrapper'>
              <MDSpinner
                size={40}
                singleColor={this.props.primaryColor}
              />
            </div>
          }
        </div>
      </>
    )
  }
}
