import React from "react";
import './FormattedEmail.css'

export default function FormattedEmail(props) {
    return (
        <div className='emailWrapper'>
            <div className="email">
                <div className='emailHeader'>
                    <p className='emailHeaderText'>To:</p>
                    <div className='emailHeaderContent'>
                        <p className='emailHeaderContentText'>{props.to}</p>
                    </div>
                    <p className='emailHeaderText'>Subject:</p>
                    <div className='emailHeaderContent'>
                        <p className='emailHeaderContentText'>{props.subject}</p>
                    </div>
                </div>
                <div className="emailBody">
                    <p className='emailBodyContent'>{props.body}</p>
                </div>
            </div>
        </div>
    )
}