import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { Viewer, SpecialZoomLevel, createStore, Position, Tooltip, Button } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { selectionModePlugin, SelectionMode } from '@react-pdf-viewer/selection-mode';
import '@react-pdf-viewer/selection-mode/lib/styles/index.css';
import { highlightPlugin, MessageIcon } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons'
import { faHand, faHighlighter } from '@fortawesome/pro-regular-svg-icons'
import { faUpRightAndDownLeftFromCenter, faDownLeftAndUpRightToCenter, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Document, Page, getSelection } from 'react-pdf/dist/esm/entry.webpack5'
import Draggable from 'react-draggable'
import './NewPdfViewer.css'
import * as pdfjs from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

export default function NewPdfViewer(props) {
  const [pageScale, setPageScale] = useState(1)
  const [zoomValue, setZoomValue] = useState('100')
  const [selectionMode, setSelectionMode] = useState('text')
  const [pdfViewerWidth, setPdfViewerWidth] = useState(600)
  // const [highlightedText, setHighlightedText] = useState([])
  const [highlights, setHighlights] = useState([])

  useEffect(() => {
    if (window.innerWidth < 1830)
      props.handlePdfDock(false)
    else
      props.handlePdfDock(true)

    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  }, [])

  const CustomZoomPlugin = () => {
    const store = useMemo(() => createStore(), []);
    return {
      install: (pluginFunctions) => {
        store.update('zoom', pluginFunctions.zoom);
      },
      zoomTo: (scale) => {
        const zoom = store.get('zoom');
        if (zoom) {
          // Zoom to that scale
          zoom(scale);
        }
      },
    };
  };

  const customZoomPluginInstance = CustomZoomPlugin();
  const { zoomTo } = customZoomPluginInstance;

  const selectionModePluginInstance = selectionModePlugin();
  const { SwitchSelectionMode } = selectionModePluginInstance;

  const renderHighlightTarget = (props) => {
    const highlightText = () => {
      let noteId = 0;

      if (highlights.length > 0) {
        let usedIds = []
        highlights.forEach((highlight) => {
          usedIds.push(highlight.id)
        })
        if (usedIds.includes(noteId)) {
          usedIds.sort((a, b) => a - b)
          noteId = usedIds[usedIds.length - 1] + 1 
        }
      }

      const note = {
        id: noteId,
        content: '',
        highlightAreas: props.highlightAreas,
        quote: props.selectedText,
      };
      setHighlights(highlights.concat([note]));

      props.cancel()
    }

    return (
      <div
        style={{
          background: '#eee',
          display: 'flex',
          position: 'absolute',
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top - props.selectionRegion.height}%`,
          transform: 'translate(0, -20px)',
          zIndex: 3
        }}
      >
        <button onClick={highlightText} className='textHighlightButton'>
          <FontAwesomeIcon
            icon={faHighlighter}
            className='textHighlightIcon'
          />
        </button>
      </div>
    )
  }

  const renderHighlights = (props) => (
    <div>
      {highlights.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            // Filter all highlights on the current page
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: 'yellow',
                    opacity: 0.4,
                    zIndex: 2,
                    cursor: 'pointer'
                  },
                  props.getCssProperties(area, props.rotation)
                )}
                onClick={() => {
                  if (selectionMode === 'text') {
                    let newHighlights = []
                    
                    highlights.forEach((highlight) => {
                      if (note.id !== highlight.id) {
                        newHighlights.push(highlight);
                      }
                    })
                    setHighlights(newHighlights)
                  }
                }}
              />
            ))}
        </React.Fragment>
      ))}
  </div>
);

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlights
  });

  let screenWidth, screenHeight, pdfViewerHeight, pdfViewer
  const pdfCanvasRef = React.createRef();
  const pdfViewerContentRef = React.createRef();

  useEffect(() => {
    pdfViewer = document.getElementById("pdfViewer")
    screenWidth = window.innerWidth
    screenHeight = window.innerHeight
    pdfViewerHeight = pdfViewer.clientHeight
    if (!props.pdfDocked) {
      pdfViewer.style.setProperty('--leftOffset', `${(screenWidth - pdfViewerWidth) / 2}px`)
      pdfViewer.style.setProperty('--topOffset', `${(screenHeight - pdfViewerHeight) / 2}px`)
    } else {
      pdfViewer.style.setProperty('--leftOffset', `0px`)
      pdfViewer.style.setProperty('--topOffset', `0px`)
    }
  }, [props.pdfDocked])

  const onDocumentLoadSuccess = () => {
    if (!props.pdfDocked) {
      pdfViewer = document.getElementById("pdfViewer")
      screenWidth = window.innerWidth
      screenHeight = window.innerHeight
      pdfViewerHeight = pdfViewer.clientHeight
      pdfViewer.style.setProperty('--leftOffset', `${(screenWidth - pdfViewerWidth) / 2}px`)
      pdfViewer.style.setProperty('--topOffset', `${(screenHeight - pdfViewerHeight) / 2}px`)
    }
  }

  const exitPDFViewerHandler = () => {
    props.handlePdfDock(false)
    props.onClose()
  }

  const onSelectedZoom = (event) => {
    switch(event.target.value) {
      case 'automatic':
        setPageScale(null)
        setZoomValue('automatic')
        zoomTo(SpecialZoomLevel.PageWidth)
        break
      case 'actual':
        setPageScale(null)
        setZoomValue('actual')
        zoomTo(SpecialZoomLevel.ActualSize)
        break
      case 'pageFit':
        setPageScale(null)
        setZoomValue('pageFit')
        zoomTo(SpecialZoomLevel.PageFit)
        break
      case 'pageWidth':
        setPageScale(null)
        setZoomValue('pageWidth')
        zoomTo(SpecialZoomLevel.PageWidth)
        break
      case '50':
        setPageScale(0.5)
        setZoomValue('50')
        zoomTo(0.5)
        break
      case '75':
        setPageScale(0.75)
        setZoomValue('75')
        zoomTo(0.75)
        break
      case '100':
        setPageScale(1)
        setZoomValue('100')
        zoomTo(1)
        break
      case '125':
        setPageScale(1.25)
        setZoomValue('125')
        zoomTo(1.25)
        break
      case '150':
        setPageScale(1.5)
        setZoomValue('150')
        zoomTo(1.5)
        break
      case '200':
        setPageScale(2)
        setZoomValue('200')
        zoomTo(2)
        break
      case '300':
        setPageScale(3)
        setZoomValue('300')
        zoomTo(3)
        break
      case '400':
        setPageScale(4)
        setZoomValue('400')
        zoomTo(4)
        break
      default:
        break
    }
  }

  const expandPDFHandler = () => {
    props.handlePdfDock(!props.pdfDocked)
  }

  const handleZoomButton = (zoomIn) => {

    if (isNaN(zoomValue)) {
      setPageScale(1)
      setZoomValue('100')
      zoomTo(1)
      return
    }

    if (zoomIn && pageScale < 4) {
      setPageScale(pageScale + 0.25)
      setZoomValue(`${(pageScale + 0.25) * 100}`)
      zoomTo(pageScale + 0.25)
    } else if (!zoomIn && pageScale > 0.5) {
      setPageScale(pageScale - 0.25)
      setZoomValue(`${(pageScale - 0.25) * 100}`)
      zoomTo(pageScale - 0.25)
    }
  }

  const handleSelectionModeButtonClick = (props) => {
    props.onClick()
    if (selectionMode === 'text')
      setSelectionMode('hand')
    else
      setSelectionMode('text')
  }

  return (
    <Draggable
      handle="#handle"
      bounds={props.page === 'grading' ? '.newGradingPageWrapper' : '.simulationWrapper'}
      id="pdfContainer"
      disabled={props.pdfDocked ? true : false}
      position={props.pdfDocked ? {x: 0, y: 0} : null}
    >
      <div
        className="pdfViewerModal"
        id="pdfViewer"
        style={{
          position: props.pdfDocked ? 'relative' : 'fixed',
          resize: props.pdfDocked ? 'none' : 'both',
          height: props.pdfDocked ? '100%' : '80%',
          width: props.pdfDocked ? '100%' : 600,
          borderRadius: props.pdfDocked ? "0px" : "4px",
          zIndex: 5
        }}
      >
        <div className="pdfViewerHeader">
          <p id="handle">PDF Viewer</p>
          {window.innerWidth > 1830 && props.page !== 'grading' ?
            <button className="expandButton exitButton" onClick={expandPDFHandler}>
              <FontAwesomeIcon
                icon={props.pdfDocked ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}
                className='exitButtonIcon'
              />
            </button>
          :
            <span />
          }
          <button className="exitButton" onClick={exitPDFViewerHandler}>
            <FontAwesomeIcon
              icon={faXmark}
              className='exitButtonIcon'
            />
          </button>
        </div>
        <div className="pdfViewerControls">
          <SwitchSelectionMode mode={selectionMode === 'text' ? SelectionMode.Hand : SelectionMode.Text}>
            {(props) => (
              <button className={`controlButtons ${selectionMode === 'hand' ? 'controlButtonSelected' : ''}`} onClick={() => handleSelectionModeButtonClick(props)}>
                <FontAwesomeIcon
                  icon={faHand}
                  className='controlButtonIcons'
                />
              </button>
            )}
          </SwitchSelectionMode>
          
          <button className="controlButtons" onClick={() => handleZoomButton(true)}>
            <FontAwesomeIcon
              icon={faPlus}
              className='controlButtonIcons'
            />
          </button>
          <button className="controlButtons" onClick={() => handleZoomButton(false)}>
            <FontAwesomeIcon
              icon={faMinus}
              className='controlButtonIcons'
            />
          </button>
          <select name="zoomOptions" value={zoomValue} className="pdfViewerZoomSelect" onChange={onSelectedZoom}>
            <option value="automatic">Automatic Zoom</option>
            <option value="actual">Actual Size</option>
            <option value="pageFit">Page Fit</option>
            <option value="pageWidth">Page Width</option>
            <option value="50">50%</option>
            <option value="75">75%</option>
            <option value="100">100%</option>
            <option value="125">125%</option>
            <option value="150">150%</option>
            <option value="175" hidden>175%</option>
            <option value="200">200%</option>
            <option value="225" hidden>225%</option>
            <option value="250" hidden>250%</option>
            <option value="275" hidden>275%</option>
            <option value="300">300%</option>
            <option value="325" hidden>325%</option>
            <option value="350" hidden>350%</option>
            <option value="375" hidden>375%</option>
            <option value="400">400%</option>
          </select>
        </div>
        <div className="pdfViewerContent" ref={pdfViewerContentRef}>
          <Viewer
            fileUrl={props.pdfFile}
            onDocumentLoad={onDocumentLoadSuccess}
            // defaultScale={pageScale}
            plugins={[customZoomPluginInstance, selectionModePluginInstance, highlightPluginInstance]}
          />
        </div>
      </div>
    </Draggable>
  )
}
