

import { AnimatePresence, motion } from 'framer-motion'
import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'
import './CaseListIntro.css'

export default class CaseListIntro extends Component {
  constructor() {
    super()
    this.state = {
      pageDisplayed: 'profession',
      // pageDisplayed: 'signature',
      professionSelected: 'Select An Option',
      selectProfessionOutstanding: false,
      professions: [
        'Dentistry',
        'Dietetics',
        'Medicine',
        'Nursing',
        'Occupational Therapy',
        'Optometry',
        'Pharmacy',
        'Physiotherapy',
        'Podiatry',
        'Radiography',
        'Speech Pathology',
        'Veterinary Science'
      ],
      userSignature: '',
      signatureOutstanding: false
    }
  }

  handleProfessionSelection = () => {
    this.setState({selectProfessionOutstanding: true})
    let professionId = this.state.professions.indexOf(this.state.professionSelected) + 1
    fetch(`${this.props.route}/updateprofession.webapi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': this.props.userData.Token
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        professionId
      })
    })
    .then((response) => {
      if (response.status === 200) {
        return response.text()
      } else {
        throw new Error()
      }
    })
    .then((responseText) => {
      this.setState({pageDisplayed: 'selectionStyle', selectProfessionOutstanding: false})
    })
    .catch((error) => {
      this.setState({selectProfessionOutstanding: false})
      window.alert('There was an error setting your profession. Please refresh the page and try again.')
    })
  }

  handleSelectExamStyle = (isExamStylePremade) => {
    this.props.setIsExamStylePremade(isExamStylePremade)
    this.setState({pageDisplayed: 'signature'})
  }

  handleSetUserSignature = (signature) => {
    this.setState({signatureOutstanding: true})
    // Handle user signature submission to server
    fetch(`${this.props.route}/sendusersignature.webapi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': this.props.userData.Token
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        CustomerSignature: signature
      })
    })
    .then((response) => {
      if (response.status === 200) {
        return response.text()
      } else {
        throw new Error()
      }
    })
    .then(() => {
      this.setState({signatureOutstanding: false}, () => {
        this.props.closeIntro()
      })
    })
    .catch((error) => {
      this.setState({selectProfessionOutstanding: false})
      window.alert('Unable to submit your signature. Please refresh the page and try again.')
    })
    this.props.closeIntro()
  }

  render() {
    return (
      <motion.div className='caseListIntroPageWrapper'
        key="caseListIntroPageWrapper"
        exit={this.props.animationsDisabled ? false : {opacity: 0, scale: 0.9}}
      >
        <AnimatePresence mode="wait">
          {this.state.pageDisplayed === 'profession' ?
            <motion.div className='caseListIntroContentWrapper'
              initial={this.props.animationsDisabled ? false : {opacity: 1, scale: 1, left: '0%'}}
              animate={this.props.animationsDisabled ? false : {opacity: 1, scale: 1, left: '0%'}}
              exit={this.props.animationsDisabled ? false : {opacity: 0, scale: 1, left: '3%'}}
              transition={{duration: 0.25}}
              key="caseListIntroProfessionContainer"
            >
              <h1 className='caseListIntroTitle'>Let's Get Started.</h1>
              <p className='caseListIntroSubtitle'>What is your profession?</p>
              <select className='caseListIntroSelect' defaultValue={'Select An Option'} onChange={(e) => this.setState({professionSelected: e.target.value})}>
                <option value='Select An Option' hidden disabled>Select An Option</option>
                {this.state.professions.map((profession, index) => (
                  <option key={index} value={profession}>{profession}</option>
                ))}
              </select>
              { this.state.selectProfessionOutstanding ?
                <MDSpinner
                  size={40}
                  singleColor='#F79D23'
                />
              :
                <button className={`caseListIntroButton primaryButton ${this.state.professionSelected === 'Select An Option' ? 'buttonDisabled' : ''}`} onClick={this.handleProfessionSelection}>Continue</button>
              }
              <p className='caseListIntroNote'>Note: You can change your selected profession at any time</p>
            </motion.div>
          : this.state.pageDisplayed === 'selectionStyle' ?
            <motion.div className='caseListIntroContentWrapper'
              initial={this.props.animationsDisabled ? false : {opacity: 0, scale: 1, left: '-3%'}}
              animate={this.props.animationsDisabled ? false : {opacity: 1, scale: 1, left: '0%'}}
              exit={this.props.animationsDisabled ? false : {opacity: 0, scale: 1, left: '3%'}}
              transition={{duration: 0.25}}
              key="caseListIntroSelectionStyleContainer"
            >
              <h1 className='caseListIntroTitle'>Do you want to</h1>
              <div className='caseListIntroStylesWrapper'>
                <div className='caseListIntroStyleOptionContainer'>
                  <h2 className='caseListIntroStyleOptionTitle'>Select a Premade Exam</h2>
                  <p className='caseListIntroStyleOptionText'>Premade Exams are collections of sections that we feel will give you a well rounded preparation of the OET Exam.</p>
                  <button className='caseListIntroStyleOptionButton primaryButton' onClick={() => this.handleSelectExamStyle(true)}>Select Premade Exam</button>
                </div>
                <p className='caseListIntroStyleOrText'>Or</p>
                <div className='caseListIntroStyleOptionContainer'>
                  <h2 className='caseListIntroStyleOptionTitle'>Create a Custom Exam</h2>
                  <p className='caseListIntroStyleOptionText'>If you'd like to practice certain sections or categories more directly, you can create your own exam.</p>
                  <button className='caseListIntroStyleOptionButton primaryButton' onClick={() => this.handleSelectExamStyle(false)}>Create Custom Exam</button>
                </div>
              </div>
              <p className='caseListIntroNote'>Note: You can change your selection style at any time</p>
            </motion.div>
          :
            <motion.div className='caseListIntroContentWrapper'
              initial={this.props.animationsDisabled ? false : {opacity: 0, scale: 1, left: '-3%'}}
              animate={this.props.animationsDisabled ? false : {opacity: 1, scale: 1, left: '0%'}}
              exit={this.props.animationsDisabled ? false : {opacity: 0, scale: 0.9, left: '0%'}}
              transition={{duration: 0.25}}
              key="caseListIntroSignatureContainer"
              style={{maxWidth: 370, fontSize: 14}}
            >
              <h1 className='caseListIntroTitle'>How will you sign letters?</h1>
              <p className='caseListIntroSubtitle'>During the exam, you are required to write a letter and sign it with your name with or without a title. Please enter how you will be signing your letter (i.e. Dr. George Smith or Dr. George Smith, MD).</p>
              <input className='caseListIntroSignatureInput' placeholder='Ex: Dr. George Smith' onChange={(e) => this.setState({userSignature: e.target.value})} value={this.state.userSignature} />
              { this.state.signatureOutstanding ?
                <MDSpinner
                  size={40}
                  singleColor='#F79D23'
                />
              :
                <button type="button" className={`caseListIntroButton primaryButton ${this.state.userSignature === '' ? 'buttonDisabled' : ''}`} onClick={() => this.handleSetUserSignature(this.state.userSignature)}>Submit</button>
              }
            </motion.div>
          }
        </AnimatePresence>
      </motion.div>
    )
  }
}
