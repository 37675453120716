import React from 'react'
import './Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockNine } from '@fortawesome/pro-regular-svg-icons'

export default function Header(props) {
    return (
        <div className='simulationHeaderContainer'>
          <div className='simulationHeaderWrapper'>
            <p className='simulationHeaderText'><strong>Section ({props.currentSection + 1} of {props.totalSections}): </strong>{props.sectionTitle}</p>
            <div className='simulationHeaderTimerWrapper'>
              <FontAwesomeIcon
                icon={faClockNine}
                className='clockIcon'
              />
              <p className='simulationHeaderText'>Introduction Time Remaining</p>
              <p className='simulationHeaderText'>{new Date(props.timeLeft * 1000).toISOString().slice(11, 19)}</p>
            </div>
            <button className='simulationHeaderButton' onClick={props.exitExam}>Exit Exam</button>
          </div>
          <div className='simulationSubheaderWrapper'>
            <p className='simulationHeaderText'><strong>Test: </strong>OET Practice Test</p>
            <p className='simulationHeaderText'><strong>Candidate: </strong>USER Demo</p>
          </div>
        </div>
    )
}