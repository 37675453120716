import { useRef, useState } from 'react';
import './WritingComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisV, faFileSignature, faHandWave, faHeading, faHospitalUser, faMemo, faParagraph, faX } from '@fortawesome/pro-light-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';

const FormattingGradeValue = ({gradeValue, textValue}) => {
  return (
    <div className='gradingFeedbackFormattingContentCardItem'>
      <FontAwesomeIcon icon={gradeValue ? faCheck : faX} className={gradeValue ? 'gradingFeedbackCorrectIcon' : 'gradingFeedbackIncorrectIcon'} />
      <p>{textValue}</p>
    </div>
  );
}

const ContentItem = ({isCorrect, itemText}) => {
  return (
    <div className={`gradingFeedbackContentItemContainer ${isCorrect ? 'gradingFeedbackContentItemCorrect' : 'gradingFeedbackContentItemIncorrect'}`}>
      <div>
        <FontAwesomeIcon icon={isCorrect ? faCheck : faX} />
      </div>
      <p>{itemText}</p>
    </div>
  )
}

const GrammarItem = ({title, feedback, userAnswer, correctAnswer, openGrammarOptions, index, convertedToCorrect}) => {
  return (
    <div className={`gradingFeedbackGrammarContentItem ${convertedToCorrect ? 'gradingFeedbackGrammarContentItemCorrect' : 'gradingFeedbackGrammarContentItemIncorrect'}`}>
      {!convertedToCorrect && 
        <button className='gradingFeedbackGrammarMoreOptionsButton' onClick={(e) => openGrammarOptions(e, index)}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </button>
      }
      <div className='gradingFeedbackGrammarContentIconWrapper'>
        <FontAwesomeIcon icon={convertedToCorrect ? faCheck : faX} />
      </div>
      <div className='gradingFeedbackGrammarContentTextWrapper'>
        <h4>{title}{convertedToCorrect ? ' (Marked as correct)' : ''}</h4>
        <p className='gradingFeedbackGrammarContentSubtitle'>{feedback}</p>
        <div>
          <p>{userAnswer}</p>
        </div>
        <p className='gradingFeedbackGrammarContentCorrectValueText'>Correct Value: {correctAnswer}</p>
      </div>
    </div>
  )
}

const WritingComponent = ({
  userGradeValues,
  section,
  animationsDisabled,
  displayConfirmationPopup,
  closeConfirmationPopup,
  route,
  userData,
  displayAuthenticationError,
  gradingData,
  updateWritingGradeData
}) => {
  const [isUserResponseComponentExpanded, setIsUserResponseComponentExpanded] = useState(false);
  const [isContentComponentExpanded, setIsContentComponentExpanded] = useState(false);
  const [isGradingComponentExpanded, setIsGradingComponentExpanded] = useState(false);
  const [currentlyOpenGrammarOptions, setCurrentlyOpenGrammarOptions] = useState(null);

  const openGrammarOptions = (e, index) => {
    e.stopPropagation();
    if (currentlyOpenGrammarOptions && currentlyOpenGrammarOptions.index === index) {
      setCurrentlyOpenGrammarOptions(null);
    } else {
      setCurrentlyOpenGrammarOptions({
        yOffset: e.clientY + document.documentElement.scrollTop,
        xOffset: e.clientX - 200,
        index
      })
    }
  }

  const markGrammarAsCorrectHandler = (e) => {
    let confirmationPopupOptions = {
      title: `Mark As Correct?`,
      description: `If you think this grammar rule was marked incorrectly, you can mark it as correct. This will change your grade, but exclude your exam from global averages. You can mark as many grammar rules as you'd like as correct.`,
      confirmFunction: async () => {
        // currentlyOpenGrammarOptions is still the old value here
        await handleChangeGrammarGrade();
        closeConfirmationPopup()
      },
      children: null,
      closePopup: () => {
        closeConfirmationPopup()
      },
      continueOnly: false
    }
    displayConfirmationPopup(confirmationPopupOptions)
  }

  const handleChangeGrammarGrade = async () => {
    const body = {
      ExamGuid: gradingData.examGuid,
      GrammarGuid: userGradeValues.Grammar[currentlyOpenGrammarOptions.index].GrammarGuid,
      SectionId: section.sectionId
    }
    await fetch(`${route}/changegrammar.webapi`, {
      method: 'POST',
      headers: {
        'Token': userData.Token,
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      //Attempt sending logs
      // fetchSubmitLogs(this.props.userProfile, this.props.userData)
      if (response.status === 401) {
        displayAuthenticationError()
        throw new Error('Authentication Error')
      } else {
        return response.json()
      }
    })
    .then(data => {
      updateWritingGradeData(data)
    })
  }

  return (
    <div onClick={() => setCurrentlyOpenGrammarOptions(null)}>
      <div className='gradingFeedbackWritingComponentContainer'>
        <h2 className='gradingFeedbackWritingHeader'>Grade Overview</h2>
        <table className='gradingFeedbackWritingTableContainer'>
          <thead>
            <tr>
              <th className='gradingFeedbackWritingTablePrimaryColumn'>Section</th>
              <th>Your Score</th>
              <th>Total Grade %</th>
              <th>Your Total Grade %</th>
            </tr>
          </thead>
          <tbody className='gradingFeedbackWritingTableBodyContainer'>
            {section.gradeOverview.slice(0, section.gradeOverview.length - 1).map((gradeItem, index) => (
              <tr key={`Writing Table Row - ${gradeItem.title}`}>
                <td className='gradingFeedbackWritingTablePrimaryColumn'>{gradeItem.title}</td>
                <td>{gradeItem.yourScore}</td>
                <td>{gradeItem.totalGradePercent}</td>
                <td>{gradeItem.yourTotalGrade}</td>
              </tr>
            ))}
          </tbody>
          <tfoot className='gradingFeedbackWritingTableFooterContainer'>
            <tr>
              <td className='gradingFeedbackWritingTablePrimaryColumn'>{section.gradeOverview[section.gradeOverview.length - 1].title}</td>
              <td>{section.gradeOverview[section.gradeOverview.length - 1].yourScore}</td>
              <td>{section.gradeOverview[section.gradeOverview.length - 1].totalGradePercent}</td>
              <td>{section.gradeOverview[section.gradeOverview.length - 1].yourTotalGrade}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className={`gradingFeedbackInfoComponentContainer ${isUserResponseComponentExpanded ? 'gradingFeedbackUserWritingResponseExpanded' : 'gradingFeedbackUserWritingResponseCollapsed'}`}>
        <h2 className='gradingFeedbackWritingHeader' style={{paddingLeft: 0}}>Your Response</h2>
        <p style={{whiteSpace: 'pre-wrap'}}>
          {userGradeValues.UserInput}
        </p>
        <button className='gradingFeedbackWritingExpandButton' onClick={() => setIsUserResponseComponentExpanded(!isUserResponseComponentExpanded)}>
          {!isUserResponseComponentExpanded ? 'Show More' : 'Show Less'}
        </button>
      </div>
      <div className='gradingFeedbackWritingSectionContainer'>
        <h3 className='gradingFeedbackWritingSectionHeader'>Formatting</h3>
        <p className='gradingFeedbackWritingSectionSubheader'>30% of Final Grade</p>
        <div className='gradingFeedbackFormattingContentWrapper'>
          <div className='gradingFeedbackFormattingContentCard'>
            <div className='gradingFeedbackFormattingContentCardHeader'>
              <FontAwesomeIcon icon={faHeading} className='gradingFeedbackFormattingContentCardHeaderIcon' />
              <h4 className='gradingFeedbackFormattingContentHeader'>Header</h4>
              <p className='gradingFeedbackFormattingContentSubheader'>10% of Final Grade</p>
            </div>
            <FormattingGradeValue
              gradeValue={userGradeValues.HeaderGrade.CorrectTitle}
              textValue='Correct Title'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.HeaderGrade.CorrectFirstName}
              textValue='Correct First Name'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.HeaderGrade.CorrectLastName}
              textValue='Correct Last Name'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.HeaderGrade.CorrectLocation}
              textValue='Correct Location'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.HeaderGrade.CorrectAddress}
              textValue='Correct Address'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.HeaderGrade.CorrectCity}
              textValue='Correct City'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.HeaderGrade.EachOnOwnLine}
              textValue='Each On Own Line'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.HeaderGrade.EndingLineBreak}
              textValue='Ending Line Break'
            />
          </div>
          <div className='gradingFeedbackFormattingContentCard'>
            <div className='gradingFeedbackFormattingContentCardHeader'>
              <FontAwesomeIcon icon={faHandWave} className='gradingFeedbackFormattingContentCardHeaderIcon' />
              <h4 className='gradingFeedbackFormattingContentHeader'>Salutation</h4>
              <p className='gradingFeedbackFormattingContentSubheader'>5% of Final Grade</p>
            </div>
            <FormattingGradeValue
              gradeValue={userGradeValues.SalutationGrade.CorrectSalutation}
              textValue='Correct Salutation'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.SalutationGrade.CorrectTitle}
              textValue='Correct Title'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.SalutationGrade.CorrectLastName}
              textValue='Correct Last Name'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.SalutationGrade.SalutationOnOwnLine}
              textValue='Salutation On Own Line'
            />
            <FormattingGradeValue
              gradeValue={!userGradeValues.SalutationGrade.IncludesFirstName}
              textValue="Doesn't include First Name"
            />
          </div>
          <div className='gradingFeedbackFormattingContentCard'>
            <div className='gradingFeedbackFormattingContentCardHeader'>
              <FontAwesomeIcon icon={faFileSignature} className='gradingFeedbackFormattingContentCardHeaderIcon' />
              <h4 className='gradingFeedbackFormattingContentHeader'>Signature & Date</h4>
              <p className='gradingFeedbackFormattingContentSubheader'>10% of Final Grade</p>
            </div>
            <FormattingGradeValue
              gradeValue={userGradeValues.Signature.CorrectSignature}
              textValue='Correct Signature'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.Signature.CorrectClosingSalutation}
              textValue='Correct Closing Salutation'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.DateGrade.CorrectDate}
              textValue='Correct Date'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.DateGrade.DateOnOwnLine}
              textValue='Date On Own Line'
            />
          </div>
          <div className='gradingFeedbackFormattingContentCard'>
            <div className='gradingFeedbackFormattingContentCardHeader'>
              <FontAwesomeIcon icon={faHospitalUser} className='gradingFeedbackFormattingContentCardHeaderIcon' />
              <h4 className='gradingFeedbackFormattingContentHeader'>Patient Info</h4>
              <p className='gradingFeedbackFormattingContentSubheader'>5% of Final Grade</p>
            </div>
            <FormattingGradeValue
              gradeValue={userGradeValues.PatientGrade.CorrectRegards}
              textValue='Correct Regards'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.PatientGrade.CorrectPatientName}
              textValue='Correct Patient Name'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.PatientGrade.CorrectDateOfBirth}
              textValue='Correct Date of Birth'
            />
            <FormattingGradeValue
              gradeValue={userGradeValues.PatientGrade.PatientDataOnOwnLine}
              textValue='Patient Data On Own Line'
            />
          </div>
        </div>
      </div>
      <div className='gradingFeedbackWritingSectionContainer'>
        <h3 className='gradingFeedbackWritingSectionHeader'>Word Count</h3>
        <p className='gradingFeedbackWritingSectionSubheader'>10% of Final Grade</p>
        <div className='gradingFeedbackWordCountContentWrapper'>
          <div className='gradingFeedbackWordCountContentCard'>
            <div className='gradingFeedbackWordCountContent'>
              <div className='gradingFeedbackWordCountContentCardHeader'>
                <FontAwesomeIcon icon={faParagraph} />
                <h4>Paragraph Word Count</h4>
                <p>5% of Final Grade</p>
              </div>
              <p className='gradingFeedbackWordCountContentCardText'>{userGradeValues.ParagraphWordCountGrade.message}</p>
            </div>
          </div>
          <div className='gradingFeedbackWordCountContentCard'>
            <div className='gradingFeedbackWordCountContent'>
              <div className='gradingFeedbackWordCountContentCardHeader'>
                <FontAwesomeIcon icon={faMemo} />
                <h4>Total Word Count</h4>
                <p>5% of Final Grade</p>
              </div>
              <p className='gradingFeedbackWordCountContentCardText'>{userGradeValues.WordCountGrade.message}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='gradingFeedbackWritingSectionContainer expandableContentContainer'>
        <h3 className='gradingFeedbackWritingSectionHeader'>Content</h3>
        <p className='gradingFeedbackWritingSectionSubheader'>30% of Final Grade</p>
        <div className={`gradingFeedbackWritingContentWrapper ${isContentComponentExpanded ? 'gradingFeedbackWritingContentExpanded' : ''}`}>
          {userGradeValues.ContentGrade.map((contentItem) => (
            <ContentItem
              isCorrect={contentItem.matched}
              itemText={contentItem.ContentToBeMatched}
              key={`Writing Content Item ${contentItem.ContentToBeMatched}`}
            />
          ))}
        </div>
        <button className='gradingFeedbackWritingExpandButton' onClick={() => setIsContentComponentExpanded(!isContentComponentExpanded)}>
          {!isContentComponentExpanded ? 'Show More' : 'Show Less'}
        </button>
      </div>
      <div className={`gradingFeedbackWritingSectionContainer ${userGradeValues.Grammar.length !== 0 ? 'expandableContentContainer' : ''}`}>
        <h3 className='gradingFeedbackWritingSectionHeader'>Grammar</h3>
        <p className='gradingFeedbackWritingSectionSubheader'>30% of Final Grade</p>
        <div className={`gradingFeedbackWritingContentWrapper ${isGradingComponentExpanded ? 'gradingFeedbackWritingContentExpanded' : ''}`}>
          {userGradeValues.Grammar.map((grammarItem, index) => (
            <GrammarItem
              title={grammarItem.rule.category.name}
              feedback={grammarItem.message}
              userAnswer={grammarItem.textToCorrect}
              correctAnswer={grammarItem.replacements[0].value}
              convertedToCorrect={grammarItem.ConvertedToCorrect}
              openGrammarOptions={openGrammarOptions}
              index={index}
              key={`Writing Grammar Item ${grammarItem.textToCorrect}`}
              handleChangeGrammarGrade={handleChangeGrammarGrade}
            />
          ))}
          {userGradeValues.Grammar.length === 0 && (
            <p className='gradingFeedbackWritingNoGrammarText'>No Grammar Errors Found!</p>
          )}
        </div>
        {userGradeValues.Grammar.length !== 0 && 
          <button className='gradingFeedbackWritingExpandButton' onClick={() => setIsGradingComponentExpanded(!isGradingComponentExpanded)}>
            {!isGradingComponentExpanded ? 'Show More' : 'Show Less'}
          </button>
        }
      </div>
      <AnimatePresence mode="wait">
        {currentlyOpenGrammarOptions && (
          <motion.div
            className='gradingFeedbackGrammarOptionsContainer'
            style={{top: currentlyOpenGrammarOptions.yOffset, left: currentlyOpenGrammarOptions.xOffset}}
            initial={animationsDisabled ? false : {opacity: 0, transform: 'translate(15px, -10px) scale(0.9)'}}
            animate={animationsDisabled ? false : {opacity: 1, transform: 'translate(0px, 0px) scale(1)'}}
            exit={animationsDisabled ? false : {opacity: 0, transform: 'translate(15px, -10px) scale(0.9)'}}
            transition={{duration: 0.15}}
          >
            <button className='gradingFeedbackGrammarOption' onClick={markGrammarAsCorrectHandler}>Mark As Correct</button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default WritingComponent;