import React from 'react'
import './PremadeExamCard.css'
import MDSpinner from 'react-md-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'
import formatDateString from '../../../Functions/FormatDateString'

const PremadeExamCard = (props) => {
  return (
    <motion.div
      initial={props.animationsDisabled ? false : {opacity: 0}}
      animate={props.animationsDisabled ? false : {opacity: 1}}
      exit={props.animationsDisabled ? false : {opacity: 0}}
      id={`case-${props.caseData.ExamId}`}
      // transition={{ delay: index < 15 ? index / 80 : 0.2 }}
      className={`caseListCaseContainer${props.selectedCase === props.caseData.ExamId ? ' caseListCaseSelected' : ''}`}
      onClick={() => props.setSelectedCase(props.caseData.ExamId)}
      key={`${props.caseData.ExamName} - ${props.caseData.ExamId}`}
    >
      {props.caseData.Completed &&
        <>
          <div className='caseListCompletedCaseMarkerWrapper'>
            <div className={ props.selectedCase === props.caseData.ExamId ? 'caseListCompletedCaseMarkerBlueSelected' : 'caseListCompletedCaseMarkerBlue'} />
          </div>
          <div className='caseListCompletedCaseMarkerWrapper'>
            <div className={ props.selectedCase === props.caseData.ExamId ? 'caseListCompletedCaseMarkerOrangeSelected' : 'caseListCompletedCaseMarkerOrange'} />
          </div>
        </>
      }
      <div className='caseListCaseHeaderWrapper'>
        <h2 className='caseListCaseId'>{props.caseData.ExamId}</h2>
        <h3 className='caseListCaseTitle'>{props.caseData.ExamName}</h3>
        {!props.userData.TurnOffHighYield ?
          <h4 className='caseListCaseMeta'>{props.caseData.HighYield} High Yield{props.caseData.Completed && ` | Completed: ${formatDateString(props.caseData.MostRecentCompletionDate)} (${Math.floor(props.caseData.MostRecentCompletionScore * 100) / 100}%)`}</h4>
        :
        <h4 className='caseListCaseMeta'>{props.caseData.Completed && `Completed: ${formatDateString(props.caseData.MostRecentCompletionDate)} (${Math.floor(props.caseData.MostRecentCompletionScore * 100) / 100}%)`}</h4>
        }
      </div>

      <div className='caseListCaseBodyWrapper'>
        {props.caseData.sections.map((section, index) => (  
          <div className='caseListCaseSectionWrapper' key={`${props.caseData.ExamId} - ${section.sectionId}`}>
            <FontAwesomeIcon icon={section.CategoryIcon} className='caseListCaseSectionIcon'/>
            <p className='caseListCaseSectionText'>Section {section.sectionId} - {section.sectionTitle}</p>
          </div>
        ))}
        {/* <div className='caseListCaseSectionWrapper'>
          <FontAwesomeIcon icon={faEarListen} className='caseListCaseSectionIcon'/>
          <p className='caseListCaseSectionText'>Section 4 - Section Title</p>
        </div>
        <div className='caseListCaseSectionWrapper'>
          <FontAwesomeIcon icon={faKeynote} className='caseListCaseSectionIcon'/>
          <p className='caseListCaseSectionText'>Section 68 - Section Title</p>
        </div>
        <div className='caseListCaseSectionWrapper'>
          <FontAwesomeIcon icon={faBookOpen} className='caseListCaseSectionIcon'/>
          <p className='caseListCaseSectionText'>Section 11 - Section Title</p>
        </div> */}
      </div>

      <div className={`caseListCaseFooterContainer${props.selectedCase === props.caseData.ExamId ? ' caseListCaseFooterSelected' : ''}`}>
        <div className='caseListCaseFooterReviewLaterContainer'>
          {props.caseData.ReviewLaterOutstanding ?
            <MDSpinner
              size={20}
              singleColor={props.selectedCase === props.caseData.ExamId ? '#fff' : props.primaryColor}
            />
          :
            <div className='checkboxContainer' onClick={(e) => props.handleExamReviewLater(e, props.caseData, props.examType)}>
              {props.caseData.ReviewLater && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
            </div>
          }
          <p className={`caseListCaseReviewLaterText ${props.selectedCase === props.caseData.ExamId ? 'caseListCaseReviewLaterTextSelected' : ''}`}>Review Later</p>
        </div>
        {props.caseData.Completed && props.caseData.LastCompletionDate !== '0001-01-01T00:00:00' &&
          <button className="caseListCaseButton secondaryButton" onClick={(e) => props.viewCaseGradesClick(e, props.caseData)}>View Grades</button>
        }
      </div>
    </motion.div>
  )
}

export default PremadeExamCard