import React, { Component } from 'react'
import './MultipleChoice.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/pro-light-svg-icons'
import FormattedEmail from '../../../Email/FormattedEmail'

export default class MultipleChoice extends Component {
  constructor() {
    super()
    this.state = {
      crossedOutAnswers: {}
    }
  }

  crossOutAnswerOption = (e, answerId) => {
    // Prevent the context menu from appearing
    e.preventDefault()

    let crossedOutAnswers = this.state.crossedOutAnswers

    // If the answer is selected, unselect it
    if (this.props.selectedAnswer === answerId)
      this.props.setSelectedAnswerIndex(null)

    // If the answer is crossed out, uncross it
    if (crossedOutAnswers[answerId])
      crossedOutAnswers[answerId] = null

    // If the answer is not crossed out, cross it
    else
      crossedOutAnswers[answerId] = true

    this.setState({ crossedOutAnswers })
  }

  selectAnswerHandler = (answerId) => {
    // If the answer is crossed out, uncross it
    if (this.state.crossedOutAnswers[answerId]) {
      let crossedOutAnswers = this.state.crossedOutAnswers
      crossedOutAnswers[answerId] = null
      this.setState({ crossedOutAnswers })
    }

    // Select the answer
    this.props.setSelectedAnswerIndex(answerId)
  }

  render() {
    return (
      <div className='multipleChoiceWrapper'>
        {this.props.content.extract &&
          <div className='multipleChoiceExtractWrapper'>
            <div className='multipleChoiceExtractContainer'>
              <p className='multipleChoiceExtractHeader'>{this.props.content.extract.header}</p>
              <p className='multipleChoiceExtractBody'>{this.props.content.extract.body}</p>
            </div>
          </div>
        }
        {this.props.content.email && Object.values(this.props.content.email).length > 0 &&
          <FormattedEmail
            to={this.props.content.email.to}
            subject={this.props.content.email.subject}
            body={this.props.content.email.body}
          />
        }
        <div className='multipleChoiceQuestionContainer'>
          <p className='multipleChoiceQuestionText'><strong style={{marginRight: '3px'}}>{this.props.questionNumber}.</strong>{this.props.content.question}</p>
        </div>
        {this.props.content.pdfFile !== null && this.props.content.pdfFile !== undefined && this.props.content.pdfFile !== "" &&
          <div className='openPDFButtonContainer'>
            <button
              className='openPDFButton'
              style={{position: 'relative', top: '-5px'}}
              onClick={() => this.props.openPDF(this.props.content.pdfBytes)}
            >
              <div className='openPDFIconWrapper'>
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className='openPDFButtonIcon'
                />
              </div>
              <span>{this.props.content.pdfName}</span>
            </button>
          </div>
        }
        {/* <div className='openPDFButtonContainer'>
          <button
            className='openPDFButton'
            style={{position: 'relative', top: '-5px'}}
            onClick={() => this.props.openPDF('test file')}
          >
            <div className='openPDFIconWrapper'>
              <FontAwesomeIcon
                icon={faFilePdf}
                className='openPDFButtonIcon'
              />
            </div>
            <span>Test PDF Name</span>
          </button>
        </div> */}
        {this.props.content.answerOptions.map((answerOption, index) => (
          <button
            className='multipleChoiceAnswerWrapper'
            onClick={() => this.selectAnswerHandler(answerOption.answerId)}
            onContextMenu={(e) => this.crossOutAnswerOption(e, answerOption.answerId)}
            key={`multipleChoiceAnswerWrapper - ${this.props.componentIndex} - ${index}`}
          >
            <div className='multipleChoiceAnswerLetter'>
              <p className={`multipleChoiceAnswerLetterText ${this.state.crossedOutAnswers[answerOption.answerId] ? 'crossedOutText' : ''}`}>{String.fromCharCode(65 + index)}</p>
            </div>
            <div className={`multipleChoiceAnswer ${this.props.selectedAnswer === answerOption.answerId ? 'selectedMultipleChoiceAnswer' : ''}`}>
              <p className={`multipleChoiceAnswerText ${this.state.crossedOutAnswers[answerOption.answerId] ? 'crossedOutText' : ''}`}>{answerOption.answer}</p>
            </div>
          </button>
        ))}
      </div>
    )
  }
}
