import React from 'react'
import './UploadingFilePopup.css'

export default function UploadingFilePopup(props) {
  return (
    <div className='uploadingFilePopupPageWrapper'>
      <div className='uploadingFilePopupBackdrop' />
      <div className='uploadingFilePopupContainer'>
        <div className='uploadingFilePopupHeaderContainer'>
          <h3 className='uploadingFilePopupHeader'>Uploading File</h3>
        </div>
        <div className='uploadingFilePopupBodyWrapper'>
          <p className='uploadingFilePopupText'>Please wait while we upload your audio to the server</p>
          <progress value={props.uploadProgress} max={1} className='uploadingFilePopupProgressBar' />
        </div>
      </div>
    </div>
  )
}
