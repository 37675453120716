import React, { useState } from "react";
import './FinishSectionPopup.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockNine } from '@fortawesome/pro-regular-svg-icons'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'

const FinishWarning = (props) => {
    return (
        <>
            <div className="finishSectionPopupModalBody">
                <FontAwesomeIcon
                    icon={faClockNine}
                    className='clockIconPopup'
                />
                <p>You still have time remaining in this <strong>section.</strong></p>
                <p>If you choose to <strong>finish this section</strong>, you will not be able to return to this section.</p>
                <p>Are you sure you would like to <strong>finish this section?</strong></p>
            </div>
            <footer className="finishSectionPopupModalFooter">
                <button onClick={props.onConfirm}>
                    <FontAwesomeIcon
                        icon={faCheck}
                        className='buttonIcon'
                    />
                    Yes, I would like to finish this section
                </button>
                <button onClick={props.onClose}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className='buttonIcon'
                    />
                    No, I would like to return to this section
                </button>
            </footer>
        </>
    )
}

const FinishConfirmation = (props) => {
    return(
        <>
            <div className="finishSectionPopupModalBody">
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className='clockIconPopup'
                    size='2x'
                />
                <p>If you select <strong>Finish Section</strong>, your answers will be submitted and you will not be able to return to this section.</p>
            </div>
            <footer className="finishSectionPopupModalFooter finishSectionPopupConfirmButtonWrapper">
                <button onClick={props.onClose}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className='buttonIcon'
                    />
                    Cancel
                </button>
                <button onClick={props.onConfirm}>
                    <FontAwesomeIcon
                        icon={faCheck}
                        className='buttonIcon'
                    />
                    Finish Section
                </button>
            </footer>
        </>
    )
}

export default function FinishSectionPopup(props) {

    const [popupIndex, setPopupIndex] = useState(1)

    const finishSectionWarningHandler = () => {
        setPopupIndex(0)
    }

    return (
        <div className="finishSectionPopupOverlay">
            <div className='finishSectionPopupBackdrop' />
            <div className={`finishSectionPopupModal ${popupIndex ? 'finishSectionWarning' : 'finishSectionConfirmation'}`}>
                <header className="finishSectionPopupModalHeader">
                    <p>Confirm Finish Section</p>
                </header>
                {popupIndex ?
                    <FinishWarning
                        onConfirm={finishSectionWarningHandler}
                        onClose={props.onClose}
                    />
                :
                    <FinishConfirmation
                        onConfirm={props.onConfirm}
                        onClose={props.onClose}
                    />
                }
            </div>
        </div>
    )
}