import React from 'react'
import './CaseListSidebar.css'
import Logo from '../../../Assets/logoCropped.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileChartPie, faUserDoctor } from '@fortawesome/pro-regular-svg-icons'
import { faArrowDownWideShort, faArrowUpShortWide, faCheck } from '@fortawesome/free-solid-svg-icons'
import MDSpinner from 'react-md-spinner'
import { AnimatePresence, motion } from 'framer-motion'

export default function CaseListSidebar(props) {

  return (
    <div className='caseListSidebar' onClick={props.toggleCaseMoreOptionsPopup}>
      { props.isCaseListIntroDisplayed ?
        <div className='caseListSidebarContentWrapper'>
          <div className='caseListLogoContainer'>
            {/* <img className='caseListLogo' alt='CCS Cases Logo' src={Logo}/> */}
            <h1 className='caseListLogoText'>OCC<span className='caseListLogoTextNormal'>English</span></h1>
            <button className='caseListDrawerCloseButton' onClick={props.toggleMobileDrawer} />
          </div>
        </div>
      :
        <div className='caseListSidebarContentWrapper'>
          <div className='caseListLogoContainer'>
            {/* <img className='caseListLogo' alt='CCS Cases Logo' src={Logo}/> */}
            <h1 className='caseListLogoText'>OCC<span className='caseListLogoTextNormal'>English</span></h1>
            <button className='caseListDrawerCloseButton' onClick={props.toggleMobileDrawer} />
          </div>
          {props.showSubscriptionEndWarning ?
            <p className='subscriptionEndWarningText'>Your subscription ends in {props.subscriptionDaysRemaining} {props.subscriptionDaysRemaining === 1 ? 'Day' : 'Days'}.</p>
          :
            null
          }
          <div className='caseListMobileNavButtonContainer'>
            <button className='caseListMobileNavButton' onClick={() => props.handlePageChange('ReportCard')}>
              <FontAwesomeIcon icon={faFileChartPie}  className='caseListHeaderButtonIcon'/>
              View Report Card
            </button>
            <button className='caseListMobileNavButton' onClick={() => props.handlePageChange('accountSettings')}>
              <FontAwesomeIcon icon={faUserDoctor}  className='caseListHeaderButtonIcon'/>
              My Account
            </button>
          </div>
          <div className='caseListDropdownWrapper dropdownWithButton'>
            <label className='standardLabel caseListSidebarLabel'>Sort By</label>
            <select value={props.sortOrder} onChange={props.handleSortChange} className='caseListDropdown'>
              <option value="CaseNumber">Exam Number</option>
              <option value="CompletionDate">Completion Date</option>
              <option value="ReviewLater">Review Later</option>
              <option value="HighYield">High Yield</option>
              <option value="AverageGrade">Average Grade</option>
              <option value="CaseTitle">Exam Title</option>
            </select>
            <button className='caseListSortDirectionButton' onClick={props.toggleSortDirection}>
              <FontAwesomeIcon icon={props.sortDirection === 'Ascending' ? faArrowUpShortWide : faArrowDownWideShort} className='caseListSortDirectionIcon' />
            </button>
          </div>
          <div className='caseListDropdownWrapper' style={{marginTop: '10px'}}>
            <label className='standardLabel caseListSidebarLabel'>Search</label>
            <input className='caseListSearchInput' initial-scale="1" maximum-scale="1" placeholder='Ex: Cough' type='text' value={props.searchTerm} onChange={props.handleSearchChange} />
          </div>
          <div className='caseListDropdownWrapper' style={{marginTop: '10px'}}>
            <label className='standardLabel caseListSidebarLabel'>Exam Style</label>
            <div className='caseListStyleToggle'>
              <button className={`caseListStyleToggleOption ${props.isExamStylePremade ? 'caseListStyleOptionSelected' : ''}`} onClick={() => props.setIsExamStylePremade(true)}>Premade</button>
              <button className={`caseListStyleToggleOption ${props.isExamStylePremade ? '' : 'caseListStyleOptionSelected'}`} onClick={() => props.setIsExamStylePremade(false)}>Custom</button>
              {props.caseListStyleToggleOutstanding &&
                <div className='caseListStyleToggleLoadingContainer'>
                  <MDSpinner 
                    size={30}
                    singleColor={props.secondaryColor}
                  />
                </div>
              }
            </div>
          </div>
          <AnimatePresence mode="wait">
            {!props.isExamStylePremade &&
              <motion.div
                key='caseListSidebarControlsWrapper'
                initial={props.animationsDisabled ? false : {opacity: 0, transform: 'scale(0.9)'}}
                animate={props.animationsDisabled ? false : {opacity: 1, transform: 'scale(1)'}}
                exit={props.animationsDisabled ? false : {opacity: 0, transform: 'scale(0.9)'}}
                transition={{duration: 0.25}}
              >
                <button
                  className='caseListMyExamsViewButton'
                  onClick={() => props.toggleIsViewingPreviousExams(!props.isViewingPreviousExams)}
                >
                  {props.isViewingPreviousExams ? 'Create a New Exam' : 'View My Custom Exams'}
                </button>
                <AnimatePresence mode="wait">
                  {!props.isViewingPreviousExams &&
                    <motion.div
                      key='caseListSidebarCategoriesWrapper'
                      initial={props.animationsDisabled ? false : {opacity: 0, transform: 'scale(0.9)'}}
                      animate={props.animationsDisabled ? false : {opacity: 1, transform: 'scale(1)'}}
                      exit={props.animationsDisabled ? false : {opacity: 0, transform: 'scale(0.9)'}}
                      transition={{duration: 0.25}}
                    >
                      <h2 className='caseListSidebarHeader'>Categories</h2>
                      {props.categories.map((category, index) => (
                        <div className='caseListSidebarCategoryWrapper' onClick={() => props.toggleCategory(index)} key={index + ' - ' + category.name}>
                          <div className='checkboxContainer'>
                            {category.checked && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
                          </div>
                          <FontAwesomeIcon icon={category.icon} className='caseListSidebarCategoryIcon' />
                          <p className="caseListSidebarText">{category.name}</p>
                        </div>
                      ))}
                    </motion.div>
                  }
                </AnimatePresence>
              </motion.div>
            }
          </AnimatePresence>
          <div className="caseListSidebarSectionBreak" />
          <h2 className='caseListSidebarHeader'>Other</h2>
          <div className='caseListSidebarTagWrapper' onClick={props.toggleHideCompleted}>
            <div className='checkboxContainer'>
                {props.isHideCompletedChecked && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
            </div>
            <p className='caseListSidebarText'>Hide Completed</p>
          </div>
          <div className='caseListSidebarTagWrapper' onClick={props.toggleHideIncomplete}>
            <div className='checkboxContainer'>
              {props.isHideIncompleteChecked && <FontAwesomeIcon icon={faCheck} className='checkboxCheck' />}
            </div>
            <p className='caseListSidebarText'>Hide Incomplete</p>
          </div>
        </div>
      }
    </div>
  )
}
