import React, { Component } from 'react'
import './AudioDeviceTest.css'
import MDSpinner from 'react-md-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck, faSquareXmark } from '@fortawesome/pro-regular-svg-icons'
import { motion } from 'framer-motion'

export default class AudioDeviceTest extends Component {
  constructor() {
    super()
    this.state = {
      hasBegunAudioTest: false,
      audioDeviceDetected: null,
      detectingAudioDevice: false,
      audioPermissionGranted: null,
      gettingAudioPermission: false,
      audioDeviceWorking: null,
      testingAudioDevice: false,
      audioDeviceErrorMessage: ''
    }
  }

  handleBeginAudioTest = () => {
    this.setState({hasBegunAudioTest: true}, () => {
      this.performAudioTest()
    })
  }

  handleRestartAudioTest = () => {
    if (this.audioTrack)
      this.audioTrack.stop()
    
    if (this.soundAnalyzer)
      clearInterval(this.soundAnalyzer)
    this.setState({
      audioDeviceDetected: null,
      detectingAudioDevice: false,
      audioPermissionGranted: null,
      gettingAudioPermission: false,
      audioDeviceWorking: null,
      testingAudioDevice: false,
      audioDeviceErrorMessage: ''
    }, () => {
      this.performAudioTest()
    })
  }

  getMicrophonePermission = async () => {
    this.setState({gettingAudioPermission: true})
    try {
      let stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      this.setState({gettingAudioPermission: false})
      return stream
    } catch(error) {
      this.setState({gettingAudioPermission: false})
      console.log(error)
      return false
    }
  }

  verifyInputDevice = async () => {
    this.setState({detectingAudioDevice: true})
    let mediaDevices = await navigator.mediaDevices.enumerateDevices()
    mediaDevices = mediaDevices.filter(device => device.kind === 'audioinput')
    this.setState({detectingAudioDevice: false})
    if (mediaDevices.length === 0) {
      return false
    } else {
      this.setState({audioDeviceDetected: true})
      return true
    }
  }

  testMicrophone = (stream) => {

    this.setState({testingAudioDevice: true})
    let audioTracks = stream.getAudioTracks()
    if (audioTracks.length === 0) {
      return this.setState({audioDeviceWorking: false, audioDeviceErrorMessage: 'No audio detected, please check your microphone and try again'})
    }

    this.audioTrack = audioTracks[0]

    // Create and configure the audio pipeline
    const audioContext = new AudioContext();
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 512;
    analyzer.smoothingTimeConstant = 0.1;
    const sourceNode = audioContext.createMediaStreamSource(stream);
    sourceNode.connect(analyzer);

    let intervalTotal = 0

    this.soundAnalyzer = setInterval(() => {
      const frequencyRangeData = new Uint8Array(analyzer.frequencyBinCount);
      analyzer.getByteFrequencyData(frequencyRangeData);
      const frequencyRangeSum = frequencyRangeData.reduce((p, c) => p + c, 0);
      intervalTotal += 1

      if (frequencyRangeSum > 0) {
        this.audioTrack.stop()
        this.setState({audioDeviceWorking: true, testingAudioDevice: false, audioDeviceErrorMessage: ''})
        clearInterval(this.soundAnalyzer)
      } else if (intervalTotal === 50) {
        this.setState({audioDeviceErrorMessage: 'We aren\'t detecting any audio coming from your microphone. Is your device muted?'})
      }
    }, 100);
  }

  performAudioTest = async () => {
    let isInputVerified = await this.verifyInputDevice()
    if (!isInputVerified) {
      return this.setState({audioDeviceDetected: false, audioDeviceErrorMessage: 'No audio input device found, please connect a microphone to continue'})
    }
    this.setState({audioDeviceDetected: true})
  
    let stream = await this.getMicrophonePermission()
    if (!stream) {
      return this.setState({audioPermissionGranted: false, audioDeviceErrorMessage: 'Microphone access denied, please allow microphone access to continue'})
    }
    this.setState({audioPermissionGranted: true})

    this.testMicrophone(stream)
  }

  audioTestComponent = (props) => {
    return (
      <div className='audioTestPopupTestContainer'>
        {props.loading ?
          <MDSpinner
            size={30}
            singleColor={this.props.primaryColor}
          />
        : props.success ?
          <FontAwesomeIcon
            icon={faSquareCheck}
            className='audioTestPopupTestSuccessIcon'
          />
        :
          <FontAwesomeIcon
            icon={faSquareXmark}
            className='audioTestPopupTestFailedIcon'
          />
        }
        <p className='audioTestPopupTestText'>{props.loading ? props.loadingText : props.success ? props.successText : props.failedText}</p>
      </div>
    )
  }

  render() {
    return (
      <div className='audoTestPopupPageWrapper'>
        <motion.div
          className='audioTestPopupBackdrop'
          initial={this.props.animationsDisabled ? false : {opacity: 1}}
          animate={this.props.animationsDisabled ? false : {opacity: 1}}
          exit={this.props.animationsDisabled ? false : {opacity: 0}}
          transition={{duration: 0.2}}
          key="audioDeviceTestBackdrop"
        />
        <motion.div
          className='audioTestPopupContainer'
          initial={this.props.animationsDisabled ? false : {y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
          animate={this.props.animationsDisabled ? false : {y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
          exit={this.props.animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
          transition={{duration: 0.2}}
          key="audioDeviceTestContainer"
        >
          <div className='audioTestPopupHeaderContainer'>
            <h3 className='audioTestPopupHeaderText'>Audio Device Test</h3>
          </div>
          { this.state.hasBegunAudioTest &&
            <this.audioTestComponent
              loading={this.state.detectingAudioDevice}
              success={this.state.audioDeviceDetected}
              loadingText={'Checking for audio devices...'}
              successText={'Audio device detected'}
              failedText={'No audio device detected'}
            />
          }
          { this.state.audioDeviceDetected &&
            <this.audioTestComponent
              loading={this.state.gettingAudioPermission}
              success={this.state.audioPermissionGranted}
              loadingText={'Requesting microphone access...'}
              successText={'Microphone access granted'}
              failedText={'Microphone access denied'}
            />
          }
          { this.state.audioPermissionGranted && this.state.audioDeviceDetected &&
            <this.audioTestComponent
              loading={this.state.testingAudioDevice}
              success={this.state.audioDeviceWorking}
              loadingText={'Listening for audio input...'}
              successText={'Audio input detected'}
              failedText={'No audio input detected'}
            />
          }
          {this.state.audioDeviceErrorMessage.length > 0 &&
            <p className='audioTestPopupInfoText' style={{fontWeight: 'bold'}}>{this.state.audioDeviceErrorMessage}</p>
          }
          {!this.state.hasBegunAudioTest &&
            <p className='audioTestPopupInfoText' style={{textAlign: 'center', margin: 20}}>We will now test your audio device to ensure that it is working properly. Please make sure that your microphone is connected and that it is not muted. <br /><br /> If you choose not to enable your microphone access, or do not have a microphone, you can choose to skip to the next section.</p>
          }
          {this.state.audioDeviceDetected && this.state.audioPermissionGranted && this.state.audioDeviceWorking ?
            <div className='audioTestPopupButtonContainer'>
              <button className='audioTestPopupConfirmButton' onClick={this.props.continue} style={{gridColumn: 'span 2'}}>Begin Speaking Section</button>
            </div>
          : !this.state.hasBegunAudioTest ?
            <div className='audioTestPopupButtonContainer'>
              <button className='audioTestPopupCancelButton' onClick={this.props.cancel}>Skip To Next Section</button>
              <button className='audioTestPopupConfirmButton' onClick={this.handleBeginAudioTest}>Begin Audio Test</button>
            </div>
          :
            <div className='audioTestPopupButtonContainer'>
              <button className='audioTestPopupCancelButton' onClick={this.props.cancel}>Skip To Next Section</button>
              <button className='audioTestPopupConfirmButton' onClick={this.handleRestartAudioTest}>Restart Audio Test</button>
            </div>
          }
        </motion.div>
      </div>
    )
  }
}
