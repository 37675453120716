import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { faVolume, faVolumeXmark } from '@fortawesome/pro-solid-svg-icons'
import React, { Component } from 'react'
import './AudioPlayer.css'
import animatedWaveform from '../../../../../Assets/audio-waveform.gif'
import flatWaveform from '../../../../../Assets/audio-flat-waveform.gif'

export default class AudioPlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      audioPlayerActive: false,
      audioVolume: 1,
      previousAudioVolume: 1,
      isAudioEnded: false,
    }

    if (props.createURL) {
      this.audioFile = new Audio(URL.createObjectURL(props.mp3Audio))
    } else {
      this.audioFile = new Audio(`${props.route}/getmp3.webapi?FileName=${props.content.mp3Audio}&Token=${this.props.userData.Token}`)
    }
  }

  componentDidMount() {
    this.audioFile.addEventListener('ended', () => {
      this.setState({ audioPlayerActive: false, isAudioEnded: true })
    })
    this.audioFile.addEventListener('play', () => {
      this.setState({ audioPlayerActive: true })
    })
    this.audioFile.addEventListener('pause', (e) => {
      this.setState({ audioPlayerActive: false })
    })
    this.audioFile.currentTime = 0
    this.playAudio()
  }

  componentWillUnmount() {
    this.pauseAudio()
    this.audioFile.removeEventListener('ended', () => {
      this.setState({ audioPlayerActive: false, isAudioEnded: true })
    })
    this.audioFile.removeEventListener('play', () => {
      this.setState({ audioPlayerActive: true })
    })
    this.audioFile.removeEventListener('pause', (e) => {
      this.setState({ audioPlayerActive: false })
    })
  }

  playAudio = async () => {
    await this.audioFile.play()
    this.setState({ audioPlayerActive: true })
  }

  pauseAudio = () => {
    this.audioFile.pause()
    this.setState({ audioPlayerActive: false })
  }

  toggleAudio = () => {
    if (!this.props.showPauseButton) {
      return
    }

    if (this.state.isAudioEnded) {
      this.audioFile.currentTime = 0
      return this.setState({ isAudioEnded: false }, () => {
        this.playAudio()
      })
    }

    if (this.state.audioPlayerActive) {
      this.pauseAudio()
    } else {
      this.playAudio()
    }
  }

  muteAudioHandler = () => {
    if (this.state.audioVolume > 0) {
      this.setState({
        previousAudioVolume: this.state.audioVolume,
        audioVolume: 0
      })
    } else {
      this.setState({ audioVolume: this.state.previousAudioVolume })
    }
  }

  adjustAudioVolume = (e) => {
    this.setState({ audioVolume: e.target.value })
    this.audioFile.volume = e.target.value
  }

  render() {
    return (
      <div className='audioComponentContainer'>
        <div className='audioAnimationWrapper'>
          <div className='audioAnimationContainer'>
            {this.state.audioPlayerActive ?
              <img src={animatedWaveform} alt='audio animation' className='audioGif' />
            :
              <img src={flatWaveform} alt='audio animation' className='audioGif' />
            }
          </div>
        </div>
        <div className='audioControlsWrapper'>
          <div className='audioPlayButtonWrapper' onClick={() => this.toggleAudio()}>
            {this.props.showPauseButton &&
              <FontAwesomeIcon
                icon={this.state.isAudioEnded ? faRotateRight : this.state.audioPlayerActive ? faPause : faPlay}
                className='audioPlayButton'
              />
            }
          </div>
          <div className='audioVolumeWrapper'>
            <FontAwesomeIcon
              icon={this.state.audioVolume > 0 ? faVolume : faVolumeXmark}
              className='audioVolumeButton'
              onClick={this.muteAudioHandler}
            />
            <div className='audioVolumeSliderWrapper'>
              <input
                type='range'
                min='0'
                max='1'
                step='0.01'
                value={this.state.audioVolume}
                onChange={this.adjustAudioVolume}
                style={{
                  background: `linear-gradient(to right, white 0%, white ${this.state.audioVolume * 100}%, #292929 ${this.state.audioVolume * 100}%, #292929 100%)`
                }}
                className='audioVolumeSlider'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
