import React, { Component } from 'react'
import './CaseList.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faBars, faCircleCheck, faCircleXmark, faArrowDownZA, faArrowUpAZ } from '@fortawesome/free-solid-svg-icons'
import { faUserDoctor, faFileChartPie } from '@fortawesome/pro-regular-svg-icons'
import MDSpinner from 'react-md-spinner'
import { AnimatePresence, motion } from 'framer-motion'
import PremadeExamCard from './PremadeExamCard/PremadeExamCard'
import CaseListSidebar from './CaseListSidebar/CaseListSidebar'
import CustomExamSectionCard from './CustomExamSectionCard/CustomExamSectionCard'
// import ConfirmationPopup from '../Util/ConfirmationPopup/ConfirmationPopup'
import ViewCaseDetailsPopup from './ViewCaseDetailsPopup/ViewCaseDetailsPopup'
import CaseListIntro from './CaseListIntro/CaseListIntro'

export default class CaseList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayIncompleteCasesPopup: false,
            pageHeight: window.innerHeight,
            pageWidth: window.innerWidth,
			incompleteCaseList: [],
			selectedIncompleteCase: 0,
			startNewCaseFunction: null,
			isCaseListReversed: false,
            loadingCaseList: false,
            isMobileDrawerOpen: false,
            popupLoading: false,
            hideCaseLoadingIdArray: [],
            reviewLaterFetchesOutstanding: [],
            confirmationPopupOptions: null,
            isCaseMoreOptionsPopupOpen: false,
            moreOptionsPopupPosition: {x: 0, y: 0, selectedCase: -1},
            currentSortDirection: 'Ascending',
            isViewingPreviousExams: false,
            moreOptionsY: null,
            moreOptionsX: null,
            selectedSection: null,
            isStartCasePopupOpen: false,
            startCasePopupData: null,
            isCaseListIntroDisplayed: props.userData.Profession === "",
            // isCaseListIntroDisplayed: true,
            searchTerm: ""
        }
    }

    componentDidMount() {
        window.addEventListener('resize', (event) => {
            this.setState({pageHeight: window.innerHeight, pageWidth: window.innerWidth})
        })
        var metaThemeColor = document.querySelector("meta[name=theme-color]")
        metaThemeColor.setAttribute("content", this.props.selectedTheme.primaryBackgroundColor)

        if (this.props.gradingMode === 'peek') {
            this.props.setGradingMode('complete', () => {
                setTimeout(() => {
                    if (this.props.selectedCase !== -1) {
                        // scroll to selected case
                        let selectedCase = document.getElementById(`case-${this.props.selectedCase}`)
                        if (selectedCase !== null) {
                            selectedCase.scrollIntoView({block: 'center'})
                        }
                    }
                }, 100)
            })
        } else {
            setTimeout(() => {
                if (this.props.selectedCase !== -1) {
                    // scroll to selected case
                    let selectedCase = document.getElementById(`case-${this.props.selectedCase}`)
                    if (selectedCase !== null) {
                        selectedCase.scrollIntoView({block: 'center'})
                    }
                }
            }, 100)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', (event) => {
            this.setState({pageHeight: window.innerHeight, pageWidth: window.innerWidth})
        })
    }

    handlePageChange = (page) => {
        this.props.changePage(page)
    }

	viewCaseGradesClick = (e, caseData) => {
        e.stopPropagation()

        let attemptsList

        if (!this.props.isExamStylePremade && !this.state.isViewingPreviousExams) {
            attemptsList = caseData.secCompletion
        } else {
            attemptsList = caseData.completedAttempts
        }

        if (!attemptsList) {
            return window.alert('There was an error viewing the grades for this case. Please try logging out and back in. If this issue persists reach out to us for assistance.')
        }

        if (attemptsList.length === 0) {
            return window.alert('There was an error viewing the grades for this case. Please try logging out and back in. If this issue persists reach out to us for assistance.')
        }

        let mostRecentCompletedAttemptGuid = attemptsList.reduce((mostRecent, current) =>
            new Date(current.TimeAttempted) > new Date(mostRecent.TimeAttempted) ? current : mostRecent
        ).guid;

        this.props.viewCaseGrades(mostRecentCompletedAttemptGuid ? mostRecentCompletedAttemptGuid : null);
	}
	
    beginCaseHandler = async (selectedSections) => {
        this.props.beginCase(this.state.isViewingPreviousExams, selectedSections)
    }
    
    selectRandomCase = () => {
        if (!this.props.isExamStylePremade && !this.state.isViewingPreviousExams) {
            // Create a random case from sections
            this.props.selectRandomSections()
        } else {
            let examList = this.props.caseListData.PremadeExams
            if (this.state.isViewingPreviousExams) {
                examList = this.props.caseListData.PreviousCustomExams
            }

            let premadeExamsWithSections = this.addSectionsToExamArray(examList, this.props.caseListData.ExamSections)
            let filteredExams = this.filterExamList(premadeExamsWithSections)

            let randomIndex = Math.floor(Math.random() * filteredExams.length)
            let randomCaseId = filteredExams[randomIndex].ExamId

            this.props.setSelectedCase(randomCaseId, () => {
                let selectedCase = document.getElementById(`case-${randomCaseId}`)
                if (selectedCase) {
                    selectedCase.scrollIntoView({behavior: this.props.animationsDisabled ? 'auto' : 'smooth', block: 'center'})
                }
            })
        }
    }

    toggleCategory = (index) => {
        let categories = [...this.props.categories]
        categories[index].checked = !categories[index].checked
        this.props.updateCategories(categories)
    }

    // Returns a map of exam sections where the key is the sectionId
    getExamSectionMap = (examSections) => {
        let examSectionsMap = {}
        examSections.forEach((section) => {
            examSectionsMap[section.sectionId] = section
        })
        return examSectionsMap
    }

    // Returns an array of exams with the sections added
    addSectionsToExamArray = (examArray, examSections) => {
        let examSectionsMap = this.getExamSectionMap(examSections)
        for(let i = 0; i < examArray.length; ++i) {
            examArray[i].sections = []
            examArray[i].HighYield = 0
            examArray[i].sectionIds.forEach((sectionId) => {
                examArray[i].sections.push(examSectionsMap[sectionId])
                examArray[i].HighYield += examSectionsMap[sectionId].HIGHYIELDCOUNT
            })
        }
        return examArray
    }

    filterExamList = (examList) => {
        let filteredExamList = []

        // Filter by search term
        if (this.state.searchTerm.length > 0) {
            examList.forEach((examValue) => {
                if (examValue.ExamName.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
                    filteredExamList.push(examValue)
                } else {
                    let sectionFound = false
                    examValue.sections.forEach((section) => {
                        if (section.sectionTitle.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
                            sectionFound = true
                        }
                    })
                    if (sectionFound) {
                        filteredExamList.push(examValue)
                    }
                }
            })
        } else {
            filteredExamList = examList
        }

        // Filter by completed/incomplete
        let tempExamList = []
        if (this.props.isHideCompletedChecked || this.props.isHideIncompleteChecked) {
            filteredExamList.forEach((examValue) => {
                if (this.props.isHideCompletedChecked && examValue.Completed) {
                    // Do nothing
                } else if (this.props.isHideIncompleteChecked && !examValue.Completed) {
                    // Do nothing
                } else {
                    tempExamList.push(examValue)
                }
            })
        } else {
            tempExamList = filteredExamList
        }

        return tempExamList
    }

    sortExamList = (examList) => {
        let newExamList = examList

        switch(this.props.sortOrder) {
            case 'CaseNumber':
                newExamList = [...newExamList].sort((a, b) => {
                    return a.ExamId - b.ExamId
                })
                break
            case 'CompletionDate':
                newExamList = [...newExamList].sort((a, b) => {
                    if (a.Completed) {
                        return new Date(b.MostRecentCompletionDate) - new Date(a.MostRecentCompletionDate)
                    } else {
                        return false
                    }
                })
                break
            case 'ReviewLater':
                newExamList = [...newExamList].sort((a, b) => {
                    return b.ReviewLater - a.ReviewLater
                })
                break
            case 'HighYield':
                newExamList = [...newExamList].sort((a, b) => {
                    return a.HighYield - b.HighYield
                })
                break
            case 'AverageGrade':
                newExamList = [...newExamList].sort((a, b) => {
                    return b.MostRecentCompletionScore - a.MostRecentCompletionScore
                })
                break
            case 'CaseTitle':
                newExamList = [...newExamList].sort((a, b) => {
                    return a.ExamName === b.ExamName ? 0 : a.ExamName < b.ExamName ? -1 : 1;
                })
                break
            default:
                break
        }
        
        if (this.props.sortDirection === 'Descending') {
            newExamList = [...newExamList].reverse()
        }

        return newExamList
    }

    addHeadersToExamArray = (examArray) => {
        let sortDirection = this.props.sortDirection
        switch(this.props.sortOrder) {
            case 'CaseNumber':
                for(let i = 0; i < examArray.length; ++i) {
                    if (i % 11 === 0 && i + 9 < examArray.length) {
                        examArray.splice(i, 0, {
                            type: 'logoText',
                            text: 'Exam Number',
                            headerText: `${examArray[i].ExamId} - ${examArray[i + 9].ExamId}`
                        })
                        i+= 1
                    } else if (i % 11 === 0) {
                        examArray.splice(i, 0, {
                            type: 'logoText',
                            text: 'Exam Number',
                            headerText: `${examArray[i].ExamId} - ${examArray[examArray.length - 1].ExamId}`
                        })
                        i+= 1
                    }
                }
                break
            case 'CompletionDate':
                for(let i = 0; i < examArray.length - 1; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i === 0 && examArray[i].Completed && examArray[i].MostRecentCompletionDate !== "0001-01-01T00:00:00") {
                            examArray.splice(i, 0, {
                                type: 'icon',
                                text: "Completed Cases",
                                icon: faCircleCheck
                            })
                            i += 1
                        } else if (i === 0 && (!examArray[i].Completed || (examArray[i].Completed && examArray[i].MostRecentCompletionDate === "0001-01-01T00:00:00"))) {
                            examArray.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                        if ((examArray[i].Completed !== examArray[i + 1].Completed) || (examArray[i + 1].Completed && examArray[i + 1].MostRecentCompletionDate === "0001-01-01T00:00:00")) {
                            examArray.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                    } else {
                        if (i === 0 && (!examArray[i].Completed || (examArray[i].Completed && examArray[i].MostRecentCompletionDate === "0001-01-01T00:00:00"))) {
                            examArray.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            i += 1
                        } else if (i === 0 && examArray[i].Completed && examArray[i].MostRecentCompletionDate !== "0001-01-01T00:00:00") {
                            examArray.splice(i, 0, {
                                type: 'icon',
                                text: "Completed Cases",
                                icon: faCircleCheck
                            })
                            break
                        }
                        if ((examArray[i].Completed !== examArray[i + 1].Completed) && examArray[i + 1].MostRecentCompletionDate !== "0001-01-01T00:00:00") {
                            examArray.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Completed Cases",
                                icon: faCircleCheck
                            })
                            break
                        }
                    }
                }
                break
            case 'ReviewLater':
                if (examArray[0].ReviewLater) {
                    if (examArray.length > 0) {
                        examArray.splice(0, 0, {
                            type: 'icon',
                            text: "Review Later",
                            icon: faCircleCheck
                        })
                    }
                } else {
                    if (examArray.length > 0) {
                        examArray.splice(0, 0, {
                            type: 'icon',
                            text: "Unmarked Cases",
                            icon: faCircleXmark
                        })
                    }
                }

                for(let i = 1; i < examArray.length - 1; ++i) {
                    if (examArray[i].ReviewLater !== examArray[i + 1].ReviewLater) {
                        if (examArray[i].ReviewLater) {
                            examArray.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Unmarked Cases",
                                icon: faCircleXmark
                            })
                        } else {
                            examArray.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Review Later",
                                icon: faCircleCheck
                            })
                        }
                        break
                    }
                }
                break
            case 'HighYield':
                let maxNumber = examArray.length > 10 ? 10 : examArray.length
                if (sortDirection === 'Ascending') {
                    if (examArray.length > 0) {
                        examArray.splice(0, 0, {
                            type: 'logoText',
                            text: "High Yield",
                            headerText: `Top 1 - ${maxNumber}`
                        })
                    }
                } else {
                    if (examArray.length > 0) {
                        examArray.splice(0, 0, {
                            type: 'logoText',
                            text: "High Yield",
                            headerText: `Top ${examArray.length} - ${examArray.length - maxNumber + 1}`
                        })
                    }
                }

                let counter = 1

                for(let i = 1; i < examArray.length; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i % 11 === 0) {
                            let highNumber = i + 10 - counter
                            let lowNumber = i + 1 - counter

                            if (highNumber + counter > examArray.length - 1) {
                                highNumber = examArray.length - counter
                            }

                            examArray.splice(i, 0, {
                                type: 'logoText',
                                text: "High Yield",
                                // headerText: `Top ${((Math.ceil(i / 10) - 1) * 10) + 1} - ${Math.ceil(i / 10) * 10}`
                                headerText: `Top ${lowNumber} - ${highNumber}`
                            })
                            counter++
                        }
                    } else {
                        if (i % 11 === 0) {
                            let lowNumber = examArray.length - i - 9
                            let highNumber = examArray.length - i
                            if (examArray.length - i - 10 < 0) {
                                lowNumber = 1
                            }
                            examArray.splice(i, 0, {
                                type: 'logoText',
                                text: "High Yield",
                                headerText: `Top ${highNumber} - ${lowNumber}`
                            })
                        }
                    }
                }
                break
            case 'AverageGrade':
                for(let i = 0; i < examArray.length - 1; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i === 0 && examArray[i].Completed && examArray[i].MostRecentCompletionDate !== "0001-01-01T00:00:00") {
                            examArray.splice(i, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            i += 1
                        } else if (i === 0 && (!examArray[i].Completed || (examArray[i].Completed && examArray[i].MostRecentCompletionDate === "0001-01-01T00:00:00"))) {
                            examArray.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                        if ((examArray[i].Completed !== examArray[i + 1].Completed) || (examArray[i + 1].Completed && examArray[i + 1].MostRecentCompletionDate === "0001-01-01T00:00:00")) {
                            examArray.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            break
                        }
                    } else {
                        if (i === 0 && (!examArray[i].Completed || (examArray[i].Completed && examArray[i].MostRecentCompletionDate === "0001-01-01T00:00:00"))) {
                            examArray.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Cases",
                                icon: faCircleXmark
                            })
                            i += 1
                        } else if (i === 0 && examArray[i].Completed && examArray[i].MostRecentCompletionDate !== "0001-01-01T00:00:00") {
                            examArray.splice(i, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            break
                        }
                        if ((examArray[i].Completed !== examArray[i + 1].Completed) && examArray[i + 1].MostRecentCompletionDate !== "0001-01-01T00:00:00") {
                            examArray.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            break
                        }
                    }
                }
                break
            case 'CaseTitle':
                if (sortDirection === 'Ascending') {
                    if (examArray.length > 0) {
                        examArray.splice(0, 0, {
                            type: 'icon',
                            text: 'Title',
                            icon: faArrowUpAZ
                        })
                    }
                } else {
                    if (examArray.length > 0) {
                        examArray.splice(0, 0, {
                            type: 'icon',
                            text: 'Title',
                            icon: faArrowDownZA
                        })
                    }
                }
                break
            default:
                break
        }
        return examArray
    }

    filterSectionList = (sectionList) => {
        let filteredSectionList = []

        // Filter by search term
        if (this.state.searchTerm.length > 0) {
            filteredSectionList = sectionList.filter((sectionItem) => {
                return sectionItem.sectionTitle.toLowerCase().includes(this.state.searchTerm.toLowerCase())
            })
        } else {
            filteredSectionList = sectionList
        }

        // Filter by completed/incomplete
        let tempSectionList = []
        if (this.props.isHideCompletedChecked || this.props.isHideIncompleteChecked) {
            filteredSectionList.forEach((sectionValue) => {
                if (this.props.isHideCompletedChecked && sectionValue.Completed) {
                    // Do nothing
                } else if (this.props.isHideIncompleteChecked && !sectionValue.Completed) {
                    // Do nothing
                } else {
                    tempSectionList.push(sectionValue)
                }
            })
        } else {
            tempSectionList = filteredSectionList
        }

        // Filter by category
        let newTempSectionList = []
        // this.props.categories
        let selectedCategories = []
        this.props.categories.forEach((category) => {
            if (category.checked) {
                selectedCategories.push(category.name)
            }
        })
        tempSectionList.forEach((sectionValue) => {
            if (selectedCategories.includes(sectionValue.sectionCategory)) {
                newTempSectionList.push(sectionValue)
            }
        })

        return newTempSectionList
    }

    sortSectionList = (sectionList) => {
        let sortedSectionList = sectionList
        switch (this.props.sortOrder) {
            case 'CaseNumber':
                sortedSectionList = [...sortedSectionList].sort((a, b) => {
                    return a.sectionId - b.sectionId
                })
                break
            case 'CompletionDate':
                sortedSectionList = [...sortedSectionList].sort((a, b) => {
                    return  new Date(b.LastCompletionDate) - new Date(a.LastCompletionDate)
                })
                break
            case 'ReviewLater':
                sortedSectionList = [...sortedSectionList].sort((a, b) => {
                    return  b.ReviewLaterFlag - a.ReviewLaterFlag
                })
                break
            case 'HighYield':
                sortedSectionList = [...sortedSectionList].sort((a, b) => {
                    return a.HIGHYIELDCOUNT - b.HIGHYIELDCOUNT
                })
                break
            case 'AverageGrade':
                sortedSectionList = [...sortedSectionList].sort((a, b) => {
                    return a.AvgGradeForCase - b.AvgGradeForCase
                })
                break
            case 'CaseTitle':
                sortedSectionList = [...sortedSectionList].sort((a, b) => {
                    return a.sectionTitle === b.sectionTitle ? 0 : a.sectionTitle < b.sectionTitle ? -1 : 1
                })
                break
            default:
                break
        }

        if (this.props.sortDirection === 'Descending') {
            sortedSectionList = [...sortedSectionList].reverse()
        }

        return sortedSectionList
    }

    addHeadersToSectionList = (sectionList) => {
        let sortDirection = this.props.sortDirection
        switch(this.props.sortOrder) {
            case 'CaseNumber':
                for(let i = 0; i < sectionList.length; ++i) {
                    if (i % 11 === 0 && i + 9 < sectionList.length) {
                        sectionList.splice(i, 0, {
                            type: 'logoText',
                            text: 'Section Number',
                            headerText: `${sectionList[i].sectionId} - ${sectionList[i + 9].sectionId}`
                        })
                        i+= 1
                    } else if (i % 11 === 0) {
                        sectionList.splice(i, 0, {
                            type: 'logoText',
                            text: 'Section Number',
                            headerText: `${sectionList[i].sectionId} - ${sectionList[sectionList.length - 1].sectionId}`
                        })
                        i+= 1
                    }
                }
                break
            case 'CompletionDate':
                for(let i = 0; i < sectionList.length - 1; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i === 0 && sectionList[i].Completed && sectionList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            sectionList.splice(i, 0, {
                                type: 'icon',
                                text: "Completed Sections",
                                icon: faCircleCheck
                            })
                            i += 1
                        } else if (i === 0 && (!sectionList[i].Completed || (sectionList[i].Completed && sectionList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            sectionList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Sections",
                                icon: faCircleXmark
                            })
                            break
                        }
                        if ((sectionList[i].Completed !== sectionList[i + 1].Completed) || (sectionList[i + 1].Completed && sectionList[i + 1].LastCompletionDate === "0001-01-01T00:00:00")) {
                            sectionList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Incomplete Sections",
                                icon: faCircleXmark
                            })
                            break
                        }
                    } else {
                        if (i === 0 && (!sectionList[i].Completed || (sectionList[i].Completed && sectionList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            sectionList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Sections",
                                icon: faCircleXmark
                            })
                            i += 1
                        } else if (i === 0 && sectionList[i].Completed && sectionList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            sectionList.splice(i, 0, {
                                type: 'icon',
                                text: "Completed Sections",
                                icon: faCircleCheck
                            })
                            break
                        }
                        if ((sectionList[i].Completed !== sectionList[i + 1].Completed) && sectionList[i + 1].LastCompletionDate !== "0001-01-01T00:00:00") {
                            sectionList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Completed Sections",
                                icon: faCircleCheck
                            })
                            break
                        }
                    }
                }
                break
            case 'ReviewLater':
                if (sortDirection === 'Ascending' && sectionList[0].ReviewLaterFlag) {
                    if (sectionList.length > 0) {
                        sectionList.splice(0, 0, {
                            type: 'icon',
                            text: "Review Later",
                            icon: faCircleCheck
                        })
                    }
                } else {
                    if (sectionList.length > 0) {
                        sectionList.splice(0, 0, {
                            type: 'icon',
                            text: "Unmarked Sections",
                            icon: faCircleXmark
                        })
                    }
                }

                for(let i = 1; i < sectionList.length - 1; ++i) {
                    if (sectionList[i].ReviewLaterFlag !== sectionList[i + 1].ReviewLaterFlag) {
                        if (sortDirection === 'Ascending') {
                            sectionList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Unmarked Sections",
                                icon: faCircleXmark
                            })
                        } else {
                            sectionList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Review Later",
                                icon: faCircleCheck
                            })
                        }
                        break
                    }
                }
                break
            case 'HighYield':
                let maxNumber = sectionList.length > 10 ? 10 : sectionList.length
                if (sortDirection === 'Ascending') {
                    if (sectionList.length > 0) {
                        sectionList.splice(0, 0, {
                            type: 'logoText',
                            text: "High Yield",
                            headerText: `Top 1 - ${maxNumber}`
                        })
                    }
                } else {
                    if (sectionList.length > 0) {
                        sectionList.splice(0, 0, {
                            type: 'logoText',
                            text: "High Yield",
                            headerText: `Top ${sectionList.length} - ${sectionList.length - maxNumber + 1}`
                        })
                    }
                }

                let counter = 1

                for(let i = 1; i < sectionList.length; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i % 11 === 0) {
                            let highNumber = i + 10 - counter
                            let lowNumber = i + 1 - counter

                            if (highNumber + counter > sectionList.length - 1) {
                                highNumber = sectionList.length - counter
                            }

                            sectionList.splice(i, 0, {
                                type: 'logoText',
                                text: "High Yield",
                                // headerText: `Top ${((Math.ceil(i / 10) - 1) * 10) + 1} - ${Math.ceil(i / 10) * 10}`
                                headerText: `Top ${lowNumber} - ${highNumber}`
                            })
                            counter++
                        }
                    } else {
                        if (i % 11 === 0) {
                            let lowNumber = sectionList.length - i - 9
                            let highNumber = sectionList.length - i
                            if (sectionList.length - i - 10 < 0) {
                                lowNumber = 1
                            }
                            sectionList.splice(i, 0, {
                                type: 'logoText',
                                text: "High Yield",
                                headerText: `Top ${highNumber} - ${lowNumber}`
                            })
                        }
                    }
                }
                break
            case 'AverageGrade':
                for(let i = 0; i < sectionList.length - 1; ++i) {
                    if (sortDirection === 'Ascending') {
                        if (i === 0 && sectionList[i].Completed && sectionList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            sectionList.splice(i, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            i += 1
                        } else if (i === 0 && (!sectionList[i].Completed || (sectionList[i].Completed && sectionList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            sectionList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Sections",
                                icon: faCircleXmark
                            })
                            break
                        }
                        if ((sectionList[i].Completed !== sectionList[i + 1].Completed) || (sectionList[i + 1].Completed && sectionList[i + 1].LastCompletionDate === "0001-01-01T00:00:00")) {
                            sectionList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Incomplete Sections",
                                icon: faCircleXmark
                            })
                            break
                        }
                    } else {
                        if (i === 0 && (!sectionList[i].Completed || (sectionList[i].Completed && sectionList[i].LastCompletionDate === "0001-01-01T00:00:00"))) {
                            sectionList.splice(i, 0, {
                                type: 'icon',
                                text: "Incomplete Sections",
                                icon: faCircleXmark
                            })
                            i += 1
                        } else if (i === 0 && sectionList[i].Completed && sectionList[i].LastCompletionDate !== "0001-01-01T00:00:00") {
                            sectionList.splice(i, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            break
                        }
                        if ((sectionList[i].Completed !== sectionList[i + 1].Completed) && sectionList[i + 1].LastCompletionDate !== "0001-01-01T00:00:00") {
                            sectionList.splice(i + 1, 0, {
                                type: 'icon',
                                text: "Average Grade",
                                icon: faCircleCheck
                            })
                            break
                        }
                    }
                }
                break
            case 'CaseTitle':
                if (sortDirection === 'Ascending') {
                    if (sectionList.length > 0) {
                        sectionList.splice(0, 0, {
                            type: 'icon',
                            text: 'Title',
                            icon: faArrowUpAZ
                        })
                    }
                } else {
                    if (sectionList.length > 0) {
                        sectionList.splice(0, 0, {
                            type: 'icon',
                            text: 'Title',
                            icon: faArrowDownZA
                        })
                    }
                }
                break
            default:
                break
        }
        return sectionList
    }

    handleSortChange = (e) => {
        this.props.updateSortOrder(e.target.value)
    }

    setIsExamStylePremade = (isExamStylePremade) => {
        this.props.setIsExamStylePremade(isExamStylePremade)
    }

    toggleCaseListSectionMoreOptions = (event, section) => {
        event.stopPropagation()
        if (this.state.isCaseMoreOptionsPopupOpen && this.state.selectedSection.sectionId === section.sectionId) {
            this.setState({isCaseMoreOptionsPopupOpen: false, selectedSection: null})
            return
        }

        // Set moreOptionsY to current mouse position
        let y = event.clientY
        let x = event.clientX
        this.setState({isCaseMoreOptionsPopupOpen: true, moreOptionsY: y, moreOptionsX: x, selectedSection: section})
    }

    handleStartCaseClick = () => {
        let selectedCaseIndex
        // The user has selected a premade exam
        if (this.props.isExamStylePremade) {
            selectedCaseIndex = this.props.caseListData.PremadeExams.findIndex((exam) => exam.ExamId === this.props.selectedCase)
        // The user has created a new custom exam
        } else if (!this.state.isViewingPreviousExams) {
            return this.setState({isStartCasePopupOpen: true, selectedCaseIndex: -1})
            // return window.alert('Not yet supported')
        // The user has selected a previous custom exam
        } else {
            selectedCaseIndex = this.props.caseListData.PreviousCustomExams.findIndex((exam) => exam.ExamId === this.props.selectedCase)
        }

        this.setState({isStartCasePopupOpen: true, selectedCaseIndex})
    }

    moveSectionIntoViewHandler = (sectionID) => {
        const section = document.getElementById(`section-${sectionID}`);
        if (section) {
            section.scrollIntoView({behavior: this.props.animationsDisabled ? 'auto' : 'smooth', block: 'center'});
        }
    }

    getCustomCaseDetails = () => {
        let examSectionMap = this.getExamSectionMap(this.props.caseListData.ExamSections)
        let highYieldCount = 0

        this.props.selectedSections.forEach((section) => {
            highYieldCount += examSectionMap[section.sectionId].HIGHYIELDCOUNT
        })

        let customCaseDetails = {
            Completed: false,
            examId: 0,
            ExamName: 'Custom Exam',
            HighYield: highYieldCount,
            IncompleteAttempts: [],
            MostRecentCompletionDate: null,
            MostRecentCompletionScore: null,
            ReviewLater: false,
            ReviewLaterOutstanding: false,
            sectionIds: this.props.selectedSections,
            sections: this.props.selectedSections.map((section) => examSectionMap[section.sectionId])
        }

        return customCaseDetails
    }

    render() {
        return (
            <div className='caseListPageContainer' style={{height: `${this.state.pageHeight}px`}}>
                <div className='caseListSidebarWrapper'>
                    <CaseListSidebar
                        showSubscriptionEndWarning={this.props.showSubscriptionEndWarning}
                        subscriptionDaysRemaining={this.props.subscriptionDaysRemaining}
                        handleSortChange={this.handleSortChange}
                        sortOrder={this.props.sortOrder}
                        handlePageChange={this.handlePageChange}
                        toggleMobileDrawer={() => this.setState({isMobileDrawerOpen: !this.state.isMobileDrawerOpen})}
                        isMobileDrawerOpen={this.state.isMobileDrawerOpen}
                        isCaseMoreOptionsPopupOpen={this.state.isCaseMoreOptionsPopupOpen}
                        toggleCaseMoreOptionsPopup={() => this.setState({isCaseMoreOptionsPopupOpen: false})}
                        toggleSortDirection={this.props.toggleSortDirection}
                        sortDirection={this.props.sortDirection}
                        setIsExamStylePremade={this.setIsExamStylePremade}
                        isExamStylePremade={this.props.isExamStylePremade}
                        isExamStyleToggleOutstanding={this.props.isExamStyleToggleOutstanding}
                        categories={this.props.categories}
                        toggleCategory={this.toggleCategory}
                        toggleHideCompleted={this.props.toggleHideCompleted}
                        isHideCompletedChecked={this.props.isHideCompletedChecked}
                        toggleHideIncomplete={this.props.toggleHideIncomplete}
                        isHideIncompleteChecked={this.props.isHideIncompleteChecked}
                        isViewingPreviousExams={this.state.isViewingPreviousExams}
                        toggleIsViewingPreviousExams={() => this.setState({isViewingPreviousExams: !this.state.isViewingPreviousExams})}
                        animationsDisabled={this.props.animationsDisabled}
                        searchTerm={this.state.searchTerm}
                        handleSearchChange={(e) => this.setState({searchTerm: e.target.value})}
                        isCaseListIntroDisplayed={this.state.isCaseListIntroDisplayed}
                    />
                </div>
                <AnimatePresence mode="wait">
                    { this.state.isCaseListIntroDisplayed ?
                        <CaseListIntro
                            setIsExamStylePremade={this.setIsExamStylePremade}
                            animationsDisabled={this.props.animationsDisabled}
                            route={this.props.route}
                            userData={this.props.userData}
                            closeIntro={() => this.setState({isCaseListIntroDisplayed: false})}
                        />
                    :
                        <motion.div className="caseListBodyPageWrapper" key="caseListBodyPageWrapper" onClick={() => this.setState({isCaseMoreOptionsPopupOpen: false})} style={{height: `${this.state.pageHeight + 1}px`}}>
                            <div className='caseListHeaderButtonContainer' style={{width: this.props.windowsSystem ? 'calc(100% - 36px)' : 'calc(100% - 20px'}}>
                                <button className='caseListHeaderButton secondaryButton' onClick={() => this.handlePageChange('ReportCard')}>
                                    <FontAwesomeIcon icon={faFileChartPie} className='caseListHeaderButtonIcon' />
                                    Report Card
                                </button>
                                <button className='caseListHeaderButton secondaryButton' onClick={() => this.handlePageChange('accountSettings')}>
                                    <FontAwesomeIcon icon={faUserDoctor} className='caseListHeaderButtonIcon' />
                                    My Account
                                </button>
                                <button className='caseListHeaderMobileButton secondaryButton' onClick={() => this.handlePageChange('ReportCard')}>
                                    <FontAwesomeIcon icon={faFileChartPie} className='caseListHeaderButtonIcon' />
                                </button>
                                <button className='caseListHeaderMobileButton secondaryButton' onClick={() => this.handlePageChange('accountSettings')}>
                                    <FontAwesomeIcon icon={faUserDoctor} className='caseListHeaderButtonIcon' />
                                </button>
                                <button className='caseListHeaderBurgerButton secondaryButton' onClick={() => this.setState({isMobileDrawerOpen: true})}>
                                    <FontAwesomeIcon icon={faBars} className='caseListHeaderButtonIcon' />
                                </button>
                            </div>
                            <AnimatePresence mode="wait">
                                {!this.props.loadingCaseList ?
                                    <this.GetCaseListDisplay />
                                :
                                    <motion.div
                                        className='caseListLoadingContainer'
                                        initial={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                        animate={this.props.animationsDisabled ? false : {opacity: 1, transform: `scale(1)`}}
                                        exit={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                                        style={{height: `${this.state.pageHeight - 50}px`}}
                                    >
                                        <div className='caseListLoadingWrapper'>
                                            <MDSpinner size={50} singleColor={this.props.primaryColor}/>
                                            <p className='caseListLoadingText'>Loading Exam List</p>
                                        </div>
                                    </motion.div>
                                }
                            </AnimatePresence>
                            <div className='caseListFooterContainer'>
                                {!this.props.isExamStylePremade && !this.state.isViewingPreviousExams &&
                                    <div className='caseListFooterSelectedSectionsWrapper'>
                                        <p className='caseListFooterSelectedSectionsText'>Selected Sections ({this.props.selectedSections.length})</p>
                                        <div className='caseListFooterSelectedSectionsContainer'>
                                            {this.props.selectedSections.sort((a, b) => a.sectionId - b.sectionId).map((section, index) => (
                                                <div className='caseListFooterSelectedSection' key={`Caselist-Footer-Section-${section.sectionId}`} onClick={() => this.moveSectionIntoViewHandler(section.sectionId)}>
                                                    <p className='caseListFooterSelectedSectionNumber'>{section.sectionId}</p>
                                                    <div className='caseListFooterSelectedSectionIconWrapper'>
                                                        <FontAwesomeIcon icon={section.categoryIcon} className='caseListFooterSelectedSectionIcon' />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                                <div className='caseListFooterButtonsWrapper'>
                                    <button className={`caseListFooterButton secondaryButton${this.state.loadingStartCase ? ' buttonDisabled' : ''}`} onClick={this.selectRandomCase}>{(!this.props.isExamStylePremade && !this.state.isViewingPreviousExams) ? 'Create' : 'Select'} Random Exam</button>
                                    {!this.state.loadingStartCase ?
                                        <button
                                            className={`caseListFooterButton primaryButton${this.props.selectedCase === -1 ? ' buttonDisabled' : ''}`}
                                            onClick={this.handleStartCaseClick}
                                        >
                                            View Exam
                                        </button>
                                    :
                                        <div className='caseListFooterLoaderWrapper'>
                                            <MDSpinner size={25} singleColor={this.props.primaryColor}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {this.state.isMobileDrawerOpen &&
                        <div className='caseListMobileDrawerPageContainer' key="caseListMobileDrawer">
                            <motion.div
                                className='caseListMobileDrawerBackdrop'
                                key='caseListMobileNavBackdrop'
                                initial={this.props.animationsDisabled ? false : {opacity: 0}}
                                animate={this.props.animationsDisabled ? false : {opacity: 1}}
                                exit={this.props.animationsDisabled ? false : {opacity: 0}}
                                transition={{duration: 0.1}}
                                onClick={() => this.setState({isMobileDrawerOpen: false})}
                            />
                            <motion.div
                                className='caseListMobileDrawerContainer'
                                key='caseListMobileDrawerContainer'
                                initial={this.props.animationsDisabled ? false : {y: '90vh'}}
                                animate={this.props.animationsDisabled ? false : {y: 0}}
                                exit={this.props.animationsDisabled ? false : {y: '90vh'}}
                                transition={{ type: 'none', duration: 0.25}}
                            >
                                <CaseListSidebar
                                    showSubscriptionEndWarning={this.props.showSubscriptionEndWarning}
                                    subscriptionDaysRemaining={this.props.subscriptionDaysRemaining}
                                    handleSortChange={this.handleSortChange}
                                    sortOrder={this.props.sortOrder}
                                    handlePageChange={this.handlePageChange}
                                    toggleMobileDrawer={() => this.setState({isMobileDrawerOpen: !this.state.isMobileDrawerOpen})}
                                    isMobileDrawerOpen={this.state.isMobileDrawerOpen}
                                    isCaseMoreOptionsPopupOpen={this.state.isCaseMoreOptionsPopupOpen}
                                    toggleCaseMoreOptionsPopup={() => this.setState({isCaseMoreOptionsPopupOpen: false})}
                                    toggleSortDirection={this.props.toggleSortDirection}
                                    sortDirection={this.props.sortDirection}
                                    setIsExamStylePremade={this.setIsExamStylePremade}
                                    isExamStylePremade={this.props.isExamStylePremade}
                                    isExamStyleToggleOutstanding={this.props.isExamStyleToggleOutstanding}
                                    categories={this.props.categories}
                                    toggleCategory={this.toggleCategory}
                                    toggleHideCompleted={this.props.toggleHideCompleted}
                                    isHideCompletedChecked={this.props.isHideCompletedChecked}
                                    toggleHideIncomplete={this.props.toggleHideIncomplete}
                                    isHideIncompleteChecked={this.props.isHideIncompleteChecked}
                                    isViewingPreviousExams={this.state.isViewingPreviousExams}
                                    toggleIsViewingPreviousExams={() => this.setState({isViewingPreviousExams: !this.state.isViewingPreviousExams})}
                                    animationsDisabled={this.props.animationsDisabled}
                                    searchTerm={this.state.searchTerm}
                                    handleSearchChange={(e) => this.setState({searchTerm: e.target.value})}
                                    isCaseListIntroDisplayed={this.state.isCaseListIntroDisplayed}
                                />
                            </motion.div>
                        </div>
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {this.state.isCaseMoreOptionsPopupOpen &&
                        <motion.div
                            className='moreOptionsPopupContainer'
                            style={{
                                top: this.state.moreOptionsY,
                                left: this.state.moreOptionsX - 220,
                            }}
                            initial={this.props.animationsDisabled ? false : {opacity: 0.15, transform: 'scale(0.95) translate(5%, -5%)'}}
                            animate={this.props.animationsDisabled ? false : {opacity: 1, transform: 'scale(1) translate(0%, 0%)'}}
                            exit={this.props.animationsDisabled ? false : {opacity: 0.15, transform: 'scale(0.95) translate(5%, -5%)'}}
                            transition={{duration: 0.15, ease: 'easeOut'}}
                            key='moreOptionsPopup'
                        >
                            {this.state.selectedSection.Completed &&
                                <button className='moreOptionsPopupButton' onClick={(e) => this.viewCaseGradesClick(e, this.state.selectedSection)}>
                                    View Grades
                                </button>
                            }
                            <button className='moreOptionsPopupReviewLaterButton' onClick={(e) => this.props.handleSectionReviewLater(e, this.state.selectedSection)}>
                                <div className='caseListSectionReviewLaterCheckboxWrapper'>
                                {this.state.selectedSection.ReviewLaterOutstanding ?
                                    <MDSpinner
                                    size={15}
                                    singleColor={this.props.primaryColor}
                                    />
                                :
                                    <div className='caseListSectionReviewLaterCheckbox'>
                                        {this.state.selectedSection.ReviewLaterFlag && <FontAwesomeIcon icon={faCheck} className='caseListSectionReviewLaterCheckboxIcon'/>}
                                    </div>
                                }
                                </div>
                                Review Later
                            </button>
                        </motion.div>
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {this.state.isStartCasePopupOpen && 
                        <ViewCaseDetailsPopup
                            selectedCase={this.props.selectedCase}
                            caseData={this.props.isExamStylePremade ? this.props.caseListData.PremadeExams[this.state.selectedCaseIndex] : this.state.isViewingPreviousExams ? this.props.caseListData.PreviousCustomExams[this.state.selectedCaseIndex] : this.getCustomCaseDetails()}
                            isExamStylePremade={this.props.isExamStylePremade}
                            isViewingPreviousExams={this.state.isViewingPreviousExams}
                            displayUpdateExamName={() => this.setState({isUpdateExamNamePopupOpen: true})}
                            setPopupLoading={this.props.setPopupLoading}
                            displayConfirmationPopup={this.props.displayConfirmationPopup}
                            closePopup={() => this.setState({isStartCasePopupOpen: false})}
                            beginCaseHandler={this.beginCaseHandler}
                            route={this.props.route}
                            updateCaseName={this.props.updateCaseName}
                            incompleteStartHandler={this.props.incompleteStartHandler}
                            sectionMap={this.getExamSectionMap(this.props.caseListData.ExamSections)}
                            handleSectionReviewLater={this.props.handleSectionReviewLater}
                            handleExamReviewLater={this.props.handleExamReviewLater}
                            primaryColor={this.props.primaryColor}
                            userData={this.props.userData}
                            fetchingStartExam={this.props.fetchingStartExam}
                            viewCaseGradesClick={this.viewCaseGradesClick}
                        />
                    }
                </AnimatePresence>
            </div>
        )
    }

    GetCaseListDisplay = () => {
        if (this.props.isExamStylePremade) {
            // If exam style is premade, display the premade case list
            let rawPremadeExams = this.props.caseListData.PremadeExams
            let premadeExamsWithSections = this.addSectionsToExamArray(rawPremadeExams, this.props.caseListData.ExamSections)
            let filteredPremadeExams = this.filterExamList(premadeExamsWithSections)
            let sortedPremadeExams = this.sortExamList(filteredPremadeExams)
            let premadeExamsWithHeaders = this.addHeadersToExamArray(sortedPremadeExams)
            let premadeExams = premadeExamsWithHeaders
            
            return (
                <AnimatePresence mode="wait">
                    <motion.div
                        className='caseListContainer'
                        initial={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                        animate={this.props.animationsDisabled ? false : {opacity: 1, transform: `scale(1)`}}
                        exit={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                        style={{height: `${this.state.pageHeight - 60}px`}}
                        key="CaseListPremadeCardContainer"
                    >
                        {premadeExams.map((value, index) => (
                            value.type ?
                                <div className='caseListSectionHeaderContainer' key={`${value.headerText} - ${index}`}>
                                    {value.type === 'logoText' ?
                                        <p className='castListSectionLogoText'>{value.headerText}</p>
                                    :
                                        <FontAwesomeIcon icon={value.icon} className='caseListSectionIcon' />
                                    }
                                    <h1 className='caseListSectionHeader'>{value.text}</h1>
                                </div>
                            :
                                <PremadeExamCard
                                    animationsDisabled={this.props.animationsDisabled}
                                    key={`Premade Exam Card - ${value.ExamId} - ${index}`}
                                    selectedCase={this.props.selectedCase}
                                    setSelectedCase={this.props.setSelectedCase}
                                    caseData={value}
                                    caseIndex={index}
                                    primaryColor={this.props.primaryColor}
                                    viewCaseGradesClick={this.viewCaseGradesClick}
                                    route={this.props.route}
                                    userData={this.props.userData}
                                    handleExamReviewLater={this.props.handleExamReviewLater}
                                    examType={'Premade'}
                                />
                            ))
                        }
                        {premadeExams.length === 0 && !this.props.loadingCaseList &&
                            <div className='caseListNoCasesContainer'>
                                <h3 className='caseListCaseTitle caseListNoCasesTitle'>No Cases Match Your Search</h3>
                                <p className='caseListCaseDiagnosisText caseListNoCasesText'>It looks like we don't have any cases matching your search. Try expanding your search by adding categories or changing your search input.</p>
                            </div>
                        }
                    </motion.div>
                </AnimatePresence>
            )
        } else if (!this.state.isViewingPreviousExams) {
            // If the user is not viewing previous exams, display the sections list

            let filteredExamSections = this.filterSectionList(this.props.caseListData.ExamSections)
            let sortedExamSections = this.sortSectionList(filteredExamSections)
            let examSectionsWithHeaders = this.addHeadersToSectionList(sortedExamSections)

            let examSections = examSectionsWithHeaders

            return (
                <AnimatePresence mode="wait">
                    <motion.div
                        className='caseListSectionWrapper'
                        initial={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                        animate={this.props.animationsDisabled ? false : {opacity: 1, transform: `scale(1)`}}
                        exit={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                        style={{height: `${this.state.pageHeight - 190}px`}}
                        key="CaseListCustomSectionContainer"
                    >
                        {examSections.map((value, index) => (
                            value.type ?
                                <div className='caseListSectionHeaderContainer' key={`${value.headerText} - ${index}`}>
                                    {value.type === 'logoText' ?
                                        <p className='castListSectionLogoText'>{value.headerText}</p>
                                    :
                                        <FontAwesomeIcon icon={value.icon} className='caseListSectionIcon' />
                                    }
                                    <h1 className='caseListSectionHeader'>{value.text}</h1>
                                </div>
                            :
                                <CustomExamSectionCard
                                    animationsDisabled={this.props.animationsDisabled}
                                    sectionData={value}
                                    userData={this.props.userData}
                                    handleSectionClick={this.props.handleSectionClick}
                                    key={`Custom Exam Section - ${value.sectionId} - ${index}`}
                                    completed={index % 5 === 0}
                                    isSectionSelected={this.props.selectedSections.some((selectedSection) => selectedSection.sectionId === value.sectionId)}
                                    toggleCaseListSectionMoreOptions={this.toggleCaseListSectionMoreOptions}
                                    handleSectionReviewLater={this.props.handleSectionReviewLater}
                                    primaryColor={this.props.primaryColor}
                                    viewCaseGradesClick={this.viewCaseGradesClick}
                                />
                            ))
                        }
                        {examSections.length === 0 && !this.props.loadingCaseList &&
                            <div className='caseListNoCasesContainer'>
                                <h3 className='caseListCaseTitle caseListNoCasesTitle'>No Cases Match Your Search</h3>
                                <p className='caseListCaseDiagnosisText caseListNoCasesText'>It looks like we don't have any cases matching your search. Try expanding your search by adding categories or changing your search input.</p>
                            </div>
                        }
                    </motion.div>
                </AnimatePresence>
            )
        } else {
            // If the user is viewing previous exams and the exam style is not premade, display the previous custom exams list
            let rawCustomExams = this.props.caseListData.PreviousCustomExams
            let customExamsWithSections = this.addSectionsToExamArray(rawCustomExams, this.props.caseListData.ExamSections)
            let filteredCustomExams = this.filterExamList(customExamsWithSections)
            let sortedCustomExams = this.sortExamList(filteredCustomExams)
            let customExamsWithHeaders = this.addHeadersToExamArray(sortedCustomExams)
            let customExams = customExamsWithHeaders

            return (
                <AnimatePresence mode="wait">
                    <motion.div
                        className='caseListContainer'
                        initial={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                        animate={this.props.animationsDisabled ? false : {opacity: 1, transform: `scale(1)`}}
                        exit={this.props.animationsDisabled ? false : {opacity: 0, transform: `scale(0.95)`}}
                        style={{height: `${this.state.pageHeight - 60}px`}}
                        key="CaseListPreviousExamsContainer"
                    >
                        {customExams.map((value, index) => (
                            value.type ?
                                <div className='caseListSectionHeaderContainer' key={`${value.headerText} - ${index}`}>
                                    {value.type === 'logoText' ?
                                        <p className='castListSectionLogoText'>{value.headerText}</p>
                                    :
                                        <FontAwesomeIcon icon={value.icon} className='caseListSectionIcon' />
                                    }
                                    <h1 className='caseListSectionHeader'>{value.text}</h1>
                                </div>
                            :
                                <PremadeExamCard
                                    animationsDisabled={this.props.animationsDisabled}
                                    key={`Custom Exam Card - ${value.ExamId} - ${index}`}
                                    selectedCase={this.props.selectedCase}
                                    setSelectedCase={this.props.setSelectedCase}
                                    caseData={value}
                                    caseIndex={index}
                                    primaryColor={this.props.primaryColor}
                                    viewCaseGradesClick={this.viewCaseGradesClick}
                                    route={this.props.route}
                                    userData={this.props.userData}
                                    handleExamReviewLater={this.props.handleExamReviewLater}
                                    examType={'Custom'}
                                />
                            ))
                        }
                        {customExams.length === 0 && !this.props.loadingCaseList &&
                            <div className='caseListNoCasesContainer'>
                                <h3 className='caseListCaseTitle caseListNoCasesTitle'>No Cases Match Your Search</h3>
                                <p className='caseListCaseDiagnosisText caseListNoCasesText'>It looks like we don't have any cases matching your search. Try expanding your search by adding categories or changing your search input.</p>
                            </div>
                        }
                    </motion.div>
                </AnimatePresence>
            )
        }
    }
}
