import React, { Component } from 'react'
import './Simulation.css'
import MultipleChoice from './Body/MainContent/MultipleChoice/MultipleChoice'
import TextArea from './Body/MainContent/TextArea/TextArea'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import TestBreakScreen from './TestBreakScreen/TestBreakScreen'
import FormattedEmail from './Email/FormattedEmail'
import ListeningInputQuestions from './Body/MainContent/ListeningInputQuestions/ListeningInputQuestions'
import examData from '../../Exam1.json'
import FinishSectionPopup from './FinishSectionPopup/FinishSectionPopup'
import AudioPlayer from './Body/MainContent/AudioPlayer/AudioPlayer'
import InfoComponent from './Body/MainContent/InfoComponent/InfoComponent'
import OutOfTimePopup from './OutOfTimePopup/OutOfTimePopup'
import AudioRecorder from './Body/MainContent/AudioRecorder/AudioRecorder'
import audioFile from '../../Assets/TestAudio.mp3'
import speakingContentImage from '../../Assets/speakingSectionContentCard.png'
import ProcessingAudioPopup from './Body/MainContent/ProcessingAudioPopup/ProcessingAudioPopup'
import PDFViewer from './PDFViewer/PDFViewer'
import Draggable from 'react-draggable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import FetchingNextSectionPopup from './Body/MainContent/FetchingNextSectionPopup/FetchingNextSectionPopup'
import axios from 'axios'
import UploadingFilePopup from './Body/MainContent/UploadingFilePopup/UploadingFilePopup'
import NewPdfViewer from './NewPdfViewer/NewPdfViewer'
import ExitExamPopup from './ExitExamPopup/ExitExamPopup'
import { PdfComponent } from './Body/MainContent/PdfComponent/PdfComponent'

// Font-family for everything but body is open sans. Body is arial

export default class Simulation extends Component {
  constructor(props) {
    super(props)

    let sectionIndex = props.selectedExamSections.indexOf(props.firstExamSection.sectionId)

    this.state = {
      timerInSeconds: props.firstExamSection.sectionTimeInSeconds,
      tabSelected: 0,
      selectedAnswerIndex: null,
      textAnswer: '',
      onBreak: false,
      sectionData: props.firstExamSection,
      currentSection: sectionIndex,
      currentSectionId: props.firstExamSection.sectionId,
      currentTab: 0,
      displayFinishSectionPopup: false,
      displayRanOutOfTimePopup: false,
      userAnswers: null,
      fetchedExamData: null,
      fetchingNextSection: false,
      displayPDFViewer: false,
      pdfDocked: false,
      pdfToDisplay: null,
      simulationWindowHeight: window.innerHeight,
      simulationBodyOverflowDetected: false,
      displayUploadingFilePopup: false,
      uploadProgress: 0,
      displayExitExamPopup: false
    }
    this.appendSectionAnswersToAnswerSheet(props.firstExamSection, true)
  }

  sectionTimer = null

  componentDidMount() {
    if (this.sectionTimer === null)
      this.startTimer()

    // if (this.state.userAnswers === null)
    //   this.appendSectionAnswersToAnswerSheet(this.props.firstExamSection)

    this.detectOverflowAndDisplayPopup()

    this.handleDisplayPdf(this.props.firstExamSection)

    window.addEventListener('resize', this.handleWindowResize)
  }

  handleDisplayPdf = (examSection) => {
    // Check for a pdf in the first section
    let pdfBytes = null

    for(let i = 0; i < examSection.tabs.length; i++) {
      let tabContent = examSection.tabs[i].tabContent
      for(let j = 0; j < tabContent.length; j++) {
        if (tabContent[j].pdfFile) {
          pdfBytes = tabContent[j].pdfBytes
          break
        }
      }
      if (pdfBytes) break
    }

    if (pdfBytes) {
      this.displayPdfHandler(pdfBytes)
    }
  }

  handleWindowResize = () => {
    this.setState({simulationWindowHeight: window.innerHeight})
  }

  componentWillUnmount() {
    this.stopTimer()
    window.removeEventListener('resize', this.handleWindowResize)
  }

  fetchNextSection = async () => {
    this.setState({fetchingNextSection: true})
    let body = {
      sectionId: this.props.selectedExamSections[this.state.currentSection + 1],
      customerId: this.props.userData.CustomerId,
      isTestComplete: this.props.selectedExamSections[this.state.currentSection + 1] ? false : true,
      examGuid: this.props.examGuid,
      custAnswers: Object.values(this.state.userAnswers)
    }
    await fetch(`${this.props.route}/getsection.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
      body: JSON.stringify(body)
		})
		.then(async response => {
      if (response.ok) {
				return response.json()
			} else if (response.status === 401) {
				return this.props.displayAuthenticationError()
			} else {
				throw new Error(await response.text())
			}
    })
		.then(async result => {
      if (this.state.sectionData.sectionCategory === 'Writing') {
        await this.fetchGradeWriting()
      }
      if (body.isTestComplete) {
        return setTimeout(() => {
          this.handleFinishExam()
        }, 1000)
      }
      this.appendSectionAnswersToAnswerSheet(result)
      this.handleDisplayPdf(result)
      this.setState({
        sectionData: result,
        fetchingNextSection: false,
        currentSection: this.state.currentSection + 1,
        timerInSeconds: result.sectionTimeInSeconds,
        tabSelected: 0,
      }, () => {
        this.contentWrapperRef.scrollTo(0, 0)
        this.startTimer()
        setTimeout(() => {
          this.detectOverflowAndDisplayPopup()
        }, 3)
      })
		})
		.catch(error => {
      this.setState({fetchingNextSection: false})
			console.log('error', error);
			// logFetchError(error, null, null, 'fetchLoginToken')
		});
  }

  detectOverflowAndDisplayPopup = () => {
    if(this.contentWrapperRef.offsetHeight < this.contentWrapperRef.scrollHeight)
      this.setState({simulationBodyOverflowDetected: true})
    else
      this.setState({simulationBodyOverflowDetected: false})
  }

  appendSectionAnswersToAnswerSheet = (sectionData, componentMounting = false) => {
    let userAnswers = this.state.userAnswers ? this.state.userAnswers : {}
    let questionNumber = 1
    if (userAnswers !== null && Object.values(userAnswers).length > 0) {
      questionNumber = Object.values(userAnswers).length + 1
    }
    sectionData.tabs.forEach(tab => {
      tab.tabContent.forEach(tabContent => {
        switch(tabContent.type) {
          case 'multipleChoice':
            if (tabContent.questionId) {
              userAnswers[tabContent.questionId] = {
                sectionId: sectionData.sectionId,
                answer: null,
                answerId: null,
                questionNumber: questionNumber++,
                questionId: tabContent.questionId
              }
            }
            break
          case 'input':
            tabContent.questionList.forEach(question => {
              if (question.questionId) {
                userAnswers[question.questionId] = {
                  sectionId: sectionData.sectionId,
                  answerId: null,
                  answer: "",
                  questionNumber: questionNumber++,
                  questionId: question.questionId
                }
              }
            })
            break
          case 'speaking':
            if (tabContent.questionId) {
              userAnswers[tabContent.questionId] = {
                sectionId: sectionData.sectionId,
                mp3File: null,
                audioTranscript: "",
                questionNumber: questionNumber++,
                questionId: tabContent.questionId
              }
            }
            break
          case 'writing':
            if (tabContent.questionId) {
              userAnswers[tabContent.questionId] = {
                sectionId: sectionData.sectionId,
                answerId: null,
                answer: "",
                questionNumber: questionNumber++,
                questionId: tabContent.questionId
              }
            }
            break
          default:
            break
        }
      })
    })
    if (userAnswers !== null) {
      if (componentMounting) {
        this.state.userAnswers = userAnswers
      } else {
        this.setState({ userAnswers })
      }
    }
  }

  startTimer = (speakingSkip = false) => {
    if (this.state.sectionData.sectionCategory.includes('Speaking') && !speakingSkip) {
      return
    }
    this.sectionTimer = setInterval(() => {
      this.setState({ timerInSeconds: this.state.timerInSeconds - 1 }, () => {
        if (this.state.timerInSeconds === 0) {
          this.advanceToNextSection(false)
          this.handleOutOfTime()
        }
      })
    }, 1000)
  }

  handleOutOfTime = () => {
    this.setState({ displayRanOutOfTimePopup: true })
  }

  stopTimer = () => {
    clearInterval(this.sectionTimer)
    this.sectionTimer = null
  }

  handleChangeQuestionButtons = (buttonClicked) => {
    // If the next button is clicked, go to the next question
    if (buttonClicked === 'next')
      this.handleNextButton()

    // If the back button is clicked, go to the previous question
    else if (buttonClicked === 'back')
      this.handleBackButton()
  }

  changeTab = (newTab) => {
    this.setState({ tabSelected: newTab })
  }

  saveAudioFile = (questionId, audioFile) => {
    let userAnswers = this.state.userAnswers
    userAnswers[questionId].mp3File = audioFile
    this.setState({ userAnswers })
  }

  sendAudioToDatabaseServer = async (questionId) => {
    const formData = new FormData();
    formData.append('file', this.state.userAnswers[questionId].mp3File);
    this.setState({displayUploadingFilePopup: true, uploadProgress: 0})
    let file = this.state.userAnswers[questionId].mp3File
    if (!file)
      return true;
    let currentMilliseconds = file.lastModified
    currentMilliseconds = currentMilliseconds - (currentMilliseconds % 1000)

    let secondsTotal = 0
    for (let i = 0; i < this.props.examGuid.length; i++) {
      if (this.props.examGuid[i] === '-') {
        continue
      } else {
        secondsTotal += parseInt(this.props.examGuid[i], 16)
      }
    }

    currentMilliseconds += secondsTotal

    let requestData = {
      method: "POST",
      url: `${this.props.route}/senduseraudio.webapi`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Token': this.props.userData.Token,
        'ExamGuid': this.props.examGuid,
        'LastModified': currentMilliseconds
      },
      data: formData,
      onUploadProgress: (p) => {
        this.setState({uploadProgress: p.progress})
        if (p.progress === 1) {
          this.setState({displayUploadingFilePopup: false})
        }
      }
    }

    let audioUploadRequest

    try {
      audioUploadRequest = await axios.request(requestData)
    } catch(error) {
      this.setState({displayUploadingFilePopup: false})
      return false
    }
    this.setState({displayUploadingFilePopup: false})
    return true
  }

  advanceToNextSection = async (displayPopup) => {
    if (displayPopup)
      return this.setState({ displayFinishSectionPopup: true })

    // Check if any tabs in the current section contain a speaking type
    let speakingSection = this.state.sectionData.tabs.find(tab => tab.tabContent.find(tabContent => tabContent.type === 'speaking'))
    if (speakingSection) {
      // Determine if there is more than one speaking tab in the current section
      let speakingTabs = speakingSection.tabContent.filter(tabContent => tabContent.type === 'speaking')
      for(let i = 0; i < speakingTabs.length; i++) {
        let successfulUpload = await this.sendAudioToDatabaseServer(speakingTabs[i].questionId)
        if (!successfulUpload) {
          return window.alert('failed to upload audio file')
        }
      }
    }

    let newSection = this.state.currentSection + 1
    this.setState({displayPDFViewer: false, pdfDocked: false})

    this.stopTimer()
    await this.fetchNextSection()
  }

  handleFinishExam = () => {
    this.props.viewCaseGrades(this.props.examGuid, true)
  }

  handleNextButton = () => {
    // If the current tab is not the last tab in the current section, go to the next tab
    if (this.state.tabSelected < this.state.sectionData.tabs.length - 1)
      this.changeTab(this.state.tabSelected + 1)

    // If the current tab is the last tab in the current section, go to the next section 
    else
      this.advanceToNextSection(true)
  }

  handleBackButton = () => {
    if (this.state.tabSelected > 0)
      this.setState({ tabSelected: this.state.tabSelected - 1 })
  }

  endBreak = () => {
    this.setState({ onBreak: false })
  }

  updateInputAnswer = (event, questionId) => {
    let userAnswers = this.state.userAnswers
    userAnswers[questionId].answer = event.target.value
    this.setState({ userAnswers })
  }

  handleMultipleChoiceSelection = (questionId, answerId) => {
    let userAnswers = this.state.userAnswers
    userAnswers[questionId].answerId = answerId
    this.setState({ userAnswers })
  }

  changeTabHandler = (newTab) => {
    this.setState({currentTab: newTab})
  }
  
  closeFinishSectionPopup = () => {
    this.setState({ displayFinishSectionPopup: false })
  }

  finishSection = () => {
    this.setState({ displayFinishSectionPopup: false }, () => {
      this.advanceToNextSection(false)
    })
  }

  closePDFViewer = () => {
    this.setState({ displayPDFViewer: false })
  }

  displayPdfHandler = (pdfBase64) => {
    // Remove double quotes from the base64 string
    pdfBase64 = pdfBase64.replace(/"/g, '')
    let pdf = 'data:application/pdf;base64,' + pdfBase64
    this.setState({ displayPDFViewer: true, pdfToDisplay: pdf })
  }

  updateWritingAnswer = (questionId, value) => {
    let userAnswers = this.state.userAnswers
    userAnswers[questionId].answer = value
    this.setState({ userAnswers })
  }

  fetchGradeWriting = async () => {

    // Need to get the questionId of the writing question
    let writingQuestionId = null

    for(let i = 0; i < this.state.sectionData.tabs.length; i++) {
      let tabContent = this.state.sectionData.tabs[i].tabContent
      for(let j = 0; j < tabContent.length; j++) {
        if (tabContent[j].type === 'writing') {
          writingQuestionId = tabContent[j].questionId
          break
        }
      }
      if (writingQuestionId) break
    }

    if (!writingQuestionId)
      return window.alert('Failed to find writing question id')

    await fetch(`${this.props.route}/gradewriting.webapi`, {
      method: 'POST',
      headers: {
        'Token': this.props.userData.Token,
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify({
        examGuid: this.props.examGuid,
        // userInput: "Dr. James Banerjee \r\nConsultant Cardiologist \r\nSanditon City Hospital \r\nSanditon\r\n\r\n2018 June 18th\r\n\r\nDr. Banerjee\r\n\r\nRe: Eleanor Bennet \r\ndate of birth: 06 Dec 1975\r\n\r\nI am writing to request your review of Ms Bennet, a 45-year-old lawyer, who is concerned about possible side effects of her cardiac medication.\r\n\r\nMs Bennet had an emergency admission at Oakville General Hospital on 8 February, having suffered a heart attack following a long-haul flight. She was given a balloon-expandable stent via the groin and prescribed captopril (50 mg twice daily) and atorvastatin (80mg daily). Two week history. Reflux is bad. She says her stricture sucks.\r\n\r\nMs Bennet was advised to make lifestyle changes as she is a heavy drinker (40 units/week) and smoker (20 per day), overweight (BMI: 29) and does little exercise. She was referred for cadiac rehabilitation sessions but has failed to attend.\r\n\r\nMs Bennet saw me today reporting problems with dizziness, headaches and diarrhoea. She asked to discontinue the captopril, attributing her symptoms to side effects of this medication. She has a family history of cardiac problems and mental health issues, with a father who died aged 53 of heart disease and a brother who is a suicide risk. She reports extreme stress over her family situation.\r\n\r\nI would appreciate it if you could review her medication and suggest ways to manage lifestyle changes.\r\n\r\nRegar3ds,\r\n\r\nDr. Tanner\r\n",
        userInput: this.state.userAnswers[writingQuestionId].answer,
        userSignature: "Dr. Tanner",
        sectionId: 13,
        demo: false
      })
    })
    .then((response) => {
      if(response.ok)
        return response.ok;
    })
    .then((jsonResponse) => {
    })
    .catch((error) => {
      console.log(error)
    })
  }

  GetContentInterfaceElement = (props) => {
    switch (props.content.type) {
      case 'multipleChoice':
        return (
          <MultipleChoice
            content={props.content}
            setSelectedAnswerIndex={(answerId) => this.handleMultipleChoiceSelection(props.content.questionId, answerId)}
            questionNumber={this.state.userAnswers[props.content.questionId].questionNumber}
            selectedAnswer={this.state.userAnswers[props.content.questionId].answerId}
            componentIndex={props.componentIndex}
            pdf={props.content.pdf}
            openPDF={this.displayPdfHandler}
          />
        )
      case 'textArea':
        return (
          <TextArea
            question={props.content.question}
            extract={props.content.extract}
          />
        )
      case 'input':
        return (
          <ListeningInputQuestions
            updateInputAnswer={this.updateInputAnswer}
            componentIndex={props.componentIndex}
            content={props.content}
            userAnswers={this.state.userAnswers}
            openPDF={this.displayPdfHandler}
          />
        )
      case 'info':
        return (
          <InfoComponent
            content={props.content}
            componentIndex={props.componentIndex}
          />
        )
      case 'audio':
        return (
          <AudioPlayer
            mp3Audio={audioFile}
            mp3Binary={props.content.mp3Binary}
            showPauseButton={props.content.showPauseButton}
            createURL={false}
            userData={this.props.userData}
            content={props.content}
            route={this.props.route}
            key={props.content.mp3Audio}
          />
        )
      case 'speaking':
        return (
          <AudioRecorder
            saveAudioFile={this.saveAudioFile}
            content={props.content}
            mp3AudioFile={this.state.userAnswers[props.content.questionId].mp3File}
            displayConfirmationPopup={this.props.displayConfirmationPopup}
            advanceToNextSection={this.advanceToNextSection}
            primaryColor={this.props.primaryColor}
            startTimer={this.startTimer}
            examGuid={this.props.examGuid}
            animationsDisabled={this.props.animationsDisabled}
          />
        )
      case 'image':
        return (
          <img className='imageComponent' onLoad={this.detectOverflowAndDisplayPopup} src={speakingContentImage} alt={props.content.alt} />
        )
      case 'writing':
        return (
          <TextArea
            answer={this.state.userAnswers[props.content.questionId].answer}
            setAnswer={(value) => this.updateWritingAnswer(props.content.questionId, value)}
          />
        )
      case 'pdf':
        return (
          <PdfComponent
            openPDF={this.displayPdfHandler}
            content={props.content}
          />
        )
      default:
        return (
          <div>
            <p>Unknown content type</p>
          </div>
        )
    }
  }

  displayExitExamPopup = () => {
    this.setState({ displayExitExamPopup: true })
  }

  render() {
    return (
      <div className='simulationWrapper' style={{ gridTemplateColumns: this.state.pdfDocked ? '1fr 1024px' : '1fr', display: this.state.pdfDocked ? 'grid' : 'block'}}>
        {this.state.displayPDFViewer &&
          // <PDFViewer 
          //   pdfFile={this.state.pdfToDisplay}
          //   onClose={this.closePDFViewer}
          //   pdfDocked={this.state.pdfDocked}
          //   handlePdfDock={(newState) => this.setState({ pdfDocked: newState })}
          // />
          <NewPdfViewer
            pdfFile={this.state.pdfToDisplay}
            onClose={this.closePDFViewer}
            pdfDocked={this.state.pdfDocked}
            handlePdfDock={(newState) => this.setState({ pdfDocked: newState })}
          />
        }
        <div className='simulationPageContainer' style={{height: this.state.simulationWindowHeight}}>
          <Header
            timeLeft={this.state.timerInSeconds}
            sectionTitle={this.state.sectionData.sectionTitle}
            finishSection={() => this.advanceToNextSection(true)}
            currentSection={this.state.currentSection}
            totalSections={this.props.selectedExamSections.length}
            exitExam={this.displayExitExamPopup}
          />

          <div className='simulationBodyWrapper'>
            <div className='simulationNavigationWrapper'>
              {this.state.sectionData.tabs.map((button, index) => (
                <button
                  className={`simulationNavigationButton ${this.state.tabSelected === index ? 'simulationNavigationButtonSelected' : ''}`}
                  onClick={() => this.changeTab(index)}
                  key={`simulationNavigationButton${index}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            {/* <div className='simulationContentWrapper' ref={(ref) => this.contentWrapperRef = ref}> */}
            <div className='simulationContentWrapper' onScroll={() => this.setState({simulationBodyOverflowDetected: false })} ref={(ref) => this.contentWrapperRef = ref}>
              {this.state.sectionData.tabs[this.state.currentTab].tabContent.map((content, index) => (
                <this.GetContentInterfaceElement
                  key={`simulationContent${index}`}
                  content={content}
                  componentIndex={index}
                />
              ))}
              {this.state.simulationBodyOverflowDetected &&
                <div className='simulationBodyOverflowPopupContainer' onClick={() => this.setState({simulationBodyOverflowDetected: false})}>
                  <p>This page requires scrolling</p>
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className='simulationBodyOverflowPopupIcon'
                  />
                </div>
              }
            </div>
          </div>

          <Footer
            tabSelected={this.state.tabSelected}
            onChangeQuestion={this.handleChangeQuestionButtons}
            maxNavigationValue={this.state.sectionData.tabs.length - 1}
          />
          {this.state.onBreak &&
            <TestBreakScreen
              endBreak={this.endBreak}
            />
          }
          {this.state.displayRanOutOfTimePopup &&
            <OutOfTimePopup
              closePopup={() => this.setState({ displayRanOutOfTimePopup: false })}
            />
          }
          {this.state.displayFinishSectionPopup &&
            <FinishSectionPopup
              onClose={this.closeFinishSectionPopup}
              onConfirm={this.finishSection}
            />
          }
          {this.state.displayProcessingAudioPopup &&
            <ProcessingAudioPopup
            
            />
          }
          {this.state.fetchingNextSection &&
            <FetchingNextSectionPopup
            
            />
          }
          {this.state.displayUploadingFilePopup &&
            <UploadingFilePopup
              uploadProgress={this.state.uploadProgress}
            />
          }
          {this.state.displayExitExamPopup &&
            <ExitExamPopup
              onClose={() => this.setState({ displayExitExamPopup: false })}
              onConfirm={this.props.exitExam}
            />
          }
        </div>
      </div>
    )
  }
}
