import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/pro-light-svg-icons'
import React, { Component } from 'react'
import './ListeningInputQuestions.css'

const GetInputQuestion = (props) => {
  let { statement, userAnswer, bulletType, questionNumber } = props
  let splitStatement = statement.split('{input}')

  return (
    <div className={`listeningInputStatementContainer ${bulletType === 2 ? 'listeningInputStatementIndented' : ''}`}>
      <p className={`listeningInputStatementText ${bulletType === 1 ? 'listeningQuestionBulletPoint' : bulletType === 2 ? 'listeningQuestionSubBulletPoint' : ''}`}>{splitStatement[0]} <strong>({questionNumber})</strong></p>
      <input className='listeningInputStatementInput' value={userAnswer} onChange={props.updateInputAnswer}/>
      <p className='listeningInputStatementText'>{splitStatement[1]}</p>
    </div>
  )
}

const ListeningInputQuestions = (props) => {
  return (
    <div className='listeningInputQuestionsWrapper'>
      {props.content.pdfFile !== null && props.content.pdfFile !== undefined && props.content.pdfFile !== "" &&
        <div className='openPDFButtonContainer'>
          <button
            className='openPDFButton'
            onClick={() => props.openPDF(props.content.pdfBytes)}
          >
            <div className='openPDFIconWrapper'>
              <FontAwesomeIcon
                icon={faFilePdf}
                className='openPDFButtonIcon'
              />
            </div>
            <span>{props.content.pdfName}</span>
          </button>
        </div>
      }
      {props.content.questionList.map((question, index) => (
        question.isSectionHeader ?
          <div className='listeningInputQuestionSectionHeader' key={`inputQuestionItem - ${props.componentIndex} - ${index}`}>
            <h3 className='listeningInputQuestionBoldText'>{question.statement}</h3>
          </div>
        :
          <div className='listeningInputQuestionContainer' key={`inputQuestionItem - ${props.componentIndex} - ${index}`}>
            {question.boldText !== "" ?
              <h3 className='listeningInputQuestionBoldText'>{question.boldText}</h3>
            :
              <div></div>
            }
            {question.statement.includes('{input}') ?
              <GetInputQuestion
                statement={question.statement}
                bulletType={question.bulletType}
                questionNumber={props.userAnswers[question.questionId].questionNumber}
                userAnswer={props.userAnswers[question.questionId].answer}
                updateInputAnswer={(e) => props.updateInputAnswer(e, question.questionId)}
              />
            :
              <div className={`listeningInputStatementContainer ${question.bulletType === 2 ? 'listeningInputStatementIndented' : ''}`}>
                <p className={`listeningInputStatementText ${question.bulletType === 1 ? 'listeningQuestionBulletPoint' : question.bulletType === 2 ? 'listeningQuestionSubBulletPoint' : ''}`}>{question.statement}</p>
              </div>
            }
          </div>
      ))}
    </div>
  )
}

export default ListeningInputQuestions