import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import './Footer.css'

export default function Footer(props) {
    return (
        <div className='simulationFooterWrapper'>
          <button
            className={`simulationFooterButton ${props.tabSelected === 0 ? 'buttonDisabled' : ''}`}
            onClick={() => props.onChangeQuestion('back')}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className='chevronIcon'
            />
            Back
          </button>
          <button
            className={`simulationFooterButton`}
            onClick={() => props.onChangeQuestion('next')}
          >
            Next
            <FontAwesomeIcon
              icon={faChevronRight}
              className='chevronIcon'
            />
          </button>
          {/* <button className='simulationFooterButton'>
            Start the Test
            <FontAwesomeIcon
              icon={faChevronRight}
              className='chevronIcon'
            />
          </button> */}
        </div>
    )
}