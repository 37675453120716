import React from "react";
import './TestBreakScreen.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons'

export default function TestBreakScreen(props) {
    return (
        <div className="breakPopup">
            <div className="breakPopupContainer">
                <div className="buttonContainer">
                    <button className="resumeBtn" onClick={props.endBreak}>
                        <FontAwesomeIcon
                            icon={faCirclePlay}
                            className='resumeIcon'
                        />
                        <span className="buttonTitle">Resume test</span>
                    </button>
                </div>
                <div className="breakTextContainer">
                    <p className="breakText">Scheduled Break Time Remaining: 00:00:00</p>
                </div>
            </div>
        </div>
    )
} 