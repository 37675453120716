import { useState } from 'react';
import './WritingAIFeedbackModal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { motion } from 'framer-motion';
import MDSpinner from 'react-md-spinner';

const WritingAIFeedbackModal = ({ closeModal, animationsDisabled, primaryColor, userData, route, sectionData }) => {
  const [feedback, setFeedback] = useState({
    formattingGrade: {
      gradeValue: null,
      gradeComment: '',
      gradeCommentExpanded: false
    },
    contentGrade: {
      gradeValue: null,
      gradeComment: '',
      gradeCommentExpanded: false
    },
    grammarGrade: {
      gradeValue: null,
      gradeComment: '',
      gradeCommentExpanded: false
    }
  })
  const [fetchOutstanding, setFetchOutstanding] = useState(false);

  const updateButtonSelected = (section, value) => {
    switch (section) {
      case 'Formatting Grade':
        setFeedback({...feedback, formattingGrade: {...feedback.formattingGrade, gradeValue: value}})
        break;
      case 'Content Grade':
        setFeedback({...feedback, contentGrade: {...feedback.contentGrade, gradeValue: value}})
        break;
      case 'Grammar Grade':
        setFeedback({...feedback, grammarGrade: {...feedback.grammarGrade, gradeValue: value}})
        break;
      default:
        break;
    }
  }

  const fetchSubmitFeedback = async () => {
    let body = {
      customerId: userData.customerId,
      sectionId: sectionData.sectionId,
      formattingGrade: feedback.formattingGrade.gradeValue,
      formattingComment: feedback.formattingGrade.gradeComment,
      contentGrade: feedback.contentGrade.gradeValue,
      contentComment: feedback.contentGrade.gradeComment,
      grammarGrade: feedback.grammarGrade.gradeValue,
      grammarComment: feedback.grammarGrade.gradeComment
    }
    // sendfeedback.webapi

    setFetchOutstanding(true);
    await fetch(`${route}/sendfeedback.webapi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': userData.Token
      },
      body: JSON.stringify(body)
    })
    .then(res => res.text())
    .then(data => {
      setFetchOutstanding(false);
      closeModal();
    })
    .catch(err => {
      console.log(err)
      setFetchOutstanding(false);
    })
  }

  const isSubmitButtonDisabled = () => {
    if (feedback.formattingGrade.gradeValue === null && feedback.contentGrade.gradeValue === null && feedback.grammarGrade.gradeValue === null) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <motion.div
        className='writingAIFeedbackBackdrop'
        onClick={closeModal}
        key='writingAIFeedbackBackdrop'
        initial={animationsDisabled ? false : {opacity: 0}}
        animate={animationsDisabled ? false : {opacity: 1}}
        exit={animationsDisabled ? false : {opacity: 0}}
        transition={{duration: 0.2}}
      />
      <motion.div
        className='writingAIFeedbackContainer'
        initial={animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
        animate={animationsDisabled ? false : {y: 0, opacity: 1, translateX: '-50%', translateY: '-50%', scale: 1}}
        exit={animationsDisabled ? false : {y: 30, opacity: 0, translateX: '-50%', translateY: '-50%', scale: 0.9}}
        transition={{duration: 0.2}}
        key='writingAIFeedbackContainer'
      >
        <button className='popupCloseButton' onClick={closeModal} />
        <h2>How Did We Do?</h2>
        <p>Want to help us improve? Your feedback helps us train our AI models to improve grading quality. Thank you!</p>
        <div className='writingAIFeedbackBodyContainer'>
          <div className='writingAIFeedbackBodyOptionContainer'>
            <h3>Formatting Grade</h3>
            <div className='writingAIFeedbackGradeValueWrapper'>
              <button className={`writingAIFeedbackGradeValueThumbButton ${feedback.formattingGrade.gradeValue >= 1 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 1)}>
                <FontAwesomeIcon icon={faThumbsDown} />
              </button>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.formattingGrade.gradeValue >= 2 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 2)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.formattingGrade.gradeValue >= 3 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 3)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.formattingGrade.gradeValue >= 4 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 4)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.formattingGrade.gradeValue >= 5 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 5)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.formattingGrade.gradeValue >= 6 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 6)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.formattingGrade.gradeValue >= 7 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 7)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.formattingGrade.gradeValue >= 8 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 8)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.formattingGrade.gradeValue >= 9 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 9)}/>
              <button className={`writingAIFeedbackGradeValueThumbButton ${feedback.formattingGrade.gradeValue >= 10 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Formatting Grade', 10)}>
                <FontAwesomeIcon icon={faThumbsUp} />
              </button>
            </div>
            {feedback.formattingGrade.gradeCommentExpanded ?
              <textarea className='writingAIFeedbackCommentBox' placeholder='Additional Comments...' value={feedback.formattingGrade.gradeComment} onChange={(e) => setFeedback({...feedback, formattingGrade: {...feedback.formattingGrade, gradeComment: e.target.value}})}/>
            :
              <button className='writingAIFeedbackCommentButton' onClick={() => setFeedback({...feedback, formattingGrade: {...feedback.formattingGrade, gradeCommentExpanded: true}})}>Tell us more</button>
            }
          </div>
          <div className='writingAIFeedbackBodyOptionContainer'>
            <h3>Content Grade</h3>
            <div className='writingAIFeedbackGradeValueWrapper'>
              <button className={`writingAIFeedbackGradeValueThumbButton ${feedback.contentGrade.gradeValue >= 1 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 1)}>
                <FontAwesomeIcon icon={faThumbsDown} />
              </button>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.contentGrade.gradeValue >= 2 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 2)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.contentGrade.gradeValue >= 3 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 3)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.contentGrade.gradeValue >= 4 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 4)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.contentGrade.gradeValue >= 5 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 5)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.contentGrade.gradeValue >= 6 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 6)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.contentGrade.gradeValue >= 7 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 7)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.contentGrade.gradeValue >= 8 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 8)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.contentGrade.gradeValue >= 9 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 9)}/>
              <button className={`writingAIFeedbackGradeValueThumbButton ${feedback.contentGrade.gradeValue >= 10 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Content Grade', 10)}>
                <FontAwesomeIcon icon={faThumbsUp} />
              </button>
            </div>
            {feedback.contentGrade.gradeCommentExpanded ?
              <textarea className='writingAIFeedbackCommentBox' placeholder='Additional Comments...' value={feedback.contentGrade.gradeComment} onChange={(e) => setFeedback({...feedback, contentGrade: {...feedback.contentGrade, gradeComment: e.target.value}})}/>
            :
              <button className='writingAIFeedbackCommentButton' onClick={() => setFeedback({...feedback, contentGrade: {...feedback.contentGrade, gradeCommentExpanded: true}})}>Tell us more</button>
            }
          </div>
          <div className='writingAIFeedbackBodyOptionContainer'>
            <h3>Grammar Grade</h3>
            <div className='writingAIFeedbackGradeValueWrapper'>
              <button className={`writingAIFeedbackGradeValueThumbButton ${feedback.grammarGrade.gradeValue >= 1 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 1)}>
                <FontAwesomeIcon icon={faThumbsDown} />
              </button>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.grammarGrade.gradeValue >= 2 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 2)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.grammarGrade.gradeValue >= 3 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 3)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.grammarGrade.gradeValue >= 4 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 4)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.grammarGrade.gradeValue >= 5 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 5)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.grammarGrade.gradeValue >= 6 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 6)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.grammarGrade.gradeValue >= 7 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 7)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.grammarGrade.gradeValue >= 8 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 8)}/>
              <button className={`writingAIFeedbackGradeValueButton ${feedback.grammarGrade.gradeValue >= 9 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 9)}/>
              <button className={`writingAIFeedbackGradeValueThumbButton ${feedback.grammarGrade.gradeValue >= 10 ? 'writingAIFeedbackGradeValueSelected' : ''}`} onClick={() => updateButtonSelected('Grammar Grade', 10)}>
                <FontAwesomeIcon icon={faThumbsUp} />
              </button>
            </div>
            {feedback.grammarGrade.gradeCommentExpanded ?
              <textarea className='writingAIFeedbackCommentBox' placeholder='Additional Comments...' value={feedback.grammarGrade.gradeComment} onChange={(e) => setFeedback({...feedback, grammarGrade: {...feedback.grammarGrade, gradeComment: e.target.value}})}/>
            :
              <button className='writingAIFeedbackCommentButton' onClick={() => setFeedback({...feedback, grammarGrade: {...feedback.grammarGrade, gradeCommentExpanded: true}})}>Tell us more</button>
            }
          </div>
        </div>
        <div className='writingAIFeedbackFooterContainer'>
          <button className='writingAIFeedbackCancelButton' onClick={closeModal}>Cancel</button>
          {fetchOutstanding ?
            <div className='writingAIFeedbackLoadingWrapper'>
              <MDSpinner
                size={30}
                singleColor={primaryColor}
              />
            </div>
          :
            <button
              className='writingAIFeedbackSubmitButton'
              onClick={fetchSubmitFeedback}
              disabled={isSubmitButtonDisabled()}
              style={{opacity: isSubmitButtonDisabled() ? 0.5 : 1, pointerEvents: isSubmitButtonDisabled() ? 'none' : 'all'}}
            >Submit Feedback</button>
          }
        </div>
      </motion.div>
    </>
  )
}

export default WritingAIFeedbackModal;