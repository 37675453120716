import React, { Component } from 'react'
import './Grading.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faUser, faUsers, faCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import logFetchError from '../../Functions/LogFetchError'
import fetchSubmitLogs from '../../Functions/FetchSubmitLogs'
import ViewCompletionsPopup from './Components/ViewCompletionsPopup/ViewCompletionsPopup'
import { AnimatePresence, motion } from 'framer-motion'
import MDSpinner from 'react-md-spinner'
import formatDateString from '../../Functions/FormatDateString'
import formatTimeString from '../../Functions/FormatTimeString'
import { faFilePdf } from '@fortawesome/pro-light-svg-icons'
import WritingComponent from './Components/WritingComponent/WritingComponent'
import createGradeOverview from './Util/CreateGradingOverview'
import AudioPlayer from './Components/AudioPlayer/AudioPlayer'
import NewPdfViewer from '../Simulation/NewPdfViewer/NewPdfViewer'
import WritingAIFeedbackModal from './Components/WritingAIFeedbackModal/WritingAIFeedbackModal'

export default class Grading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      caseCompletionDate: null,
      reviewLater: this.props.reviewLater,
      highYield: false,
      selectedCaseData: {
        AvgGradeForCase: 0,
      },
      gradingData: null,
      gradingSections: [
        {
          title: 'Diagnosis Orders',
          gradeValue: 'FinalDiagnoisGrade',
          weightValue: 'WEIGHTDIAGNOSIS'
        },
        {
          title: 'Treatment Orders',
          gradeValue: 'FinalTreatmentGrade',
          weightValue: 'WEIGHTTREATMENT',
        },
        {
          title: 'Preventive care',
          gradeValue: 'FinalPreventiveCareGrade',
          weightValue: 'WEIGHTPREVENTATIVE'
        },
        {
          title: 'Timing',
          gradeValue: 'FinalTimingGrade',
          weightValue: 'WEIGHTTIMING'
        },
        {
          title: 'Appropriate Orders',
          gradeValue: 'FinalAppropriateGrade',
          weightValue: 'WEIGHTAPPROPRIATE'
        },
        {
          title: 'Appropriate Location / Appropriate Sequence',
          gradeValue: 'FinalSequenceGrade',
          weightValue: 'WEIGHTSEQUENCING'
        },
      ],
      viewPreviousCompletions: false,
      previousCompletionSelected: 0,
      previousCompletions: [],
      highYieldLoading: false,
      reviewLaterLoading: false,
      questionIdMap: null,
      fetchingGradeOutstanding: true,
      displayPDFViewer: false,
      pdfToDisplay: null,
      displayWritingFeedbackModal: false,
      writingFeedbackModalSection: null,
    }
  }

  componentDidMount() {
    this.fetchCaseGrade(this.props.selectedExamGuid ? this.props.selectedExamGuid : 'efda69fb-1a8e-4dca-bb1a-6de97436abf7')

    var metaThemeColor = document.querySelector("meta[name=theme-color]")
    metaThemeColor.setAttribute("content", this.props.selectedTheme.tertiaryBackgroundColor)
	}

  fetchCaseGrade = (examGuid) => {
    this.setState({fetchingGradeOutstanding: true})
    fetch(`${this.props.route}/grading.webapi`, {
      method: 'POST',
      headers: {
        'Token': this.props.userData.Token,
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify({
        CustomerId: this.props.userData.CustomerId,
        examGuid,
      })
    })
    .then(response => {
      //Attempt sending logs
      // fetchSubmitLogs(this.props.userProfile, this.props.userData)
      if (response.status === 401) {
        this.props.displayAuthenticationError()
        throw new Error('Authentication Error')
      } else {
        return response.json()
      }
    })
    .then(response => {

      // Handle caselist refresh
      if (this.props.shouldGradingRefreshCaseList) {
        this.props.fetchCaseList()
      }

      let questionIdMap = this.getQuestionIdMap(response.Grade)

      // Add question numbers to each question
      let currentQuestionNumber = 1
      
      response.Sections.forEach((section, sectionIndex) => {
        if (section.title === 'Writing') {
          section.questionNumberStart = currentQuestionNumber
          section.questionNumberEnd = currentQuestionNumber + 1
          currentQuestionNumber += 1
          section = createGradeOverview(section.WritingGrade.WritingGradeOutput, section)
        } else {
          let initialSectionQuestionNumber = currentQuestionNumber
          section.Elements.forEach((element, elementIndex) => {
            if (element.type === 'input') {
              element.questionList.forEach((question, questionIndex) => {
                if (question.questionId) {
                  question.questionNumber = currentQuestionNumber
                  currentQuestionNumber++
                }
              })
            } else if (element.questionId) {
              element.questionNumber = currentQuestionNumber
              currentQuestionNumber++
            }
          })
          section.questionNumberStart = initialSectionQuestionNumber
          section.questionNumberEnd = currentQuestionNumber - 1
        }
      })

      this.setState({gradingData: response, questionIdMap, fetchingGradeOutstanding: false})
    })
    .catch(() => {
      this.setState({fetchingGradeOutstanding: false})
    })
  }

  getQuestionIdMap = (gradeArray) => {
    let questionIdMap = {}
    gradeArray.forEach((question) => {
      questionIdMap[question.questionId] = question
    })
    return questionIdMap
  }

  fetchReviewLater = () => {
		this.setState({reviewLaterLoading: true})
		fetch(`${this.props.route}/reviewlater.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.props.userData.CustomerId,
				caseId: this.state.gradingData.examId,
				marked: !this.state.gradingData.ReviewLater,
			})
		})
		.then(response => {
      this.setState({reviewLaterLoading: false})
			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			if (response.status === 401) {
				this.props.displayAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then(response => {
			this.reviewLaterToggle();
		})
		.catch(error => {
      this.setState({reviewLaterLoading: false})
			console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchReviewLater')
		});
	}

	reviewLaterToggle = () => {
		this.setState({reviewLater: !this.state.reviewLater}, () => {
      this.props.updateReviewLater(this.state.gradingData.examId)
    });
	}

	fetchRetrieveCaseGrade = async (id) => {
		await fetch(`${this.props.route}/retreivecasegrade.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerGradeId: id,
			})
		})
		.then(response => {
			if (response.status === 401) {
				this.props.displayAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then(data => {
			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			data = JSON.parse(data)

      // Get caseData from the unfiltered case list
      let caseData = this.props.unfilteredCaseList.filter(tempCase => tempCase.ID === this.props.selectedCase)[0]

      // sort by GradeCategory
      data.GradeFeedback.GradeOut.sort((a, b) => {
        if (a.GradeCategory < b.GradeCategory) {
          return -1
        } else if (a.GradeCategory > b.GradeCategory) {
          return 1
        } else {
          return 0
        }
      })
      data.GradeFeedback.GradeOut = data.GradeFeedback.GradeOut.filter(grade => grade.GradeCategory !== "")

      for(let i = 0; i < data.GradeFeedback.GradeOut.length; i++) {
        if (data.GradeFeedback.GradeOut[i].GradeColor.includes('Correct - Green')) {
          data.GradeFeedback.GradeOut[i].Correct = true
        } else {
          data.GradeFeedback.GradeOut[i].Correct = false
        }
      }

			this.setState({
				selectedCase: id,
        selectedCaseData: caseData,
        caseCompletionDate: data.CaseCompletionDate,
        caseGradeData: data,
				grading: data,
				highYield: data.HighYieldFlag
			});
		})
		.catch(error => {
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log('error', error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchRetrieveCaseGrade')
		});
	}

	markHighYieldHandler = () => {
    let gradingData = {...this.state.gradingData}
    gradingData.HighYieldFlag = !gradingData.HighYieldFlag
    this.setState({gradingData, highYieldLoading: true}, () => {
      fetch(`${this.props.route}/highyield.webapi`, {
        method: 'POST',
        headers: {
          'Token': this.props.userData.Token,
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          CustomerId: this.props.userData.CustomerId,
          TurnOffHighYield: gradingData.HighYieldFlag
        })
      })
      .then(response => {
        this.setState({highYieldLoading: false})
        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData)

        if (response.status === 401) {
          this.props.displayAuthenticationError()
          throw new Error('Authentication Error')
        } else {
          return response.text()
        }
      })
      .then(response => {

      })
      .catch((error) => {
        gradingData.HighYieldFlag = !gradingData.HighYieldFlag
        this.setState({gradingData, highYieldLoading: false})
        logFetchError(error, this.props.userProfile, this.props.userData, 'markHighYieldHandler')
      })
    })
	}

  highYieldHelpHandler = (e) => {
    e.stopPropagation()
    let confirmationPopupOptions = {
      title: `High Yield`,
      description: `If you think this case has a good chance to show up on the USMLE Step 3 exam, you can mark it as High Yield. This helps other users know which cases to study.`,
      confirmFunction: async () => {
        this.props.closeConfirmationPopup()
      },
      children: null,
      closePopup: () => {
        this.props.closeConfirmationPopup()
      },
      continueOnly: true
    }
    this.props.displayConfirmationPopup(confirmationPopupOptions)
  }

  filterText = input => {
    // Split the string into an array of substrings, using the "<strong>", "</strong>", "<center>", and "</center>" tags as the separators
    const substrings = input.split(/(<strong>|<\/strong>|<center>|<\/center>)/).filter((substring) => substring !== "");

    let result = []
    let centered = false
    let bold = false

    // Loop through the array of substrings and add the appropriate formatting to each substring
    // add it to the result array if it is not a tag
    for (let i = 0; i < substrings.length; i++) {
      switch(substrings[i]) {
        case "<strong>":
          bold = true
          break
        case "</strong>":
          bold = false
          break
        case "<center>":
          centered = true
          break
        case "</center>":
          centered = false
          break
        default:
          result.push({ text: substrings[i], bold, centered })
          break
      }
    }

    return result;
  }

  toggleSection = (sectionIndex) => {
    let gradingData = {...this.state.gradingData}
    gradingData.Sections[sectionIndex].expanded = !gradingData.Sections[sectionIndex].expanded
    this.setState({gradingData})
  }

  openPDF = (pdfBase64) => {
    pdfBase64 = pdfBase64.replace(/"/g, '')
    let pdf = 'data:application/pdf;base64,' + pdfBase64
    this.setState({ displayPDFViewer: true, pdfToDisplay: pdf })
  }

  updateWritingGradeData = (gradeData, sectionIndex) => {
    let gradingData = {...this.state.gradingData}
    gradingData.Sections[sectionIndex].WritingGrade.WritingGradeOutput = gradeData
    gradingData.Sections[sectionIndex] = createGradeOverview(gradingData.Sections[sectionIndex].WritingGrade.WritingGradeOutput, gradingData.Sections[sectionIndex])
    this.setState({gradingData})
  }

  render() {
    return (
      <div className='newGradingPageWrapper'>
        {this.state.displayPDFViewer &&
          <NewPdfViewer
            pdfFile={this.state.pdfToDisplay}
            onClose={() => this.setState({ displayPDFViewer: false, pdfToDisplay: null })}
            pdfDocked={false}
            handlePdfDock={() => null}
            page={'grading'}
          />
        }
        <AnimatePresence mode="wait">
          {this.state.displayWritingFeedbackModal &&
            <WritingAIFeedbackModal
              userData={this.props.userData}
              route={this.props.route}
              sectionData={this.state.writingFeedbackModalSection}
              closeModal={() => this.setState({displayWritingFeedbackModal: false, writingFeedbackModalSection: null})}
              animationsDisabled={this.props.animationsDisabled}
              primaryColor={this.props.primaryColor}
            />
          }
        </AnimatePresence>
        <nav className='newGradingNavbar'>
          {/* <img className='newGradingLogo' alt="CCS Cases Logo" src={Logo}/> */}
          <h1 className='caseListLogoText'>OCC<span className='caseListLogoTextNormal'>English</span></h1>
        </nav>
        <AnimatePresence mode="wait">
          {this.state.fetchingGradeOutstanding &&
            <motion.div
              className='newGradingLoaderContainer'
              initial={this.props.animationsDisabled ? false : {opacity: 0}}
              animate={this.props.animationsDisabled ? false : {y: 0, opacity: 1}}
              exit={this.props.animationsDisabled ? false : {opacity: 0}}
              transition={{duration: 0.2}}
              key="newGradingLoaderContainer"
            >
              <MDSpinner
                size={60}
                singleColor={this.props.primaryColor}
              />
              <p className='newGradingLoaderText'>Loading Grades</p>
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {!this.state.fetchingGradeOutstanding &&
            this.GradingNav()
          }
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {!this.state.fetchingGradeOutstanding &&
            <motion.div
              className='newGradingCaseTitleContainer'
              initial={this.props.animationsDisabled ? false : {opacity: 0}}
              animate={this.props.animationsDisabled ? false : {opacity: 1}}
              exit={this.props.animationsDisabled ? false : {opacity: 0}}
              transition={{duration: 0.2}}
              key="newGradingCaseTitleContainer"
            >
              <div className='newGradingCaseNumberContainer'>
                <h1 className='newGradingCaseNumber'>{this.state.gradingData.examId}</h1>
              </div>
              <div className='newGradingCaseTitleWrapper'>
                <h2 className='newGradingCaseTitle'>{this.state.gradingData.ExamTitle}</h2>
                <h3 className='newGradingCaseCompletionDate'>Completed: {formatDateString(this.state.gradingData.DateCompleted)}, {formatTimeString(this.state.gradingData.DateCompleted)}</h3>
              </div>
              {this.state.gradingData.PreviousCompletions.length > 0 && 
                <button className='newGradingViewPreviousButton tertiaryButton' onClick={() => this.setState({viewPreviousCompletions: true})}>View Previous Completions</button>
              }
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {!this.state.fetchingGradeOutstanding &&
            <motion.div
              className='newGradingCaseDetailsWrapper'
              initial={this.props.animationsDisabled ? false : {opacity: 0}}
              animate={this.props.animationsDisabled ? false : {opacity: 1}}
              exit={this.props.animationsDisabled ? false : {opacity: 0}}
              transition={{duration: 0.2}}
              key="newGradingCaseDetailsWrapper"
            >
              <div className='newGradingCaseDetailContainer'>
                <div className='newGradingCaseDetailIconContainer'>
                  <FontAwesomeIcon icon={faUser} className='newGradingCaseDetailIcon' />
                </div>
                <h3 className='newGradingCaseDetailTitle'>Your Score</h3>
                <h2 className='newGradingCaseDetailValue'>{Math.floor(this.state.gradingData.gradeScore * 100) / 100}%</h2>
              </div>

              <div className='newGradingCaseDetailContainer'>
                <div className='newGradingCaseDetailIconContainer'>
                  <FontAwesomeIcon icon={faUsers} className='newGradingCaseDetailIcon' />
                </div>
                <h3 className='newGradingCaseDetailTitle'>Average First Attempt Score</h3>
                <h2 className='newGradingCaseDetailValue'>{Math.floor(this.state.gradingData.AvgFirstAttemptGrade * 100) / 100}%</h2>
              </div>
            </motion.div>
          }
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {!this.state.fetchingGradeOutstanding &&
            this.state.gradingData.Sections.map((section, sectionIndex) => {
              return this.GradingSection(sectionIndex, section)
            })
          }
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {!this.state.fetchingGradeOutstanding &&
            this.GradingNav()
          }
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {this.state.viewPreviousCompletions &&
            <ViewCompletionsPopup
              closePopup={() => this.setState({viewPreviousCompletions: false})}
              previousCompletionSelected={this.state.previousCompletionSelected}
              previousCompletions={this.state.gradingData.PreviousCompletions}
              updatePreviousCompletionSelected={(newSelection) => this.setState({previousCompletionSelected: newSelection})}
              confirm={this.fetchCaseGrade}
              timeZone={this.props.userData.TimeZone}
              primaryColor={this.props.primaryColor}
              key="viewCompletionsPopupComponent"
            />
          }
        </AnimatePresence>
      </div>
    )
  }

  GradingNav() {
    return <motion.div
      className='newGradingBackWrapper'
      initial={this.props.animationsDisabled ? false : { opacity: 0 }}
      animate={this.props.animationsDisabled ? false : { opacity: 1 }}
      exit={this.props.animationsDisabled ? false : { opacity: 0 }}
      transition={{ duration: 0.2 }}
      key="newGradingBackWrapper"
    >
      <button className='tertiaryButton newGradingBackButton' onClick={() => this.props.closeGrading()}>
        <FontAwesomeIcon icon={faArrowLeft} className='newGradingBackIcon' />
        Back To Exam List
      </button>
      <div className='newGradingCheckboxContainer' onClick={this.markHighYieldHandler} style={{ gridTemplateColumns: '30px auto auto', paddingRight: '10px' }}>
        <div className='newGradingCheckbox'>
          {this.state.gradingData.HighYieldFlag && <FontAwesomeIcon icon={faCheck} className='newGradingCheckboxIcon' />}
        </div>
        <p className='newGradingCheckboxText'>
          High Yield
        </p>
        <FontAwesomeIcon icon={faQuestionCircle} className='highYieldMoreInfoButton' onClick={this.highYieldHelpHandler} />
        {this.state.highYieldLoading &&
          <div className='highYieldLoadingContainer'>
            <MDSpinner
              size={35}
              singleColor={'#fff'} />
          </div>}
      </div>
      <div className='newGradingCheckboxContainer' onClick={this.fetchReviewLater} style={{ gridTemplateColumns: '30px auto' }}>
        <div className='newGradingCheckbox'>
          {this.state.reviewLater && <FontAwesomeIcon icon={faCheck} className='newGradingCheckboxIcon' />}
        </div>
        <p className='newGradingCheckboxText'>
          Review Later
        </p>
        {this.state.reviewLaterLoading &&
          <div className='highYieldLoadingContainer'>
            <MDSpinner
              size={30}
              singleColor={'#fff'} />
          </div>}
      </div>
    </motion.div>
  }

  GradingSection(sectionIndex, section) {
    return <motion.div
      className='gradingFeedbackSectionContainer'
      initial={this.props.animationsDisabled ? false : { opacity: 0 }}
      animate={this.props.animationsDisabled ? false : { opacity: 1 }}
      exit={this.props.animationsDisabled ? false : { opacity: 0 }}
      transition={{ duration: 0.2 }}
      key={`gradingFeedbackSectionContainer - ${sectionIndex}`}
    >
      <div className='gradingFeedbackSectionHeaderWrapper' style={{ marginBottom: !section.expanded ? 0 : 15 }}>
        <button
          className={section.expanded ? 'gradingFeedbackSectionExpandButton gradingFeedbackSectionExpandButtonOpen' : 'gradingFeedbackSectionExpandButton'}
          onClick={() => this.toggleSection(sectionIndex)}
        />
        <div className='gradingFeedbackSectionHeaderInnerWrapper'>
          <h2 className='gradingFeedbackSectionHeader'>Section {section.sectionId} - {section.title}</h2>
          <h3 className='gradingFeedbackSectionSubheader'>Questions {section.questionNumberStart} - {section.questionNumberEnd}</h3>
        </div>
        {section.title === 'Writing' &&
          <button
            className='gradingFeedbackSectionWritingFeedbackButton'
            onClick={() => this.setState({displayWritingFeedbackModal: true, writingFeedbackModalSection: section})}
          >How Did We Do?</button>
        }
      </div>
      {!section.expanded && <div className='gradingFeedbackSectionBodyContainer'>
        {section.title === 'Writing' ?
          <WritingComponent
            userGradeValues={section.WritingGrade.WritingGradeOutput}
            section={section}
            animationsDisabled={this.props.animationsDisabled}
            displayConfirmationPopup={this.props.displayConfirmationPopup}
            closeConfirmationPopup={this.props.closeConfirmationPopup}
            route={this.props.route}
            userData={this.props.userData}
            displayAuthenticationError={this.props.displayAuthenticationError}
            gradingData={this.state.gradingData}
            updateWritingGradeData={(gradeData) => this.updateWritingGradeData(gradeData, sectionIndex)}
          />
        :
          section.Elements.map((element, elementIndex) => {
            switch (element.type) {
              case 'info':
                return (
                  this.InfoComponent(element, sectionIndex, elementIndex)
                )
              case 'multipleChoice':
                return (
                  this.MultipleChoiceComponent(element)
                )
              case 'input':
                return (
                  element.questionList.map((question, questionIndex) => (
                    this.InputComponent(question)
                  ))
                )
              case 'audio':
                return (
                  <AudioPlayer
                    fileName={element.mp3Audio}
                    route={this.props.route}
                    userData={this.props.userData}
                    primaryColor={this.props.primaryColor}
                  />
                )
              default:
                return null
        }
        })}
      </div>}
    </motion.div>
  }

  InputComponent(question) {
    if (!question.questionId)
      return null

    return <div className='gradingFeedbackInputQuestionContainer' style={{ backgroundColor: this.state.questionIdMap[question.questionId].EventName === 'Answered Incorrectly' ? "#FFECEB" : "#F7FFED" }}>
      <div className='gradingFeedbackInputQuestionNumberWrapper' style={{ backgroundColor: this.state.questionIdMap[question.questionId].EventName === 'Answered Incorrectly' ? "#B53333" : "#517145" }}>
        <h3 className='gradingFeedbackInputQuestionNumber'>{question.questionNumber}</h3>
      </div>
      <div className='gradingFeedbackInputQuestionContentWrapper'>
        <h4 className='gradingFeedbackInputQuestionTitle'>{question.statement.replaceAll('{input}', '_____')}</h4>
        <p className='gradingFeedbackInputQuestionText'>Your Answer: "{this.state.questionIdMap[question.questionId].CustomerAnswer}"</p>
        <p className='gradingFeedbackInputQuestionText'>Correct Answer: "{this.state.questionIdMap[question.questionId].CorrectAnswer}"</p>
      </div>
    </div>
  }

  InfoComponent(element, sectionIndex, elementIndex) {
    return <div className='gradingFeedbackInfoComponentContainer'>
      <p
        className={`gradingFeedbackInfoText`}
      >
        {element.text && this.filterText(element.text).map((text, index) => (
          text.bold && text.centered ?
            <span className='centeredText' key={`infoComponentText - ${sectionIndex} - ${elementIndex} - ${index}`}><strong>{text.text}</strong></span>
            : text.bold ?
              <strong key={`infoComponentText - ${1} - ${index}`}>{text.text}</strong>
              : text.centered ?
                <span className='centeredText' key={`infoComponentText - ${sectionIndex} - ${elementIndex} - ${index}`}>{text.text}</span>
                :
                text.text
        ))}
      </p>
    </div>
  }

  MultipleChoiceComponent(element) {
    return <div className='gradingFeedbackMultipleChoiceQuestionContainer' style={{ backgroundColor: this.state.questionIdMap[element.questionId].EventName === 'Answered Incorrectly' ? "#FFECEB" : "#F7FFED" }}>
      <div className='gradingFeedbackMultipleChoiceQuestionNumberWrapper' style={{ backgroundColor: this.state.questionIdMap[element.questionId].EventName === 'Answered Incorrectly' ? "#B53333" : "#517145" }}>
        <h3 className='gradingFeedbackMultipleChoiceQuestionNumber'>{element.questionNumber}</h3>
      </div>
      <div className='gradingFeedbackMultipleChoiceQuestionContentWrapper'>
        <h4 className='gradingFeedbackMultipleChoiceQuestionTitle'>{element.question.replaceAll('{input}', '_____')}</h4>
        {/* <div className='gradingFeedbackMultipleChoicePDFContainer'></div> */}
        {element.pdfFile &&
          <button
            className='openPDFButton'
            style={{ width: 'fit-content', margin: 0, marginBottom: 10 }}
            onClick={() => this.openPDF(element.pdfBytes)}
          >
            <div className='openPDFIconWrapper'>
              <FontAwesomeIcon
                icon={faFilePdf}
                className='openPDFButtonIcon' />
            </div>
            <span className='gradingFeedbackMultipleChoicePDFText'>Fractures, dislocations, and sprains</span>
          </button>}
        {element.answerOptions.map((answer, answerIndex) => {
          let didUserSelectAnswer = this.state.questionIdMap[element.questionId].CustomerAnswer === answer.answer
          let isAnswerCorrect = this.state.questionIdMap[element.questionId].CorrectAnswer === answer.answer
          return (
            <div className='gradingFeedbackMultipleChoiceQuestionAnswerContainer' style={{ backgroundColor: (didUserSelectAnswer && isAnswerCorrect) ? "#ECFFE5" : (didUserSelectAnswer && !isAnswerCorrect) ? "#FFECEB" : "#F5F5F5" }}>
              <div className='gradingFeedbackMultipleChoiceQuestionCheckboxWrapper' style={{ backgroundColor: (didUserSelectAnswer && isAnswerCorrect) ? "#517145" : (didUserSelectAnswer && !isAnswerCorrect) ? "#B53333" : "#6A6A6A" }}>
                <div className='gradingFeedbackMultipleChoiceQuestionCheckbox'>
                  {didUserSelectAnswer &&
                    <FontAwesomeIcon
                      icon={faCheck}
                      className='gradingFeedbackMultipleChoiceQuestionCheckboxIcon' />}
                </div>
              </div>
              <div className='gradingFeedbackMultipleChoiceQuestionAnswerWrapper'>
                <p className='gradingFeedbackMultipleChoiceQuestionAnswerText'>{answer.answer}</p>
                <p className='gradingFeedbackMultipleChoiceQuestionAnswerSubtext'>{answer.isCorrect ? "Correct Answer" : "Incorrect Answer"}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  }
}
