import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import './OutOfTimePopup.css'

export default function OutOfTimePopup(props) {
  return (
    <div className='outOfTimePopupPageWrapper'>
      <div className='outOfTimePopupBackdrop' />
      <div className='outOfTimePopupContainer'>
        <div className='outOfTimePopupHeader'>
          <h1 className='outOfTimePopupHeaderText'>Section Time Expired</h1>
        </div>
        <p className='outOfTimePopupText'>The time for the previous section of the test has expired. Please continue with the new section you're being directed to.</p>
        <button onClick={props.closePopup} className='outOfTimePopupButton'>
          <FontAwesomeIcon
            icon={faCheck}
            className='buttonIcon'
          />
          Close
        </button>
      </div>
    </div>
  )
}
