import React, { Component } from 'react'
import AudioPlayer from '../AudioPlayer/AudioPlayer'
import MicRecorder from 'mic-recorder-to-mp3'
import './AudioRecorder.css'
import AudioDeviceTest from './AudioDeviceTest/AudioDeviceTest'
import { AnimatePresence } from 'framer-motion'

export default class AudioRecorder extends Component {

  constructor() {
    super()
    this.state = {
      recordingState: 'notYetRecorded',
      currentTimerInSeconds: 0,
      displayAudioTest: true
    }
  }

  // New instance
  recorder = new MicRecorder({
    bitRate: 128
  })

  startTimer = () => {
    this.timer = setInterval(() => {
      this.setState({currentTimerInSeconds: this.state.currentTimerInSeconds + 1})
    }, 1000)
  }

  stopTimer = () => {
    clearInterval(this.timer)
  }

  createAudioVisualizer = (recorder) => {
    const visualMainElement = document.querySelector( 'span' );
    const visualValueCount = 16;
    let visualElements;
    const createDOMElements = () => {
      let i;
      for ( i = 0; i < visualValueCount; ++i ) {
        const elm = document.createElement( 'div' );
        visualMainElement.appendChild( elm );
      }

      visualElements = document.querySelectorAll( 'span div' );
    };
    createDOMElements();
    const audioContext = new AudioContext();
    const initDOM = () => {
      visualMainElement.innerHTML = '';
      createDOMElements();
    };
    initDOM();

    // Swapping values around for a better visual effect
    const dataMap = { 0: 15, 1: 10, 2: 8, 3: 9, 4: 6, 5: 5, 6: 2, 7: 1, 8: 0, 9: 4, 10: 3, 11: 7, 12: 11, 13: 12, 14: 13, 15: 14 };
    const processFrame = ( data ) => {
      const values = Object.values( data );
      let i;
      for ( i = 0; i < visualValueCount; ++i ) {
        const value = values[ dataMap[ i ] ] / 255;
        const elmStyles = visualElements[ i ].style;
        elmStyles.transitionDuration = '0.1s';
        if (value > .45) {
          elmStyles.transform = `scaleY( ${ Math.max( .01, value ) } )`;
        } else {
          elmStyles.transform = `scaleY( ${ .01 } )`;
        }
        // elmStyles.opacity = Math.max( .25, value * 4 );
        elmStyles.opacity = 1;
      }
    };

    this.audioContext = audioContext;
    this.processFrame = processFrame;

    this.connectStream(recorder)
  }

  startRecording = () => {
    // Start recording. Browser will request permission to use your microphone.
    this.recorder.start().then((recorder) => {
      this.startTimer()
      this.setState({recordingState: 'recording'}, () => {
        this.createAudioVisualizer(recorder)
      })
      // something else
    }).catch((e) => {
      if (e.name === 'NotAllowedError') {
        let confirmationPopupOptions = {
          title: `Microphone Access Needed`,
          description: `You will have to allow access to your microphone to complete this question. Not allowing microphone access will result in a score of 0 for this section.`,
          confirmFunction: async () => {
            
          },
          continueOnly: true,
          children: <div style={{height: 10}}/>
        }
        this.props.displayConfirmationPopup(confirmationPopupOptions)
        // alert('Please allow microphone access to continue')
      } else {
        alert('There was an error recording your audio, please allow microphone access and check that a microphone is connected and try again')
      }
    });
  }

  connectStream = ( stream ) => {
    this.analyser = this.audioContext.createAnalyser();
    const source = this.audioContext.createMediaStreamSource( stream );
    source.connect( this.analyser );
    this.analyser.smoothingTimeConstant = 0.5;
    this.analyser.fftSize = 32;

    this.initRenderLoop();
  }

  initRenderLoop = () => {
    const frequencyData = new Uint8Array( this.analyser.frequencyBinCount );
    const processFrame = this.processFrame || ( () => {} );

    const renderFrame = () => {
      this.analyser.getByteFrequencyData( frequencyData );
      processFrame( frequencyData );

      requestAnimationFrame( renderFrame );
    };
    requestAnimationFrame( renderFrame );
  }

  stopRecording = () => {
    this.recorder
    .stop()
    .getMp3().then(([buffer, blob]) => {
      const file = new File(buffer, `${this.props.examGuid}.mp3`, {
        type: blob.type,
        lastModified: Date.now()
      });
      this.stopTimer()
      this.setState({recordingState: 'recorded'})
      this.props.saveAudioFile(this.props.content.questionId, file)
    }).catch((e) => {
      alert('We could not retrieve your message');
    });
  }

  getTimerTime = () => {
    const minutes = Math.floor(this.state.currentTimerInSeconds / 60)
    const seconds = this.state.currentTimerInSeconds % 60
    if (minutes === 0) {
      return `${seconds} second${seconds === 1 ? '' : 's'}`
    }

    return `${minutes} minute${minutes === 1 ? '' : 's'} ${seconds} second${seconds === 1 ? '' : 's'}`
  }

  handleBeginSpeakingSection = () => {
    this.setState({displayAudioTest: false})
    this.props.startTimer(true)
  }

  render() {
    return (
      <>
        <audio ref={this.audioInputRef} />
        <audio ref={this.audioOutputRef} />
        {this.state.recordingState === 'notYetRecorded' ?
          <div className='audioRecorderWrapper'>
            <button onClick={this.startRecording} className='audioRecordingButton'>Begin recording</button>
          </div>
        : this.state.recordingState === 'recording' ?
          <div className='audioRecorderWrapper'>
            <div className='audioRecordingTextWrapper'>
              <h3 className='audioRecordingTitle'>Recording Duration</h3>
              <p className='audioRecordingText'>{this.getTimerTime()}</p>
            </div>
            <div className='audioVisualizerWrapper'>
              <div className='audioVisualizerContainer'>
                <span className='visualizerContainer'></span>
              </div>
              <p className='audioVisualizerText'>Audio Visualizer</p>
            </div>
            <button onClick={this.stopRecording} className='audioRecordingButton'>Finish recording</button>
          </div>
        :
          <AudioPlayer
            mp3Audio={this.props.mp3AudioFile}
            showPauseButton={true}
            createURL={true}
          />
        }
        <AnimatePresence mode="wait">
          {this.state.displayAudioTest &&
            <AudioDeviceTest
              primaryColor={this.props.primaryColor}
              continue={this.handleBeginSpeakingSection}
              cancel={this.props.advanceToNextSection}
              animationsDisabled={this.props.animationsDisabled}
            />
          }
        </AnimatePresence>
      </>
    )
  }
}
